import { useState, useEffect } from 'react';
import { TitleBar } from '@app/components/TitleBar';
import { Controller, useForm } from 'react-hook-form';
import { View, StyleSheet, Text, TextInput, TouchableOpacity, Alert, Modal, ScrollView } from 'react-native';
import { Timesheets } from './Components/Timesheets';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import moment from 'moment';
import { colours } from '../../../../../config';
import { BreakdownDatabase } from '@store/services/database/breakdown-database';
import { Picker } from '@react-native-picker/picker';
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import { EngineerReportDatabase } from '@store/services/database/engineer-report-database';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { JobDatabase } from '@store/services/database/job-database';
import { setJobData } from '@store/slices/job-slice';
import { ClientSignOff } from './Components/ClientSignOff';
import { InspectionDatabase } from '@store/services/database/inspection-database';

export default function ERSTRScreen({ navigation }: { navigation: any }) {
  const dispatch = useDispatch();
  const job = useSelector((state: RootState) => state.job);

  const [timesheets, setTimesheets] = useState([]);
  const [partSearch, setPartSearch] = useState("");
  const [selectedPartId, setSelectedPartId] = useState(null);
  const [filteredParts, setFilteredParts] = useState([]);
  const [jobCompleted, setJobCompleted] = useState(null);
  
  const [parts, setParts] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [showSignOff, setShowSignOff] = useState(false);

  const breakdownDatabase = new BreakdownDatabase();
  const engineerReportDatabase = new EngineerReportDatabase();

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      worksCarriedOut: "",
      furtherWorks: "",
      customerPoNumber: ""
    }
  });


  useEffect(() => {
    const generateErstr = () => {
      var erstr = {
        id: null,
        worksCarriedOut: "",
        furtherWorks: "",
        customerPoNumber: "",
        timesheets: [],
        parts: [],
        jobCompleted: null
      };

      for (let i = 0; i < 7; i++) {
        var timesheetDay = moment().day(1).add(i, 'day');
        erstr.timesheets.push({
          day: timesheetDay.format("dddd"),
          date: timesheetDay.format("YYYY-MM-DD"),
          toSite: "",
          arrived: "",
          depart: "",
          home: "",
          hours: "",
          hoursHalf: "",
          hoursDouble: ""
        });
      }

      return erstr;
    }

    const loadErstr = async () => {
      var erstr = generateErstr();
      if (job.engineerReportId != null)
        erstr = await engineerReportDatabase.getById(job.engineerReportId);

      if (erstr.jobCompleted == true || erstr.jobCompleted == 1) {
        Alert.alert("", "Engineer report has already been completed");
        navigation.goBack();
        return;
      }

      if (erstr.parts.length > 0) {
        //console.log(erstr.parts);
        const allParts = await breakdownDatabase.getBreakdownParts();
        for (let part of allParts) {
          console.log(part.id + " - " + part.description);
        }
        var erstrParts = [];
        for (var p of erstr.parts) {
          var part = allParts.find(local => local.id == p.partId);
          erstrParts.push(part);
        }

        setParts(erstrParts);
      }

      setTimesheets(erstr.timesheets);
      setJobCompleted(erstr.jobCompleted)
      
  
      setValue("id", erstr.id);
      setValue("worksCarriedOut", erstr.worksCarriedOut);
      setValue("furtherWorks", erstr.furtherWorks);
      setValue("customerPoNumber", erstr.customerPoNumber);
    }
    
    loadErstr();
  }, [])

  const searchParts = async () => {
    var parts = await breakdownDatabase.getBreakdownPartsBySearch(partSearch);
    setFilteredParts(parts);
  }

  const addPart = () => {
    console.log(selectedPartId);
    if (selectedPartId != null) {
      var part = filteredParts.find(p => p.id == selectedPartId);
      setParts([...parts, part]);
    }
    
  }

  const removePart = (part) => {
    Alert.alert('Confirmation', 'Are you sure you want to remove this part?', [        
      { text: 'No', style: 'cancel', },
      { text: 'Yes', onPress: () => {
        var newParts = parts.filter((p) => { return p.id !== part.id });
        setParts([...newParts]); 
      }}
    ]);
  }

  const complete = () => {
    if (jobCompleted == null) {
      Alert.alert("", "Check if job is completed");
      return;
    }

    // for (var timesheet of timesheets) {
    //   console.log(timesheet);
    //   if (timesheet.toSite == null || timesheet.toSite == "") {
    //     Alert.alert("", "Please enter at least 1 timesheet");
    //     return;
    //   }
    // }

    

    // if the job is complete prompt for client signature and name
    if (jobCompleted) {
      setShowSignOff(true);
      return;
    } else {
      setShowSignOff(false);
    }

    save(null);
  }

  const checkMissing = async () => {
    if (job.jobType == 0) {
      Alert.alert('Complete Job', 'Would you like to set the remaining assets as missing?',
        [
          { text: 'No', style: 'cancel', },
          { text: 'Yes', onPress: async () => {
            var inspectionDatabase = new InspectionDatabase();
            var jobDatabase = new JobDatabase();
            var jobAssets = await jobDatabase.getJobAssets(job.jobId, null);
            for (let jobAsset of jobAssets) {
              await inspectionDatabase.addMissingInspection(moment().format("YYYY-MM-DD"), jobAsset.id, job.engineerId, job.jobId, job.purposeId, job.colourId);
            }

          }, style: 'cancel', },
        ],
      );
      return;
    }
  }

  const save = async (clientData: any = null) => {
    const engineerReportDatabase = new EngineerReportDatabase();
    const jobDatabase = new JobDatabase();

    const values = getValues();

    setLoading(true);
    try {
      var engineerReportId = await engineerReportDatabase.addEngineerReport(
        values.id, job.engineerId, job.jobId, values.worksCarriedOut,
        values.furtherWorks, jobCompleted, clientData?.clientName, clientData?.clientSignatureBase64,
        values.customerPoNumber, timesheets, parts
      );

      await checkMissing();

      // await jobDatabase.updateEngineerReport(job.jobId, engineerReportId);

      if (jobCompleted) {
        setLoading(false);
        await jobDatabase.completeJob(job.jobId);

        navigation.navigate('Dashboard');
        return;
      }

      dispatch(setJobData({
        engineerReportId: engineerReportId,
      }))


    } catch (e) {
      console.log(e);
      setLoading(false);
      Alert.alert("Error saving Engineer Report. Please try again");
      return;
    }

    setLoading(false);

    Alert.alert("Success", "Successfully saved Engineer Report");

   // navigation.goBack();
  }

  if (isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
       <Modal
        animationType="slide"
        transparent={true}
        visible={showSignOff}>
          <ClientSignOff onSave={(clientData) => save(clientData)}/>
      </Modal>

      <TitleBar navigation={navigation} title="ERSTR" showBackButton={true} showSearchButton={false} onSynced={null} onSearch={null}/>

      <ScrollView style={styles.form}>
        <Controller
          control={control}
          name="worksCarriedOut"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Work/Repairs Carried Out</Text>

              <TextInput
                style={styles.step__question__input}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
              />
            </View>
          )}
        />

        <Timesheets timesheets={timesheets} onUpdated={(updatedTimesheets) => setTimesheets(updatedTimesheets)}/> 

        <View style={styles.step__question}>
          <Text style={styles.step__question__label}>Part # / Description</Text>

          <View style={{ flexDirection: 'row'}}>
            <TextInput
              style={styles.step__question__input}
              onChangeText={(text) => setPartSearch(text) }
              value={partSearch}
            />

            <TouchableOpacity style={styles.search__button} onPress={searchParts}>
              <Text>Search</Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style={styles.step__question}>
          <View style={{ flexDirection: 'row'}}>
            <Picker
              style={{ flex: 1 }}
              selectedValue={selectedPartId}
              onValueChange={(itemValue, itemIndex) => setSelectedPartId(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="Please Select" value="" />

              { filteredParts != null && filteredParts.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.description} value={value.id} />
                )
              })}
            </Picker>

            <TouchableOpacity style={styles.search__button} onPress={addPart}>
              <Text>Add</Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style={{ minHeight: 200, backgroundColor: '#FAFAFA' }}>
          <Text style={styles.header}>Part Description</Text>
            { parts != null && parts.length > 0 && parts.map((part: any, index: number) => {
            return (
              <TouchableOpacity key={part.id} style={styles.part__row} onPress={() => removePart(part)}>
                <Text style={styles.part__column}>{part.description}</Text>
              </TouchableOpacity>
            )
          })}
        </View>

        <Controller
          control={control}
          name="customerPoNumber"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Customer PO Number</Text>

              <TextInput
                style={styles.step__question__input}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
              />
            </View>
          )}
        />

        <View style={{ marginTop: 10, marginBottom: 30, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <Text style={{ fontFamily: 'OpenSans-Bold', fontSize: 24, marginBottom: 10 }}>Is this job completed?</Text>
          <RadioButtonGroup
            containerStyle={{ flexDirection: 'row' }}
            radioBackground={colours.primary}
            selected={jobCompleted}
            onSelected={(val) => setJobCompleted(val)}>

            <RadioButtonItem value={0} label="No" />
            <RadioButtonItem value={1} style={{ marginLeft: 20 }} label="Yes" />
          </RadioButtonGroup>
        </View>

        <Controller
          control={control}
          name="furtherWorks"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Further Works</Text>

              <TextInput
                style={styles.step__question__input}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
              />
            </View>
        )}/>

        <TouchableOpacity style={styles.proceed__button} onPress={() => complete()}>
          <Text style={styles.proceed__button__text}>SAVE</Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 40,
  },

  form: {
    marginTop: 10,
  },

    step__question: {
      padding: 20,
      backgroundColor: '#FFF',
      marginBottom: 20,
    },

    step__question__label: {
      fontFamily: 'OpenSans-Light',
      fontSize: 18,
      marginBottom: 5,
    },

    step__question__input: {
      borderWidth: 1,
      borderColor: '#CECECE',
      padding: 10,
      marginTop: 5,
      flex: 1,
    },

    input__validation: {
      color: 'red',
      marginTop: 10
    },

    proceed__button: {
      padding: 10,
      backgroundColor: colours.primary,
      marginTop: 20,
      borderRadius: 7,
      height: 45,
  
  
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  
      proceed__button__text: {
        fontFamily: 'OpenSans-Bold',
        color: '#FFF',
        textAlign: 'center',
        fontSize: 18
      },

  search__button: {
    marginLeft: 20, 
    backgroundColor: '#ededed', 
    width: 100,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    height: 50,
    alignSelf: 'flex-end'
  },

  header: {
    textAlign: 'center',
    padding: 10,
    backgroundColor: colours.primary,
    color: '#FFF',
    fontSize: 14,
    marginLeft: 2.5,
    marginRight: 2.5,
    fontFamily: 'OpenSans-Bold',
  },

  part__row: {
    flexDirection: 'row',
    backgroundColor: '#FFF',
  },

  part__column: {
    padding: 10,
    textAlign: 'center',
    width: '100%'
  }
})