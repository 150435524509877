import { createNativeStackNavigator } from '@react-navigation/native-stack';
import CategoriesListScreen from './CategoriesList';
import CategoryManageScreen from './CategoryManage';

const Stack = createNativeStackNavigator();

export default function Categories({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={CategoriesListScreen} options={{ headerShown: false, title: 'Categories'  }} />
      <Stack.Screen name="manage" component={CategoryManageScreen} options={{ headerShown: false, title: 'Category Manage'}}  />
    </Stack.Navigator>
  )
};