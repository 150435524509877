import * as SQLite from 'expo-sqlite';
import { Database } from './database';

export class LocationDatabase extends Database {
  TABLE_NAME = "Locations";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Locations ( 
        id TEXT PRIMARY KEY,
        name TEXT,
        address1 TEXT,
        address2 TEXT,
        address3 TEXT,
        address4 TEXT,
        postcode TEXT,
        companyId TEXT
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  insertOrUpdateList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (id, name, address1, address2, address3, address4, postcode, companyId)
            VALUES (?, ?, ?, ?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [entity.id, entity.name, entity.address1, entity.address2, entity.address3, entity.address4, entity.postcode, entity.companyId]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getAllLocations() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME}
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          //console.log(_array)
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }
}