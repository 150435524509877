import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';

export class BreakdownDatabase extends Database {
  TABLE_NAME = "BreakdownInspections";
  TABLE_NAME_INSPECTION_PARTS = "BreakdownInspectionParts";
  TABLE_NAME_INSPECTION_FAULTS = "BreakdownInspectionFaults";
  TABLE_NAME_COMMON_FAULTS = "BreakdownCommonFaults";
  TABLE_NAME_PARTS = "BreakdownParts";
  TABLE_NAME_IMAGES = "BreakdownInspectionImages";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      
      var sql = `CREATE TABLE IF NOT EXISTS BreakdownInspections ( 
        id TEXT PRIMARY KEY,
        inspectionDate TEXT,
        nextInspectionDate TEXT,
      
        equipmentId TEXT,
        engineerId TEXT,
        jobId TEXT,
        purposeId TEXT,
        colourId TEXT,
        missing INT,

        hours INT,
        hoursHalf INT,
        hoursDouble INT
      )`;

      var sqlInspectionParts = `CREATE TABLE IF NOT EXISTS BreakdownInspectionParts ( 
        id TEXT PRIMARY KEY,
        breakdownPartId TEXT,
        breakdownInspectionId TEXT,
        qty TEXT
      )`;

      var sqlInspectionFaults = `CREATE TABLE IF NOT EXISTS BreakdownInspectionFaults ( 
        id TEXT PRIMARY KEY,
        breakdownFaultId TEXT,
        actionTaken TEXT,
        remarks TEXT,
        hours TEXT,
        breakdownInspectionId TEXT
      )`;

      var sqlFaults = `CREATE TABLE IF NOT EXISTS BreakdownCommonFaults ( 
        id TEXT PRIMARY KEY,
        fault TEXT
      )`;

      var sqlParts = `CREATE TABLE IF NOT EXISTS BreakdownParts ( 
        id TEXT PRIMARY KEY,
        itemCode TEXT,
        description TEXT,
        description2 TEXT,
        unitCost INT,
        partNo TEXT
      )`;

      var sqlImages = `CREATE TABLE IF NOT EXISTS BreakdownInspectionImages (
        id TEXT PRIMARY KEY,
        inspectionId TEXT,
        base64 TEXT
      )`

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlInspectionParts);
        tx.executeSql(sqlInspectionFaults);
        tx.executeSql(sqlFaults);
        tx.executeSql(sqlParts);
        tx.executeSql(sqlImages);
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  addInspection(inspectionDate: any, nextInspectionDate: any, equipmentId: any, engineerId: any, jobId: any, 
    purposeId: any, colourId: any, missing: any, parts: any, faults, images, hours: any, hoursHalf: any, hoursDouble: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        Database.getDb().transaction((tx) => {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (
              id, inspectionDate, nextInspectionDate, equipmentId, engineerId, jobId, purposeId, colourId, missing, hours, hoursHalf, hoursDouble
            )
            VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
          `
          var inspectionId = uuid.v4();

          if (hours == null) { hours = 0; }
          if (hoursHalf == null) { hoursHalf = 0; }
          if (hoursDouble == null) { hoursDouble = 0; }

          tx.executeSql(sql, [
            inspectionId, inspectionDate, nextInspectionDate, equipmentId, engineerId, jobId, purposeId, colourId, missing,
            hours, hoursHalf, hoursDouble
          ]);

          if (faults != null) {
            for (let fault of faults) {
              var sql = `
                INSERT OR REPLACE INTO ${this.TABLE_NAME_INSPECTION_FAULTS} (
                  id, breakdownFaultId, actionTaken, remarks, hours, breakdownInspectionId
                )
                VALUES (?, ?, ?, ?, ?, ?)
              `;

              tx.executeSql(sql, [uuid.v4(), fault.commonFault.id, fault.action, fault.details, fault.hours, inspectionId]);
            }
          }

          if (parts != null) {
            for (let part of parts) {
              var sql = `
                INSERT OR REPLACE INTO ${this.TABLE_NAME_INSPECTION_PARTS} (
                  id, breakdownPartId, breakdownInspectionId, qty
                )
                VALUES (?, ?, ?, ?)
              `;

              tx.executeSql(sql, [uuid.v4(), part.id, inspectionId, part.qty]);
            }
          }

          if (images.length > 0) {
            sql = `INSERT INTO ${this.TABLE_NAME_IMAGES} (id, inspectionId, base64) VALUES (?, ?, ?)`;
            for (let image of images) {
              tx.executeSql(sql, [uuid.v4(), inspectionId, image]);
            }
          }

          // Update this asset to be completed 
          sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? and equipmentId = ?";
          tx.executeSql(sql, [jobId, equipmentId]);

        }, (error) => {
          console.log("ERROR");
          console.log(error);
          reject();
        }, () => {
          console.log("UPDATED JOB Assets - JobId: " + jobId + " EquipId: " + equipmentId);
          resolve();
        }) 
      } catch (e) {
        console.log("ERROR INSIDE BREAKDOWN")
        reject();
        console.log(e)
      }
    })
  }
  
  insertOrUpdateList(entities: any): Promise<void> {
    throw new Error('Method not implemented.');
  }

  insertOrUpdateCommonFaultsList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_COMMON_FAULTS} (id, fault)
            VALUES (?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.fault
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  insertOrUpdatePartsList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_PARTS} (id, itemCode, description, description2, unitCost, partNo)
            VALUES (?, ?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.itemCode, entity.description, entity.description2, entity.unitCost, entity.partNo
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getBreakdownParts() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM BreakdownParts
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }

  getBreakdownPartsBySearch(search: string) : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM BreakdownParts WHERE Description LIKE ? OR PartNo LIKE ?
        `;

        tx.executeSql(sql, [`%${search}%`, `%${search}%`], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getBreakdownCommonFaults() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM BreakdownCommonFaults
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }

  getForSync() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME}
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;

          var sqlFaults = `SELECT * FROM ${this.TABLE_NAME_INSPECTION_FAULTS} WHERE breakdownInspectionId = ?`;
          var sqlParts = `SELECT * FROM ${this.TABLE_NAME_INSPECTION_PARTS} WHERE breakdownInspectionId = ?`;
          for (let inspection of data) {
            tx.executeSql(sqlFaults, [inspection.id], (_, { rows: { _array } }) => {
              inspection.faults = _array;
            });
          }

          for (let inspection of data) {
            tx.executeSql(sqlParts, [inspection.id], (_, { rows: { _array } }) => {
              inspection.parts = _array;
            });

            sql = `SELECT base64 FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
            tx.executeSql(sql, [inspection.id], (_, { rows: { _array } }) => {
              inspection.images = [];
              for (let image of _array) {
                inspection.images.push(image.base64)
              }
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  deleteInspection(inspectionId) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `DELETE FROM ${this.TABLE_NAME_INSPECTION_PARTS} WHERE breakdownInspectionId = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

        sql = `DELETE FROM ${this.TABLE_NAME_INSPECTION_FAULTS} WHERE breakdownInspectionId = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

        sql = `DELETE FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

        sql = `DELETE FROM ${this.TABLE_NAME} WHERE id = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

}