import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator, Alert } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { JobDatabase } from '@store/services/database/job-database';
import { FlashList } from "@shopify/flash-list";
import { TitleBar } from '../../../components/TitleBar';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import moment from "moment";
import { setJobData } from '../../../../../store/slices/job-slice';
import { useIsFocused } from '@react-navigation/native';
import { clearInspectionData } from '../../../../../store/slices/rote-inspection-slice';
import { colours } from '../../../../../config';
import { EquipmentDatabase } from '@store/services/database/equipment-database';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { ServiceDatabase } from '@store/services/database/service-database';

export default function ServiceHistoryScreen({navigation, route}: {navigation: any, route: any}) {
  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const job = useSelector((state: RootState) => state.job);

  const [serviceHistory, setServiceHistory] = useState(null);



  const loadServiceHistory = async () => {
    const serviceDatabase = new ServiceDatabase();
    const serviceHistory = await serviceDatabase.getServiceHistory(job.equipmentId);
    setServiceHistory(serviceHistory);
    // var jobAssets = await jobDatabase.getJobAssets(job.jobId);
    // setJobAssets(jobAssets);
    console.log(serviceHistory);
  }  



  useEffect(() => {
    loadServiceHistory();
  }, [isFocused])

  if (serviceHistory == null) {
    return <LoadingIndicator/>
  }


  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} title="Service History" showBackButton={true} showSearchButton={false} onSynced={null} onSearch={null}/>

      <View style={styles.form}>
        <View style={styles.form__row}>
          <View style={styles.column}>
            <Text style={styles.column__label}>Previous Service Date</Text>
            <Text style={styles.column__value}>{serviceHistory.inspectedAt}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.column__label}>Engineer</Text>
            <Text style={styles.column__value}>{serviceHistory.engineerName}</Text>
          </View>

          <View style={styles.column}>
            <Text style={styles.column__label}>Status</Text>
            <Text style={styles.column__value}>{serviceHistory.status}</Text>
          </View>

          
        </View>
      </View>

      <View style={styles.form}>
        <View style={styles.form__row}>
          <View style={styles.column}>
            <Text style={styles.column__label}>Previous Comments</Text>
            <Text style={styles.column__value}>{serviceHistory.comments}</Text>
          </View>
        </View>
      </View>


      
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 40,
  },

  form: {
    marginTop: 10,
  },

    form__row: {
      flexDirection: 'row',
    },

    form__row__margin: {
      marginTop: 20,
    },

    column: {
      flex: 1,
      margin: 5,
      flexDirection: 'column'
    },

    column__label: {
      fontWeight: 'bold',
      fontSize: 18
    },

    column__value: {
      padding: 10,
      backgroundColor: '#ebe8e8',
      borderRadius: 5,
      marginTop: 5,
      fontSize: 24,
      borderWidth: 1,
      borderColor: '#cecece'
    },

  button: {
    backgroundColor: colours.primary,
    padding: 15,
  },

    button__text: {
      textAlign: 'center',
      color: '#FFF',
      fontFamily: 'OpenSans-Bold',
      fotnSize: 32
    }


});
