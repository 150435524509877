import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface RoteInspectionState {
  // Step 1
  firstExamination: boolean | null,
  installedCorrectly: boolean | null,

  // Step 2
  defects: string | null,
  immediateToPersons: boolean | null,
  becomeDangerToPersons: boolean | null,
  repairDate: string | null,
  repair: string | null,

  // Step 2
  identification: string | null,
  particulars: string | null,
  safeForUse: boolean | null,
}

const initialState: RoteInspectionState = {
  firstExamination: null,
  installedCorrectly: false,

  defects: "NONE",
  immediateToPersons: null,
  becomeDangerToPersons: false,
  repairDate: null,
  repair: null,

  identification: null,
  particulars: null,
  safeForUse: null,
}

export const roteInspectionSlice = createSlice({
  name: 'roteinspection',
  initialState,
  reducers: {
    setInspectionData: (state, action: PayloadAction<any>) => {
      if (action.payload.firstExamination != null) state.firstExamination = action.payload.firstExamination;
      if (action.payload.installedCorrectly != null) state.installedCorrectly = action.payload.installedCorrectly;

      if (action.payload.defects != null) state.defects = action.payload.defects;
      if (action.payload.immediateToPersons != null) state.immediateToPersons = action.payload.immediateToPersons;
      if (action.payload.becomeDangerToPersons != null) state.becomeDangerToPersons = action.payload.becomeDangerToPersons;
      if (action.payload.repairDate != null) state.repairDate = action.payload.repairDate;
      if (action.payload.repair != null) state.repair = action.payload.repair;

      if (action.payload.identification != null) state.identification = action.payload.identification;
      if (action.payload.particulars != null) state.particulars = action.payload.particulars;
      if (action.payload.safeForUse != null) state.firstExamination = action.payload.safeForUse;
    },

    clearInspectionData: (state, action: PayloadAction) => {
      state.firstExamination = null;
      state.installedCorrectly = null;
      state.defects = "NONE";
      state.immediateToPersons = null;
      state.becomeDangerToPersons = null;
      state.repairDate = null;
      state.repair = null;
      state.identification = null;
      state.particulars = null;
      state.safeForUse = null;
    },
  }
})

export const { setInspectionData, clearInspectionData } = roteInspectionSlice.actions;

export default roteInspectionSlice.reducer;