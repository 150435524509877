import { Feather } from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import DateTimePicker from "@web/components/navigation/controls/DateTimePicker";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, Platform } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from "../../constants/GlobalStyle";

export const JobManageControl = ({ companies, locations, allEngineers, job, onSubmit }) => {
  const [engineerId, setEngineerId] = React.useState("");

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      companyId: "",
      locationId: "",
      jobType: "",
      scheduledDate: new Date(Date.now()),
      notes: "",
      engineerIds: [],
      engineers: []
    }
  });

  React.useEffect(() => {
    if (job != null && allEngineers != null && allEngineers.length > 0) {
      console.log(job);
      setValue("id", job.id);
      setValue("companyId", job.companyId);
      setValue("locationId", job.locationId);
      setValue("jobType", job.jobType);
      setValue("scheduledDate", moment(job.scheduledDate).toDate());
      setValue("notes", job.notes);

      for (let engineerId of job.engineerIds) {
        addEngineer(engineerId);
      }
    }
  }, [job, allEngineers])

  const jobId = watch("id");
  const companyId = watch("companyId");
  const engineersList = watch("engineers");

  const submit = async (data) => {
    if (data.scheduledDate != null) {
      data.scheduledDate = moment(data.scheduledDate).format("YYYY-MM-DD");
    }
    data.jobType = parseInt(data.jobType);
    onSubmit(data);
  }

  const addEngineer = (engineerIdToAdd) => {
    if (engineerIdToAdd == null || engineerIdToAdd == "")
      return;

    var engineerIds = getValues("engineerIds");
    var formEngineers = getValues("engineers");

    const engineer = allEngineers.find(e => e.id == engineerIdToAdd);
    if (engineerIds.filter(e => e.id == engineer?.id).length > 0)
      return;

    engineerIds = [...engineerIds, engineer?.id];
    setValue("engineerIds", engineerIds);

    formEngineers = [...formEngineers, engineer];
    setValue("engineers", allEngineers.filter(e => engineerIds.includes(e.id)));
  }

  const removeEngineer = (engineer) => {
    var engineerIds = getValues("engineerIds");
    var formEngineers = getValues("engineers");

    engineerIds = engineerIds.filter(e => e != engineer.id);
    setValue("engineerIds", engineerIds);

    formEngineers = formEngineers.filter(e => e.id != engineer.id);
    setValue("engineers", formEngineers);

  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.form}>
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="companyId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Company (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    enabled={jobId == null}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    {companies != null && companies.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.companyId && <Text style={GlobalStyle.form__input__validation}>Company is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="locationId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Location (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    enabled={jobId == null}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    {locations != null && companyId != "" && locations.filter(l => l.companyId == companyId).map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.locationId && <Text style={GlobalStyle.form__input__validation}>Location is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="jobType"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Job Type (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    enabled={jobId == null}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />
                    <Picker.Item key={0} label={"ROTE"} value={0} />
                    <Picker.Item key={1} label={"Servicing"} value={1} />
                    <Picker.Item key={2} label={"Repair/Breakdown"} value={2} />
                    <Picker.Item key={3} label={"Proof Load"} value={3} />

                  </Picker>
                </View>
              )}
            />

            {errors.jobType && <Text style={GlobalStyle.form__input__validation}>Job Type is required.</Text>}
          </View>
        </View>

        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="notes"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Notes</Text>
                  <TextInput
                    style={GlobalStyle.form__column__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          {Platform.OS == "web" &&
            <View style={GlobalStyle.form__column}>
              <Controller
                control={control}
                name="scheduledDate"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View>
                    <Text style={GlobalStyle.form__column__text}>Scheduled Date</Text>
                    <DateTimePicker
                      style={GlobalStyle.form__column__input}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}>
                    </DateTimePicker>
                  </View>
                )}
              />
            </View>
          }
        </View>

        {allEngineers != null && allEngineers.length > 0 &&
          <View style={styles.engineer__picker__list}>
            <Text>Engineers</Text>
            <View style={styles.engineer__picker}>
              <Picker
                style={GlobalStyle.form__column__input}
                selectedValue={engineerId}
                onValueChange={(itemValue, itemIndex) => setEngineerId(itemValue)}
                itemStyle={{ height: 50 }}>

                <Picker.Item label="Please Select" value="" />

                {allEngineers != null && allEngineers.map((value, index) => {
                  return (
                    <Picker.Item key={index} label={value.name} value={value.id} />
                  )
                })}
              </Picker>

              <TouchableOpacity style={styles.engineer__picker__button} onPress={() => addEngineer(engineerId)}>
                <Text style={styles.engineer__picker__button__text}>Add</Text>
              </TouchableOpacity>
            </View>

            <View>
              {engineersList != null && engineersList.map((element: any) => {
                return (
                  <View style={styles.engineer__row} key={element.id}>
                    <Text style={styles.engineer__row__text}>{element.name}</Text>
                    <TouchableOpacity onPress={() => removeEngineer(element)}>
                      <Feather name="trash" size={20} color="red" />
                    </TouchableOpacity>
                  </View>
                )
              })}
            </View>
          </View>
        }

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(submit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
};


const styles = StyleSheet.create({
  container: {
    //flex: 1,
    //position: 'relative',
    padding: 10,
  },

  engineer__picker: {
    flexDirection: 'row',
  },

  engineer__picker__button: {
    backgroundColor: PRIMARY_COLOUR,
    borderWidth: 1,
    borderColor: '#CECECE',
    marginTop: 5,
    marginLeft: 10,
  },

  engineer__picker__button__text: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    color: '#FFF'
  },

  engineer__picker__list: {
    margin: 5,
  },

  engineer__row: {
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: '#f5f0f0',
    padding: 5,

    flexDirection: 'row',
    alignItems: 'center',
  },

  engineer__row__text: {
    fontFamily: 'OpenSans-Bold',
    padding: 5,
    marignRight: 10,
  }
});
