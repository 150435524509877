import { baseApi, PagedTableDto } from './base-api'

export const serviceApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getServicingInspections: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `servicing/GetServiceInspections?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    syncServiceQuestions: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `servicing/getservicequestions?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    syncServiceQuestionCategories: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `servicing/getservicequestioncategories?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    sendServiceInspection: build.mutation<any, any>({
      query: (body) => ({
        url: 'servicing/addservice',
        method: 'post',
        body: body
      }),
    }),
    syncServices: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `servicing/getservices?lastmodifiedon=${lastModifiedOn}`,
        
      })
    }),
    syncServicesQuestions: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `servicing/getservicesquestions?lastmodifiedon=${lastModifiedOn}`,
        
      })
    }),
  }),
  overrideExisting: true,
})

export const { useSyncServiceQuestionsMutation, useSyncServiceQuestionCategoriesMutation, useSendServiceInspectionMutation, useGetServicingInspectionsQuery } = serviceApi