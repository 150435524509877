import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';

export class RAMSDatabase extends Database {
  TABLE_NAME = "RAMS";
  TABLE_NAME_ANSWERED_QUESTIONS = "RAMSAnsweredQuestions";
  TABLE_NAME_QUESTIONS = "RAMSQuestions";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS RAMS ( 
        id TEXT PRIMARY KEY,
        engineerId TEXT,
        jobId TEXT,
        clientName TEXT,
        clientSignature TEXT,
        engineerSignature TEXT,
        toBeSynced INT
      )`;

      var sqlAnsweredQuestions = `CREATE TABLE IF NOT EXISTS RAMSAnsweredQuestions ( 
        id TEXT PRIMARY KEY,
        ramsId TEXT,
        ramsQuestionId TEXT,
        response INT,
        priority INT,
        severity INT,
        comments TEXT
      )`;

      var sqlQuestions = `CREATE TABLE IF NOT EXISTS RAMSQuestions ( 
        id TEXT PRIMARY KEY,
        position INT,
        title TEXT,
        question TEXT,
        questionType INT,
        expired INT
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlAnsweredQuestions);
        tx.executeSql(sqlQuestions);
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  insertOrUpdateList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          console.log(entity)

          tx.executeSql(`DELETE FROM ${this.TABLE_NAME} WHERE id = ?`, [entity.id]);

          if (entity.isDeleted || entity.isDeleted == 1) {
            continue;
          }


          var sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME} (id, engineerId, jobId, clientName, clientSignature, engineerSignature, toBeSynced)
          VALUES (?, ?, ?, null, null, null, 0)
        `

        tx.executeSql(sql, [entity.id, entity.engineerId, entity.jobId]);

          
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  addRams(questions: any, engineerId: any, jobId: any) : Promise<string> {
    const ramsId = uuid.v4();

    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME} (id, engineerId, jobId, clientName, clientSignature, engineerSignature, toBeSynced)
          VALUES (?, ?, ?, null, null, null, 1)
        `

        tx.executeSql(sql, [ramsId, engineerId, jobId]);

        // //update the job
        // console.log("Job id " + jobId);
        // console.log("rams id " + ramsId);
        // var sql = `
        // UPDATE Jobs  
        // SET ramsId = ?, toBeSynced = 1 
        // WHERE id = ?
        // `
        // tx.executeSql(sql, [ramsId,  jobId]);
        // console.log("UPDATEd Job");
        

        // // Insert the questions
        sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME_ANSWERED_QUESTIONS} (id, ramsId, ramsQuestionId, response, priority, severity, comments)
          VALUES (?, ?, ?, ?, ?, ?, ?)
        `;

        for (let question of questions) {
          tx.executeSql(sql, [uuid.v4(), ramsId, question.id, question.response, question.priority, question.severity, question.comments]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(ramsId.toString());
      });
    });
  }


  insertOrUpdateQuestionList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_QUESTIONS} (id, position, title, question, questionType, expired)
            VALUES (?, ?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.position, entity.title, entity.question, entity.questionType, entity.expired
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getRAMSQuestions() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM RAMSQuestions
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getForSync() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME} WHERE toBeSynced = 1
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;

          var sqlQuestions = `SELECT * FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE ramsId = ?`;
          for (let rams of data) {
            tx.executeSql(sqlQuestions, [rams.id], (_, { rows: { _array } }) => {
              rams.answeredQuestions = _array;
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  updateRAMSToBeSynced(id, toBeSynced) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          UPDATE ${this.TABLE_NAME} SET toBeSynced = ? WHERE id = ?
        `;

        tx.executeSql(sql, [toBeSynced, id], (_, { rows: { _array } }) => {
          
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }
}