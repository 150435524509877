import { baseUrl } from '@store/services/api/base-api';
import { useState } from 'react';
import { View, Text, StyleSheet, Modal, Image } from 'react-native';

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
}

export const ImageViewer = ({ images, onClosed }) => {
  const forceUpdate = useForceUpdate();
  const [selectedImageIdx, setSelectedImageIdx] = useState(0);

  document.addEventListener('keydown', (event) => {
    if (event.code == "ArrowRight") {
      nextImage();
    } else if (event.code == "ArrowLeft") {
      prevImage();
    } else if (event.code == "Escape") {
      onClosed();
    }
  })

  const nextImage = () => {
    var idx = selectedImageIdx + 1;
    if (idx > images.length - 1)
      return;

    setSelectedImageIdx(idx);
  }

  const prevImage = () => {
    if (selectedImageIdx == 0)
      return;

    var idx = selectedImageIdx - 1;
    setSelectedImageIdx(idx);
  }


  return (
    <View style={styles.container}>
      <Modal
        animationType="fade"
        transparent={true}>
          <View style={styles.modalContainer}>
            <View style={styles.centeredContainer}>
              { images[selectedImageIdx].imageName != null &&
                <View style={styles.imagerViewerContainer}>
                  <Image
                    style={[styles.image]}
                    resizeMode={"contain"}
                    source={{
                      uri: baseUrl + images[selectedImageIdx].image,
                    }}
                  />
                  <Text style={styles.imageText}>{images[selectedImageIdx].imageName} - Image {selectedImageIdx + 1} of {images.length}</Text>
                </View>
              }

              { images[selectedImageIdx].imageName == null &&
                <View style={styles.imagerViewerContainer}>
                  <Image
                    style={[styles.image]}
                    resizeMode={"contain"}
                    source={{
                      uri: baseUrl + images[selectedImageIdx],
                    }}
                  />
                  <Text style={styles.imageText}>Image {selectedImageIdx + 1} of {images.length}</Text>
                </View>
              }
            </View>
          </View>
          
      </Modal>
    </View>
  )
}


const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    // left: 0, top: 0, right: 0, bottom: 0,
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    // //backgroundColor: 'red',
    // zIndex: 999999,
    // height: '100vh',
    // width: '100vw',

    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center'

    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999999,
  },

  centeredContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  
  imagerViewerContainer: {
    backgroundColor: '#FFF',
    width: 850,
    height: 600,

    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },

  image: {
    height: 500,
    width: 800,
  },

  imageText: {
    marginTop: 10,
    fontWeight: 'bold'
  }

});