import * as SQLite from 'expo-sqlite';
import { Database } from './database';

export class CompanyDatabase extends Database {
  TABLE_NAME = "Companies";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Companies ( 
        id TEXT PRIMARY KEY,
        name TEXT,
        address1 TEXT,
        address2 TEXT,
        address3 TEXT,
        address4 TEXT,
        phone TEXT,
        postcode TEXT
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
        // List our db
        // Database.getDb().transaction((tx2) => {
        //   tx2.executeSql("select * from SQLite_master", [],  (_, { rows: { _array } }) => console.log(_array));
        // })

        // resolve();
      })
    });
  }

  insertOrUpdateList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (id, name, address1, address2, address3, address4, phone, postcode)
            VALUES (?, ?, ?, ?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [entity.id, entity.name, entity.address1, entity.address2, entity.address3, entity.address4, entity.phone, entity.postcode]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getAllCompanies() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME} ORDER BY Name
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          //console.log(_array)
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }
}