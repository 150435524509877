import { baseApi, PagedTableDto } from './base-api'

export const locationApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getLocations: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `location/getlocations?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getLocation: build.query<any, any>({
      query: (locationId) => ({
        url: `location/getlocation/${locationId}`,
      }),
    }),
    getAllLocations: build.query<any, any>({
      query: (companyId) => ({
        url: `location/getalllocations`,
      }),
    }),
    createLocation: build.mutation<any, any>({
      query: (body) => ({
        url: 'location/addlocation',
        method: 'post',
        body: body
      }),
    }),
    updateLocation: build.mutation<any, any>({
      query: (body) => ({
        url: `location/updatelocation/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    syncLocations: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `location/getlocationsforsync?lastmodifiedon=${lastModifiedOn}`,
      })
    })
  }),
  overrideExisting: true,
})

export const { useGetLocationsQuery, useGetLocationQuery, useGetAllLocationsQuery, useCreateLocationMutation, useUpdateLocationMutation } = locationApi