import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';

import { useIsFocused } from "@react-navigation/native";
import { Picker } from '@react-native-picker/picker';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { useCreateUserMutation, userApi, useUpdateUserMutation } from '@store/services/api/user-api';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';

export default function WebUserManage({navigation, route}: {navigation: any, route: any}) {
  const [createUser, createUserResult] = useCreateUserMutation();
  const [updateUser, updateUserResult] = useUpdateUserMutation();
  const { data: companies } = useGetAllCompaniesQuery(null);

  const [trigger, usersResult] =  userApi.useLazyGetUserQuery();



  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "Admin",
      companyId: null
    }
  });

  const userId = watch("id");
  const role = watch("role");

  React.useEffect(() => {
    if (route.params?.userId != null) {
      trigger(route.params?.userId);
    }
  }, [])

  React.useEffect(() => {
    if (usersResult.isSuccess) {
      var data = usersResult.data;
      setValue("id", data.id);
      setValue("name", data.name);
      setValue("email", data.email);
      setValue("companyId", data.companyId);
    }
  }, [usersResult]);

  const onSubmit = async (data) => {
    var result = null;

    if (data.password != data.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    if (data.id != null) {
      result = await updateUser(data) as any;
    } else {
      result = await createUser(data) as any;
    }

    if (result.error) {
      alert(result.error.data);
      return;
    }

    navigation.goBack();
  };

  if (createUserResult.isLoading || updateUserResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon}  onPress={() => navigation.goBack() }>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{userId != null ? "CREATE USER" : "UPDATE USER"}</Text>
      </View>
      
      <View style={GlobalStyle.form}>
        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Name (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.name && <Text style={GlobalStyle.form__input__validation}>Name is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="email"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Email (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.email && <Text style={GlobalStyle.form__input__validation}>Email is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="role"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Company (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Admin" value={"Admin"} />
                    <Picker.Item label="Client" value={"User"} />
                  </Picker>
                </View>
              )}
            />

            {errors.companyId && <Text style={GlobalStyle.form__input__validation}>Company is required.</Text>}
          </View>
        </View>

        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: userId != null ? false : true }}
              name="password"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Password (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    secureTextEntry={true}
                    placeholder={userId != null ? 'Unchanged' : ''}>
                  </TextInput>
                </View>
              )}
            />

            {errors.password && <Text style={GlobalStyle.form__input__validation}>Password is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: userId != null ? false : true }}
              name="confirmPassword"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Confirm Password (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    secureTextEntry={true}
                    placeholder={userId != null ? 'Unchanged' : ''}>
                  </TextInput>
                </View>
              )}
            />

            {errors.confirmPassword && <Text style={GlobalStyle.form__input__validation}>Password is required.</Text>}
          </View>

          { role == 'User' && <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="companyId"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Company (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) =>
                      onChange(itemValue)
                    }>

                    <Picker.Item label="Please Select" value="" />

                    { companies != null && companies.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.companyId && <Text style={GlobalStyle.form__input__validation}>Company is required.</Text>}
          </View> }
        </View>

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
