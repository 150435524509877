import { useWindowDimensions, View, Text } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { DrawerContent } from '../components/navigation/DrawerContent';

import CompaniesList from './AuthenticatedScreens/Management/Companies/CompaniesList';
import DashboardScreen from './AuthenticatedScreens/DashboardScreen';

import { RootState } from '../../../store';
import { Header } from '../components/navigation/Header';

import Companies from './AuthenticatedScreens/Management/Companies/Companies';
import Locations from './AuthenticatedScreens/Management/Locations/Locations';
import Categories from './AuthenticatedScreens/Management/Categories/Categories';
import Manufacturers from './AuthenticatedScreens/Management/Manufacturers/Manufacturers';
import Equipment from './AuthenticatedScreens/Management/Equipment/Equipment';
import Jobs from './AuthenticatedScreens/Jobs/Jobs';
import BreakdownParts from './AuthenticatedScreens/Management/Parts/Parts';
import TabletUsers from './AuthenticatedScreens/Management/TabletUsers/TabletUsers';
import WebUsers from './AuthenticatedScreens/Management/WebUsers/WebUsers';
import InspectionScreen from './AuthenticatedScreens/Reports/Inspections';
import BreakdownInspectionScreen from './AuthenticatedScreens/Reports/Breakdowns';
import ServiceInspectionScreen from './AuthenticatedScreens/Reports/Services';
import AssetRegisterScreen from './AuthenticatedScreens/Reports/AssetRegister';
import JobApprovalScreen from './AuthenticatedScreens/Jobs/JobApproval/JobApproval';
import EngineerReportsScreen from './AuthenticatedScreens/Reports/EngineerReports';
import RAMSScreen from './AuthenticatedScreens/Reports/RAMS';
import ProofLoadsScreen from './AuthenticatedScreens/Reports/ProofLoads';

const Drawer = createDrawerNavigator();

export default function WebNavigator({navigation}: {navigation: any}) {
  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width >= 768;
  const user = useSelector((state: RootState) => state.user.user);
  
  useEffect(() => {
    if (user == null) {
      navigation.navigate("login")
    }
  }, [user])

  return (
    <Drawer.Navigator 
      useLegacyImplementation={true}
      detachInactiveScreens={true}
      defaultStatus="open"
      screenOptions={{
        drawerType: isLargeScreen ? 'permanent' : 'back',
        drawerStyle: isLargeScreen ? {width: 250} : { width: '100%' },
        overlayColor: 'transparent',
        header: (props) => Header(props, user),
        unmountOnBlur: true
      }}
      drawerContent={(props) => <DrawerContent {...props}/> }
    >
      <Drawer.Screen name="dashboard" component={DashboardScreen} options={{ title: 'Dashboard' }} />

      <Drawer.Screen name="reports-asset-register" component={AssetRegisterScreen} options={{ title: 'Asset Register' }} />
      <Drawer.Screen name="reports-inspections" component={InspectionScreen} options={{ title: 'Inspections' }} />
      <Drawer.Screen name="reports-breakdowns" component={BreakdownInspectionScreen} options={{ title: 'Repair/Breakdown Inspections' }} />
      <Drawer.Screen name="reports-servicing" component={ServiceInspectionScreen} options={{ title: 'Servicing Inspections' }} />
      <Drawer.Screen name="reports-erstr" component={EngineerReportsScreen} options={{ title: 'Engineer Reports' }} />
      <Drawer.Screen name="reports-rams" component={RAMSScreen} options={{ title: 'PoWRA Reports' }} />
      <Drawer.Screen name="reports-proofloads" component={ProofLoadsScreen} options={{ title: 'Proof Load Reports' }} />

      <Drawer.Screen name="job-management" component={Jobs} options={{ title: 'Jobs' }} />
      <Drawer.Screen name="job-approval" component={JobApprovalScreen} options={{ title: 'Job Approval' }} />

      <Drawer.Screen name="administration-companies" component={Companies} options={{ title: 'Companies' }} />
      <Drawer.Screen name="administration-locations" component={Locations} options={{ title: 'Locations' }} />
      <Drawer.Screen name="administration-categories" component={Categories} options={{ title: 'Categories' }} />
      <Drawer.Screen name="administration-manufacturers" component={Manufacturers} options={{ title: 'Manufacturers' }} />
      <Drawer.Screen name="administration-equipment" component={Equipment} options={{ title: 'Equipment' }} />
      <Drawer.Screen name="administration-breakdown-parts" component={BreakdownParts} options={{ title: 'Breakdown Parts' }} />

      <Drawer.Screen name="administration-web-users" component={WebUsers} options={{ title: 'Web Users' }} />
      <Drawer.Screen name="administration-engineers" component={TabletUsers} options={{ title: 'Engineers' }} />
    </Drawer.Navigator>
  );
}

