import { useRef } from "react";
import { View, StyleSheet, Text, TextInput, Modal, TouchableOpacity } from 'react-native';
import { colours } from '../../../../../../config';
import { useState, useEffect } from 'react';
import Signature from "react-native-signature-canvas";
import { Colors } from "react-native/Libraries/NewAppScreen";
import { Alert } from "react-native";

export const ClientSignOff = ({ onSave }) => {
  const ref = useRef();
  const [clientName, setClientName] = useState("");
  const [clientSignature, setClientSignature] = useState("");

  const save = () => {
    if (clientName == null || clientName == "") {
      Alert.alert("", "Enter client name");
      return;
    }

    ref.current.readSignature();
  }

  const handleOK = (signature) => {
    console.log(signature);
    onSave({
      clientName: clientName,
      clientSignatureBase64: signature
    })
  };

  const clear = () => {
    ref.current.clearSignature();
  }

  const style = `.m-signature-pad--footer {display: none; margin: 0px;}`;

  return (
    <View style={styles.modal__block}>
      <View style={styles.modal}>
        <Text style={styles.header}>Client Sign Off</Text>

        <View style={styles.step__question}>
          <Text style={styles.step__question__label}>Client Name</Text>

          <TextInput
            style={styles.step__question__input}
            onChangeText={(text) => setClientName(text)}
            value={clientName}
          />
        </View>

        <View style={styles.step__question}>
          <Text style={styles.step__question__label}>Client Signature</Text>

          <View style={{ height: 400 }}>
            <Signature
              ref={ref}
              onOK={handleOK}
              descriptionText=""
              clearText="Clear"
              confirmText="Save"
              webStyle={style}
            />
          </View>
        </View>

        <View style={styles.action__buttons}>
          <TouchableOpacity style={styles.cancel__button} onPress={() => clear()}>
            <Text style={styles.cancel__button__text}>CLEAR</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.proceed__button} onPress={() => save()}>
            <Text style={styles.proceed__button__text}>SAVE</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}


const styles = StyleSheet.create({
  modal__block: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },

    modal: {
      backgroundColor: '#FFF',
      padding: 10,
      width: 600,
      borderRadius: 5,      
    },

  header: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 24
  },

  step__question: {
    padding: 0,
    backgroundColor: '#FFF',
    marginTop: 10,
  },

  step__question__label: {
    fontFamily: 'OpenSans-Light',
    fontSize: 18,
    marginBottom: 5,
  },

  step__question__input: {
    borderWidth: 1,
    borderColor: '#CECECE',
    padding: 10,
    marginTop: 5,
  },


  proceed__button: {
    padding: 10,
    backgroundColor: colours.primary,
    marginTop: 20,
    borderRadius: 7,
    height: 45,


    flex: 1,
    marginLeft: 5,
  },

    proceed__button__text: {
      fontFamily: 'OpenSans-Bold',
      color: '#FFF',
      textAlign: 'center',
      fontSize: 18
    },

  action__buttons: {
    flexDirection: 'row',
  },

  cancel__button: {
    padding: 10,
    backgroundColor: '#FFF',
    marginTop: 20,
    borderRadius: 7,
    height: 45,
    flex: 1,
    marginRight: 5,
  },

  cancel__button__text: {
    fontFamily: 'OpenSans-Bold',
    textAlign: 'center',
    fontSize: 18,
    color: colours.primary
  },

});