export class ReportableService {
   private baseUrl = "https://reportable.corerfid.net";
  //private baseUrl = "http://reportable.dev7.corerfid.com";

  public downloadAssetRegister = async (locationId, base64: boolean = false) => {
    const response = await fetch(`${this.baseUrl}/api/elitecranes/getassetregister?locationId=${locationId}`, {
      method: "GET",
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) { return data; }
      let blob = this.convertBase64toBlob(data, 'application/pdf');
      return blob;
    }
  }

  public downloadBreakdown = async (inspectionId, base64: boolean = false) => {
    const response = await fetch(`${this.baseUrl}/api/elitecranes/getbreakdown?inspectionId=${inspectionId}`, {
      method: "GET",
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) { return data; }
      let blob = this.convertBase64toBlob(data, 'application/pdf');
      return blob;
    }
  }

  public downloadDoC = async (equipmentId, base64: boolean = false) => {
    const response = await fetch(`${this.baseUrl}/api/elitecranes/getdoc?equipmentId=${equipmentId}`, {
      method: "GET",
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) { return data; }
      let blob = this.convertBase64toBlob(data, 'application/pdf');
      return blob;
    }
  }

  public downloadERSTR = async (inspectionId, base64: boolean = false) => {
    const response = await fetch(`${this.baseUrl}/api/elitecranes/geterstr?engineerReportId=${inspectionId}`, {
      method: "GET",
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) { return data; }
      let blob = this.convertBase64toBlob(data, 'application/pdf');
      return blob;
    }
  }

  public downloadProofLoad = async (inspectionId, base64: boolean = false) => {
    const response = await fetch(`${this.baseUrl}/api/elitecranes/getproofload?inspectionId=${inspectionId}`, {
      method: "GET",
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) { return data; }
      let blob = this.convertBase64toBlob(data, 'application/pdf');
      return blob;
    }
  }

  public downloadPPE = async (inspectionId, base64: boolean = false) => {
    const response = await fetch(`${this.baseUrl}/api/elitecranes/getppe?inspectionId=${inspectionId}`, {
      method: "GET",
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) { return data; }
      let blob = this.convertBase64toBlob(data, 'application/pdf');
      return blob;
    }
  }

  public downloadRote = async (inspectionId, base64: boolean = false) => {
    const response = await fetch(`${this.baseUrl}/api/elitecranes/getliftingexam?inspectionId=${inspectionId}`, {
      method: "GET",
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) { return data; }
      let blob = this.convertBase64toBlob(data, 'application/pdf');
      return blob;
    }
  }

  public downloadService = async (inspectionId, base64: boolean = false) => {
    const response = await fetch(`${this.baseUrl}/api/elitecranes/getservice?inspectionId=${inspectionId}`, {
      method: "GET",
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) { return data; }
      let blob = this.convertBase64toBlob(data, 'application/pdf');
      return blob;
    }
  }

  public downloadRAMS = async (ramsId, base64: boolean = false) => {
    const response = await fetch(`${this.baseUrl}/api/elitecranes/getrams?ramsId=${ramsId}`, {
      method: "GET",
    });

    if (response.status == 200) {
      var data = await response.json();
      if (base64) { return data; }
      let blob = this.convertBase64toBlob(data, 'application/pdf');
      return blob;
    }
  }


  private convertBase64toBlob = (content, contentType) => {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}
