import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import { SQLTransaction } from 'expo-sqlite';
import uuid from 'react-native-uuid';

export class EquipmentDatabase extends Database {
  TABLE_NAME = "Equipment";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Equipment ( 
        id TEXT PRIMARY KEY,
        description TEXT,
        serialNumber TEXT,
        assetNumber TEXT,
        dateOfManufacture TEXT,
        proofLoad TEXT,
        swl TEXT,
        qr TEXT,
        locationId TEXT,
        categoryId TEXT,
        manufacturerId TEXT,

        transposedStandards TEXT,
        nationalStandards TEXT,
        dateOfFirstUse TEXT,
        
        abusNo TEXT, 
        hoistType TEXT,
        numberOfHoists INT,
        yearOfCommissioning TEXT,
        isCraneRadio INT,
        DOC INT,
        scrapped INT,
        toBeSynced INT
      )`;

      var sqlExtras = `CREATE TABLE IF NOT EXISTS EquipmentExtras (
        id TEXT PRIMARY KEY,
        serialNum TEXT,
        make TEXT,
        equipmentExtraType INT,
        equipmentId TEXT,
        make2 TEXT,
        serialNum2 TEXT,
        type TEXT,
        type2 TEXT,
        description TEXT,
        diameter TEXT,
        length TEXT
      )`;

      var sqlLoadTypes = `CREATE TABLE IF NOT EXISTS LoadTypes (
        id TEXT PRIMARY KEY,
        type TEXT
      )`;

      var sqlLoadUnits = `CREATE TABLE IF NOT EXISTS LoadUnits (
        id TEXT PRIMARY KEY,
        unit TEXT
      )`;

      

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlExtras);
        tx.executeSql(sqlLoadTypes);
        tx.executeSql(sqlLoadUnits);

        //check for new columns
        var checkColumnSql = "PRAGMA table_info(Equipment)";
        tx.executeSql(checkColumnSql, [], (_, { rows: { _array } }) => {
          const columnExists = _array.some((column) => column.name === "qr");
          
          if (!columnExists) {
            var alterSql = `ALTER TABLE Equipment ADD COLUMN qr TEXT`;
            tx.executeSql(alterSql);
          }
        });

        checkColumnSql = "PRAGMA table_info(EquipmentExtras)";
        tx.executeSql(checkColumnSql, [], (_, { rows: { _array } }) => {
          const columnExists = _array.some((column) => column.name === "description");
          
          if (!columnExists) {
            var alterSql = `ALTER TABLE EquipmentExtras ADD COLUMN description TEXT`;
            tx.executeSql(alterSql);
          }
        });

        checkColumnSql = "PRAGMA table_info(EquipmentExtras)";
        tx.executeSql(checkColumnSql, [], (_, { rows: { _array } }) => {
          const columnExists = _array.some((column) => column.name === "diameter");
          
          if (!columnExists) {
            var alterSql = `ALTER TABLE EquipmentExtras ADD COLUMN diameter TEXT`;
            tx.executeSql(alterSql);
          }
        });

        checkColumnSql = "PRAGMA table_info(EquipmentExtras)";
        tx.executeSql(checkColumnSql, [], (_, { rows: { _array } }) => {
          const columnExists = _array.some((column) => column.name === "length");
          
          if (!columnExists) {
            var alterSql = `ALTER TABLE EquipmentExtras ADD COLUMN length TEXT`;
            tx.executeSql(alterSql);
          }
        });


      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  insertOrUpdateLoadUnitsList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO LoadUnits (id, unit)
            VALUES (?, ?)
          `;

          tx.executeSql(sql, [
            entity.id, entity.unit
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      });
    });
  }

  insertOrUpdateLoadTypesList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO LoadTypes (id, type)
            VALUES (?, ?)
          `;

          tx.executeSql(sql, [
            entity.id, entity.type
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      });
    });
  }

  insertOrUpdateList(entities: any, toBeSynced: boolean) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          tx.executeSql(`DELETE FROM EquipmentExtras WHERE equipmentId = ?`, [entity.id]);

          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (
              id, description, serialNumber, assetNumber, dateOfManufacture, proofLoad, swl,qr, locationId, categoryId, manufacturerId,
              DOC, transposedStandards, nationalStandards, dateOfFirstUse, scrapped, toBeSynced, abusNo, hoistType, numberOfHoists, 
              yearOfCommissioning, isCraneRadio
            )
            VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?,?)
          `

          tx.executeSql(sql, [
            entity.id, entity.description, entity.serialNumber, entity.assetNumber, entity.dateOfManufacture, entity.proofLoad, 
            entity.swl, entity.qr,entity.locationId, entity.categoryId, entity.manufacturerId,
            entity.doc == true ? 1 : 0, entity.transposedStandards, entity.nationalStandards, entity.dateOfFirstUse, entity.scrapped, 
            toBeSynced, entity.abusNo, entity.hoistType, entity.numberOfHoists, entity.yearOfCommissioning, entity.isCraneRadio
          ]);

          for (let extra of entity.equipmentExtras) {
            console.log("SAVING EXTRAS");
            if (extra.id == null) {
              extra.id = uuid.v4();

            }
            tx.executeSql(`INSERT OR REPLACE INTO EquipmentExtras (id, serialNum, make, equipmentExtraType, equipmentId, make2, serialNum2, type, type2, description,diameter,length)
             VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ? ,? ,?)`, [
              extra.id, extra.serialNum, extra.make, extra.equipmentExtraType, entity.id, extra.make2, extra.serialNum2, extra.type, extra.type2, extra.description, extra.diameter, extra.length
            ]);
          }
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getById(equipmentId) : Promise<any> {
    return new Promise((resolve, reject) => {
      var equipment = null;
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT Equipment.*, Manufacturers.name AS manufacturerName, Categories.name AS categoryName FROM Equipment
          INNER JOIN Manufacturers ON Equipment.manufacturerId = Manufacturers.id
          INNER JOIN Categories ON Equipment.categoryId = Categories.id
          WHERE Equipment.id = ? 
        `;

        tx.executeSql(sql, [equipmentId], (_, { rows: { _array } }) => {
          if (_array.length > 0) {
            equipment = _array[0];
            var sqlExtras = `SELECT * FROM EquipmentExtras WHERE equipmentId = ?`;
            tx.executeSql(sqlExtras, [equipment.id], (_, { rows: { _array } }) => {
              equipment.equipmentExtras = _array;
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(equipment);
      })
    })
  }

  getByLocationId(locationId) : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Equipment WHERE locationId = ? AND scrapped = 0
        `;

        tx.executeSql(sql, [locationId], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getForSync() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Equipment WHERE toBeSynced = 1
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
          for (var equipment of data) {
            console.log(equipment);
            var sqlExtras = `SELECT * FROM EquipmentExtras WHERE equipmentId = ?`;
            tx.executeSql(sqlExtras, [equipment.id], (_, { rows: { _array } }) => {
              console.log("EQUIPMENT EXTRAS");
              console.log(_array);
              equipment.equipmentExtras = _array;
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  setToBeSynced(equipmentId: any, toBeSynced: boolean) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
            UPDATE ${this.TABLE_NAME} SET toBeSynced = ? WHERE id = ?
          `

          tx.executeSql(sql, [toBeSynced, equipmentId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  setScrapped(equipmentId: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `UPDATE ${this.TABLE_NAME} SET scrapped = 1, toBeSynced = 1 WHERE id = ?`
        tx.executeSql(sql, [equipmentId]);
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getLoadTypes() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM LoadTypes ORDER BY Type
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getLoadUnits() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM LoadUnits ORDER BY Unit
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }
}