import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';
import { SQLTransaction } from 'expo-sqlite';

export class ServiceDatabase extends Database {
  TABLE_NAME = "ServiceInspections";
  TABLE_NAME_ANSWERED_QUESTIONS = "ServiceInspectionsAnsweredQuestions";
  TABLE_NAME_QUESTIONS = "ServiceQuestions";
  TABLE_NAME_QUESTION_CATEGORIES = "ServiceQuestionCategories";
  // TABLE_NAME_IMAGES = "ServiceInspectionImages";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS ServiceInspections ( 
        id TEXT PRIMARY KEY,
        inspectionDate TEXT,
        nextInspectionDate TEXT,
        safeforUse INT,

        equipmentId TEXT,
        engineerId TEXT,
        engineerName TEXT,
        jobId TEXT,
        purposeId TEXT,
        colourId TEXT,
        missing INT,
        toBeSynced INT
      )`;

      var sqlAnsweredQuestions = `CREATE TABLE IF NOT EXISTS ServiceInspectionsAnsweredQuestions ( 
        id TEXT PRIMARY KEY,
        questionId TEXT,
        response INT,
        comments TEXT,
        serviceInspectionId TEXT,
        imageBase64 TEXT
      )`;

      var sqlQuestions = `CREATE TABLE IF NOT EXISTS ServiceQuestions ( 
        id TEXT PRIMARY KEY,
        position INT,
        question TEXT,
        suffix TEXT,
        serviceQuestionCategoryId TEXT
      )`;

      var sqlQuestionCategories = `CREATE TABLE IF NOT EXISTS ServiceQuestionCategories ( 
        id TEXT PRIMARY KEY,
        position INT,
        title TEXT
      )`;

      var sqlImages = `CREATE TABLE IF NOT EXISTS InspectionImages (
        id TEXT PRIMARY KEY,
        inspectionId TEXT,
        base64 TEXT
      )`  

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlAnsweredQuestions);
        tx.executeSql(sqlQuestions);
        tx.executeSql(sqlQuestionCategories);
        tx.executeSql(sqlImages);



        //check for new columns
        var checkColumnSql = "PRAGMA table_info(ServiceInspections)";
        tx.executeSql(checkColumnSql, [], (_, { rows: { _array } }) => {
          const columnExists = _array.some((column) => column.name === "equipmentId");
          
          if (!columnExists) {
            var alterSql = `ALTER TABLE ServiceInspections ADD COLUMN equipmentId TEXT`;
            tx.executeSql(alterSql);
          }
        });

        checkColumnSql = "PRAGMA table_info(ServiceInspections)";
        tx.executeSql(checkColumnSql, [], (_, { rows: { _array } }) => {
          const columnExists = _array.some((column) => column.name === "engineerName");
          
          if (!columnExists) {
            var alterSql = `ALTER TABLE ServiceInspections ADD COLUMN engineerName TEXT`;
            tx.executeSql(alterSql);
          }
        });

        checkColumnSql = "PRAGMA table_info(ServiceInspections)";
        tx.executeSql(checkColumnSql, [], (_, { rows: { _array } }) => {
          const columnExists = _array.some((column) => column.name === "toBeSynced");
          
          if (!columnExists) {
            var alterSql = `ALTER TABLE ServiceInspections ADD COLUMN toBeSynced INT`;
            tx.executeSql(alterSql);
          }
        });


        
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }  

  addInspection(inspectionDate: any, nextInspectionDate: any, safeForUse: any, equipmentId: any, engineerId: any, jobId: any, 
    purposeId: any, colourId: any, missing: any,toBeSynced:1, questions: any, images: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        Database.getDb().transaction((tx) => {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (
              id, inspectionDate, nextInspectionDate, safeForUse, equipmentId, engineerId, jobId, purposeId, colourId, missing,toBeSynced
            )
            VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
          `
          var serviceId = uuid.v4();

          tx.executeSql(sql, [
            serviceId, inspectionDate, nextInspectionDate, safeForUse, equipmentId, engineerId, jobId, purposeId, colourId, missing, toBeSynced
          ]);


          //
          for (let question of questions) {
            var sql = `
              INSERT OR REPLACE INTO ${this.TABLE_NAME_ANSWERED_QUESTIONS} (
                id, questionId, response, comments, serviceInspectionId, imageBase64
              )
              VALUES (?, ?, ?, ?, ?, ?)
            `;

            tx.executeSql(sql, [uuid.v4(), question.id, question.response, question.comments, serviceId, question.imageBase64]);
            console.log("added service question")
          }

          // if (images.length > 0) {
          //   sql = `INSERT INTO ${this.TABLE_NAME_IMAGES} (id, inspectionId, base64) VALUES (?, ?, ?)`;
          //   for (let image of images) {
          //     tx.executeSql(sql, [uuid.v4(), serviceId, image]);
          //   }
          // }

          // Update this asset to be completed 
          sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? and equipmentId = ?";
          tx.executeSql(sql, [jobId, equipmentId]);

        }, (error) => {
          console.log("ERROR");
          console.log(error);
          reject();
        }, () => {
          console.log("UPDATED JOB Assets - JobId: " + jobId + " EquipId: " + equipmentId);
          resolve();
        }) 
      } catch (e) {
        console.log("ERROR INSIDE SERVICING")
        reject();
        console.log(e)
      }
    })
  }

  insertOrUpdateQuestionList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_QUESTIONS} (id, position, question, suffix, serviceQuestionCategoryId)
            VALUES (?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.position, entity.question, entity.suffix, entity.serviceQuestionCategoryId
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  insertOrUpdateQuestionCategoryList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_QUESTION_CATEGORIES} (id, position, title)
            VALUES (?, ?, ?)
          `

          tx.executeSql(sql, [
            entity.id, entity.position, entity.title
          ]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getServiceQuestions() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT ServiceQuestions.id, ServiceQuestions.position as questionPosition, question, suffix, ServiceQuestionCategories.title,
          ServiceQuestionCategories.position as questionCategoryPosition
          FROM ServiceQuestions
          INNER JOIN ServiceQuestionCategories ON ServiceQuestions.serviceQuestionCategoryId = ServiceQuestionCategories.id
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getForSync() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME} WHERE toBeSynced = 1
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
          console.log("Getting services in sync method");
          console.log(data);

          var sqlQuestions = `SELECT * FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE serviceInspectionId = ?`;
       
          for (let inspection of data) {
            console.log("getting service questions for sync");
            console.log(inspection.id);

            tx.executeSql(sqlQuestions, [inspection.id], (_, { rows: { _array } }) => {
              inspection.questions = _array;
            });

            // sql = `SELECT base64 FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
            // tx.executeSql(sql, [inspection.id], (_, { rows: { _array } }) => {
            //   inspection.images = [];
            //   for (let image of _array) {
            //     inspection.images.push(image.base64)
            //   }
            // });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  deleteInspection(inspectionId) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `DELETE FROM ${this.TABLE_NAME_ANSWERED_QUESTIONS} WHERE serviceInspectionId = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

        // sql = `DELETE FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
        // tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });

        sql = `DELETE FROM ${this.TABLE_NAME} WHERE id = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }
  removeSyncInspection(inspectionId) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {

        var sql = `UPDATE ${this.TABLE_NAME} SET toBeSynced = 0 WHERE id = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => { });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  removeOldServices() : Promise<any> {
    return new Promise((resolve, reject) => {
      console.log("removing old service inspections")
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
        SELECT * FROM ServiceInspections WHERE DATE(ServiceInspections.inspectionDate) < DATE('now','-1 years')
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
          console.log(data);
       
          for (let inspection of data) {
            this.deleteInspection(inspection.id);
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  addInspectionHistory(id: any,inspectionDate: any, nextInspectionDate: any, safeForUse: any, equipmentId: any, engineerId: any, jobId: any, 
    purposeId: any, colourId: any, missing: any,toBeSynced:0,engineerName: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        Database.getDb().transaction((tx) => {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (
              id, inspectionDate, nextInspectionDate, safeForUse, equipmentId, engineerId, jobId,  missing,toBeSynced, engineerName
            )
            VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
          `

          tx.executeSql(sql, [
            id, inspectionDate, nextInspectionDate, safeForUse, equipmentId, engineerId, jobId, missing, toBeSynced, engineerName
          ]);



        }, (error) => {
          console.log("ERROR");
          console.log(error);
          reject();
        }, () => {
          resolve();
        }) 
      } catch (e) {
        console.log("ERROR INSIDE SERVICING")
        reject();
        console.log(e)
      }
    })
  }
  addInspectionQuestionHistory(id: any,questionId: any, response: any, comments: any, serviceInspectionId: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        Database.getDb().transaction((tx) => {
          var sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME_ANSWERED_QUESTIONS} (
                   id, questionId, response, comments, serviceInspectionId
                 )
                 VALUES (?, ?, ?, ?, ?)
          `
          // var serviceId = uuid.v4();

          tx.executeSql(sql,[ id, questionId, response, comments, serviceInspectionId]);


        }, (error) => {
          console.log("ERROR");
          console.log(error);
          reject();
        }, () => {

          resolve();
        }) 
      } catch (e) {
        console.log("ERROR INSIDE SERVICING")
        reject();
        console.log(e)
      }
    })
  }

  getServiceHistory(id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
        SELECT strftime('%d/%m/%Y', serviceinspections.inspectiondate) AS inspectedAt,
                serviceinspections.engineername,
                all_comments.comments,
                CASE
                    WHEN serviceinspections.safeforuse = 1 AND serviceinspections.missing = 0 THEN 'Servicable'
                    WHEN serviceinspections.safeforuse = 0 AND serviceinspections.missing = 0 THEN 'Removed from service'
                    WHEN serviceinspections.missing = 1 THEN 'Missing'
                END AS status
        FROM serviceinspections
        LEFT JOIN (
          SELECT GROUP_CONCAT('(' || ServiceQuestionCategories.title || ') ' || ServiceQuestions.question || ': ' || ServiceInspectionsAnsweredQuestions.comments, '\n') AS 'comments'
		
        FROM ServiceInspectionsAnsweredQuestions
		    INNER JOIN ServiceQuestions ON ServiceQuestions.id = ServiceInspectionsAnsweredQuestions.questionId
        INNER JOIN ServiceQuestionCategories ON ServiceQuestions.serviceQuestionCategoryId = ServiceQuestionCategories.id
        WHERE ServiceInspectionsAnsweredQuestions.serviceInspectionId IN (Select id From ServiceInspections WHERE equipmentId = ?
        ORDER BY date(serviceinspections.inspectiondate) DESC LIMIT 1)
        )all_comments

        WHERE serviceinspections.equipmentid = ?
        ORDER BY date(serviceinspections.inspectiondate) DESC
        LIMIT 1
        `;

        tx.executeSql(sql, [id, id], (_, { rows: { _array } }) => {
          if (_array.length > 0) {
            data =  _array[0];
          }
          
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }
}