import { DataTable } from 'react-native-paper';
import { useState } from 'react';
import { StyleSheet } from 'react-native';

type DataTableHeaderProps = {
  headers: any[];

  sortedColumn: string;
  sortedColumnAscending: boolean;

  onSorted: CallableFunction;
}

export const DataTableHeader = (props: DataTableHeaderProps) => {
  const [sortedColumn, setSortedColumn] = useState(props.sortedColumn);
  const [sortedColumnAscending, setSortedColumnAscending] = useState(props.sortedColumnAscending);

  const sortHeader = (header) => {
    const column = header.sortedName;
    if (column == null) return;
    let ascending = sortedColumnAscending;

    if (sortedColumn.toLowerCase() == column.toLowerCase()) {
      ascending = !sortedColumnAscending;
      setSortedColumnAscending(ascending);
    } else {
      ascending = true;
      setSortedColumnAscending(true);
    }

    setSortedColumn(column);
    props.onSorted(column, ascending);
  }

  const sortHeaderOrder = () => sortedColumnAscending ? 'ascending' : 'descending';

  return (
    <DataTable.Header>
      { props.headers.filter(h => h.visible).map((header) => {
        return (
          <DataTable.Title  
            onPress={() => sortHeader(header)} 
            sortDirection={sortedColumn?.toLowerCase() != header?.sortedName?.toLowerCase() ? null : sortHeaderOrder()}
            style={{ flex: header.flex != null ? header.flex : 1 }}>

            {header.text}

          </DataTable.Title>
        )
      })}
    </DataTable.Header>
  )
}

