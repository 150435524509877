import { baseApi, PagedTableDto } from './base-api'

export const inspectionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getInspections: build.query<any, PagedTableDto>({
      query: (dto) => ({
        //url: `inspection/getinspections?pageNumber=${dto.page + 1}&filters=${dto.getFilters()}`,
        url: `inspection/getinspections?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getInspectionsDue: build.query<any, PagedTableDto>({
      query: (dto) => ({
        //url: `inspection/getinspections?pageNumber=${dto.page + 1}&filters=${dto.getFilters()}`,
        url: `inspection/getinspectionsdue?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    sendInspection: build.mutation<any, any>({
      query: (body) => ({
        url: 'inspection/addinspection',
        method: 'post',
        body: body
      }),
    }),
  }),
  overrideExisting: true,
})

export const { 
  useSendInspectionMutation,
  useGetInspectionsQuery,
  useGetInspectionsDueQuery
} = inspectionApi