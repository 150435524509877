import * as React from 'react';
import { useState, useEffect } from 'react';
import { Picker } from '@react-native-picker/picker';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import GlobalStyle from '../../../../../constants/GlobalStyle';
import { useGetAllCategoriesQuery } from '@store/services/api/category-api';
import { Foundation, SimpleLineIcons } from '@expo/vector-icons';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { PagedTableDto } from '@store/services/api/base-api';
import { DataTable } from 'react-native-paper';
import { equipmentApi } from '@store/services/api/equipment-api';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { ReportableService } from '@store/services/reportable/reportable.service';
import { ReportKey } from '@web/components/ReportKey';
import { DataTableHeader } from '@web/table/DataTableHeader';

export default function AssetRegisterScreen({navigation}: {navigation: any}) {
  const user = useSelector((state: RootState) => state.user.user);

  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: categories } = useGetAllCategoriesQuery(null);

  const [page, setPage] = React.useState<number>(0);
  const [trigger, inspectionResult] = equipmentApi.useLazyGetAssetRegisterQuery();

  const [companyId, setCompanyId] = useState(user.companyId);
  const [locationId, setLocationId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [statusId, setStatusId] = useState("-1");
  const [sortedColumn, setSortedColumn] = React.useState<string>("serialNumber");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

  const resetFilters = () => {
    setCompanyId(user.companyId);
    setLocationId("");
    setCategoryId("");
    setStatusId("-1");
  }

  const loadTable = () => {
    
    var dto = new PagedTableDto(page, { 
      companyId: companyId,
      locationId: locationId,
      categoryId: categoryId,
      statusId: statusId,
    }, sortedColumn, sortedColumnAscending);
    trigger(dto);
  }

  // useEffect(() => {
  //   // setSortBy('SerialNumber');
  //   loadTable();
  // }, [page])
  useEffect(() => {
    loadTable();
  }, [page, sortedColumn, sortedColumnAscending])

  useEffect(() => {
    console.log(inspectionResult.data);
  }, [inspectionResult.data]);
  
  const downloadCert = async (equipment) => {
    //inspection.isLoading = true;
    var reportableService = new ReportableService();
    var data = await reportableService.downloadRote(equipment.inspectionId);
    //inspection.isLoading = false;
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data, 'Report.pdf');
    }
  }

  const downloadRAMS = async (equipment) => {
    var reportableService = new ReportableService();
    var data = await reportableService.downloadRAMS(equipment.ramsId);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data, 'Report.pdf');
    }
  }

  const downloadDoC = async (equipment) => {
    var reportableService = new ReportableService();
    var data = await reportableService.downloadDoC(equipment.id);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data, 'Report.pdf');
    }
  }

  const downloadAssetRegister = async () => {
    var reportableService = new ReportableService();
    var data = await reportableService.downloadAssetRegister(locationId);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data, 'AssetRegister.pdf');
    }
  }

  if (inspectionResult.isLoading) {
    return <LoadingIndicator/>
  }


  return (
    <View style={styles.container}>
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
         
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={companyId}
              enabled={user.role != 'User'}
              onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); setLocationId(""); }}>

              <Picker.Item label="Please Select" value="" />

              { companies != null && companies.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>
          

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={locationId}
              onValueChange={(itemValue, itemIndex) => setLocationId(itemValue)}>

              <Picker.Item label="Please Select" value="" />

              { locations != null && locations.filter(l => companyId == "" || l.companyId == companyId).map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Categories</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={categoryId}
              onValueChange={(itemValue, itemIndex) => setCategoryId(itemValue)}>

              <Picker.Item label="Please Select" value="" />

              { categories != null && categories.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Status</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={statusId}
              onValueChange={(itemValue, itemIndex) => setStatusId(itemValue)}>

              <Picker.Item label="All" value="-1" />
              <Picker.Item label="Failed" value="0" />
              <Picker.Item label="Safe For Use" value="1" />
              <Picker.Item label="Scrapped" value="2" />
            </Picker>
          </View>
    

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      { locationId != null && locationId != "" && 
        <View style={{ flexDirection: 'row'}}>
          <TouchableOpacity style={{ marginRight: 20 }} onPress={() => downloadAssetRegister()}>
            <Text style={GlobalStyle.add__new__link}>Download Asset Register</Text>
          </TouchableOpacity>
        </View> 
      }

      <ReportKey showRote={true} showDoC={true}/>

      <DataTable style={GlobalStyle.table}>
      <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Report', visible: true },
            { text: 'Description', sortedName: "description", visible: true },
            { text: 'Inspection Date', sortedName: "inspection.InspectionDate", visible: true },
            { text: 'Next Inspection Date', sortedName: "inspection.NextInspectionDate", visible: true },
            { text: 'Serial Number', sortedName: "serialNumber", visible: true },
            { text: 'Created', sortedName: "inspection.CreatedOn", visible: true },
            { text: 'Category', sortedName: "categoryName", visible: true },
            { text: 'Frequency', sortedName: "frequency", visible: true },
            { text: 'Safe For Use', sortedName: "inspection.SafeForUse", visible: true },
          ]}
        />
        { inspectionResult.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>
                <View style={{ flexDirection: 'row'}}>
                  { element.inspectionId != null && <TouchableOpacity onPress={() => downloadCert(element)}><Foundation name="page-pdf" size={24} color="black" /></TouchableOpacity> }
                  {/* { element.ramsId != null && <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => downloadRAMS(element)}><Foundation name="page-pdf" size={24} color="blue" /></TouchableOpacity> } */}
                  { element.doc == true && <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => downloadDoC(element)}><Foundation name="page-pdf" size={24} color="green" /></TouchableOpacity> }
                </View>
              </DataTable.Cell>
              <DataTable.Cell>{element.description}</DataTable.Cell>
              <DataTable.Cell>{element.lastInspectionDate}</DataTable.Cell>
              <DataTable.Cell>{element.nextInspectionDate}</DataTable.Cell>
              <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
              <DataTable.Cell>{element.created}</DataTable.Cell>
              <DataTable.Cell>{element.categoryName}</DataTable.Cell>
              <DataTable.Cell>{element.frequency}</DataTable.Cell>
              <DataTable.Cell>{element.status}</DataTable.Cell>
            </DataTable.Row>
          )
        })}


        {/* <DataTable.Header>
          <DataTable.Title>Report</DataTable.Title>
          <DataTable.Title>Description</DataTable.Title>
          <DataTable.Title>Inspection Date</DataTable.Title>
          <DataTable.Title>Next Inspection Date</DataTable.Title>
          <DataTable.Title>Serial Number</DataTable.Title>
          <DataTable.Title>Created</DataTable.Title>
          <DataTable.Title>Category</DataTable.Title>
          <DataTable.Title>Frequency</DataTable.Title>
          <DataTable.Title>Safe For Use</DataTable.Title>
        </DataTable.Header>

        { inspectionResult.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
               <DataTable.Cell>
                <View style={{ flexDirection: 'row'}}>
                  { element.inspectionId != null && <TouchableOpacity onPress={() => downloadCert(element)}><Foundation name="page-pdf" size={24} color="black" /></TouchableOpacity> }
                  { element.ramsId != null && <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => downloadRAMS(element)}><Foundation name="page-pdf" size={24} color="blue" /></TouchableOpacity> }
                  { element.doc == true && <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => downloadDoC(element)}><Foundation name="page-pdf" size={24} color="green" /></TouchableOpacity> }
                </View>
              </DataTable.Cell>
              <DataTable.Cell>{element.description}</DataTable.Cell>
              <DataTable.Cell>{element.lastInspectionDate}</DataTable.Cell>
              <DataTable.Cell>{element.nextInspectionDate}</DataTable.Cell>
              <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
              <DataTable.Cell>{element.created}</DataTable.Cell>
              <DataTable.Cell>{element.categoryName}</DataTable.Cell>
              <DataTable.Cell>{element.frequency}</DataTable.Cell>
              <DataTable.Cell>{element.status}</DataTable.Cell>
            </DataTable.Row>
          )
        })} */}
      </DataTable>


      <DataTable.Pagination
        // page={page}
        // numberOfPages={inspectionResult.data?.pagination.totalPages}
        // onPageChange={(page) => setPage(page)} 
        // label={`${inspectionResult?.data?.pagination.currentStartIndex}-${inspectionResult?.data?.pagination.currentEndIndex} of ${inspectionResult?.data?.pagination.totalCount}`}
        // showFastPaginationControls
        // numberOfItemsPerPage={inspectionResult.data?.pagination.pageSize}
        // optionsLabel={'Rows per page'}
        page={page}
        numberOfPages={inspectionResult.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)} 
        //@ts-ignore
        itemsPerPage={inspectionResult.data?.pagination.pageSize}
        showFastPagination
        optionsLabel={'Rows per page'}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

});
