import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Foundation } from '@expo/vector-icons';

export const ReportKey = ({ showRote = false, showPowra = false, showDoC = false, roteLabel = "ROTE" }) => {
  return (
    <View style={styles.report__key__container}>
      { showRote && <View style={styles.key}>
        <Foundation name="page-pdf" size={24} color="black" />
        <Text style={{ fontWeight: 'bold', marginLeft: 10 }}>{roteLabel}</Text>
      </View>
      }

      { showPowra && <View style={styles.key}>
        <Foundation name="page-pdf" size={24} color="blue" />
        <Text style={{ fontWeight: 'bold', marginLeft: 10 }}>PoWRA</Text>
      </View> }

      { showDoC && <View style={styles.key}>
        <Foundation name="page-pdf" size={24} color="green" />
        <Text style={{ fontWeight: 'bold', marginLeft: 10 }}>DoC</Text>
      </View> }
    </View>
  )
}

const styles = StyleSheet.create({
  report__key__container: {
    marginBottom: 10,
    alignItems: 'flex-start',

    flexDirection: 'row'
  },

  key: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: '#ebe8e8',
    borderRadius: 5,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,

    marginRight: 10
  }
});