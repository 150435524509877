import { baseApi, PagedTableDto } from './base-api'

export const proofLoadApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getProofLoadInspections: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `proofload/GetProofLoadInspections?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    sendProofLoads: build.mutation<any, any>({
      query: (body) => ({
        url: 'proofload/addproofload',
        method: 'post',
        body: body
      }),
    }),
  }),
  overrideExisting: true,
})

export const { useSendProofLoadsMutation, useGetProofLoadInspectionsQuery } = proofLoadApi