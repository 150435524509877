import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';

import { useIsFocused } from "@react-navigation/native";
import { Picker } from '@react-native-picker/picker';
import { manufacturerApi, useCreateManufacturerMutation, useUpdateManufacturerMutation } from '../../../../../../store/services/api/manufacturer-api';


export default function ManufacturerManageScreen({navigation, route}: {navigation: any, route: any}) {
  const [createManufacturer, createManufacturerResult] = useCreateManufacturerMutation();
  const [updateManufacturer, updateManufacturerResult] = useUpdateManufacturerMutation();

  const [trigger, manufacturerResult] = manufacturerApi.useLazyGetManufacturerQuery();

  const { control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      name: "",
    }
  });

  React.useEffect(() => {
    if (route.params?.manufacturerId != null) {
      trigger(route.params?.manufacturerId);
    }
  }, [])

  React.useEffect(() => {
    if (manufacturerResult.isSuccess) {
      var data = manufacturerResult.data;
      setValue("id", data.id);
      setValue("name", data.name);
    }
  }, [manufacturerResult]);

  const onSubmit = async (data) => {
    var result = null;

    if (data.id != null) {
      result = await updateManufacturer(data) as any;
    } else {
      result = await createManufacturer(data) as any;
    }

    if (result.error) {
      return;
    }

    navigation.goBack();
  };

  if (createManufacturerResult.isLoading || updateManufacturerResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon}  onPress={() => navigation.goBack() }>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{getValues("name") == null ? "CREATE MANUFACTURER" : "UPDATE MANUFACTURER"}</Text>
      </View>
      
      <View style={GlobalStyle.form}>
        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Manufacturer Name (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.name && <Text style={GlobalStyle.form__input__validation}>Name is required.</Text>}
          </View>
        </View>

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(onSubmit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
