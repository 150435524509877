import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert } from 'react-native';
import { EvilIcons, Ionicons, FontAwesome } from '@expo/vector-icons';
import * as Progress from 'expo-progress';

export function JobGridStats({ jobs }) {

  return (
    <View style={styles.job__grid}>
      <View style={styles.job__block}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={styles.job__icon}>
            <Ionicons name="checkmark-circle-outline" size={32} color="#2B93F1" />
          </View>
          <Text style={styles.job__text}>ROTE</Text>
        </View>
        
        <View style={styles.job__info}>
          <Text style={{ fontFamily: 'OpenSans-Light', fontSize: 22 }}># Jobs: <Text style={{ fontFamily: 'OpenSans-Bold' }}>{ jobs.filter(j => j.jobType == 0).length }</Text></Text>
        </View>
      </View>
      <View style={styles.job__block}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={[styles.job__icon, styles.servicing__icon]}>
            <Ionicons name="cog-outline" size={32} color="#F7A931" />
          </View>
          <Text style={styles.job__text}>SERVICING</Text>
        </View>
        
        <View style={styles.job__info}>
          <Text style={{ fontFamily: 'OpenSans-Light', fontSize: 22 }}># Jobs: <Text style={{ fontFamily: 'OpenSans-Bold' }}>{ jobs.filter(j => j.jobType == 1).length }</Text></Text>
        </View>
      </View>
      <View style={styles.job__block}>
        <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
          <View style={[styles.job__icon, styles.breakdown__icon]}>
            <Ionicons name="warning-outline" size={32} color="#FF4C4F" />
          </View>
          <Text style={styles.job__text}>REPAIR/BREAKDOWN</Text>
        </View>
        
        <View style={styles.job__info}>
          <Text style={{ fontFamily: 'OpenSans-Light', fontSize: 22 }}># Jobs: <Text style={{ fontFamily: 'OpenSans-Bold' }}>{ jobs.filter(j => j.jobType == 2).length }</Text></Text>
        </View>
      </View>
      <View style={styles.job__block}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={[styles.job__icon, styles.proofload__icon]}>
            <Ionicons name="ios-reload-circle-outline" size={32} color="#0D4487" />
            
          </View>
          <Text style={styles.job__text}>PROOF LOAD</Text>
        </View>
        
        <View style={styles.job__info}>
          <Text style={{ fontFamily: 'OpenSans-Light', fontSize: 22 }}># Jobs: <Text style={{ fontFamily: 'OpenSans-Bold' }}>{ jobs.filter(j => j.jobType == 3).length }</Text></Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  spacer: {
    flex: 1,
  },
  job__grid: {
    flexDirection: 'row',
  },

  job__block: {
    margin: 10,
    flex: 1,
    backgroundColor: '#FFF',
    borderRadius: 10,
    minHeight: 50,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center'
  },

    job__icon: {
      padding: 10,
      backgroundColor: '#E9F5FE',
      borderRadius: 10,
      
      alignSelf: 'flex-start'
    },

    job__text: {
      fontFamily: 'OpenSans-Bold',
      fontSize: 26,
      textAlign: 'center',
      flex: 1,
      alignSelf: 'center',
    },

    servicing__icon: {
      backgroundColor: '#FFF6E8'
    },

    breakdown__icon: {
      backgroundColor: '#FFE0E0',
    },

    proofload__icon: {
      backgroundColor: '#E7ECF3' 
    },

  job__info: {
    marginTop: 20, 
  }
});
