import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { JobDatabase } from '@store/services/database/job-database';
import { FlashList } from "@shopify/flash-list";
import { AntDesign } from '@expo/vector-icons';
import { TitleBar } from '../../../components/TitleBar';
import { Controller, useForm } from 'react-hook-form';
import { setInspectionData } from '@store/slices/rote-inspection-slice';
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import { colours } from '../../../../../config';

export default function RoteStep1({navigation, route}: {navigation: any, route: any}) {
  const dispatch = useDispatch();

  // const { assetId } = route.params;
  // console.log(assetId);

  const inspection = useSelector((state: RootState) => state.roteInspection);

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      firstExamination: inspection.firstExamination,
      installedCorrectly: inspection.installedCorrectly
    }
  });

  const firstExamination = watch("firstExamination");

  const onSubmit = data => {
    dispatch(setInspectionData({
      firstExamination: data.firstExamination, 
      installedCorrectly: data.installedCorrectly, 
    }));

    navigation.navigate("Step2");
  };

  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} title="Step 1 of 3" showSearchButton={false} showBackButton={true} onSynced={null}/>

      <View style={styles.form}>
        <Controller
          control={control}
          rules={{ required: true }}
          name="firstExamination"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Is this the first examination after installation or assembly at a new site or location?</Text>

              <RadioButtonGroup 
                containerStyle={{ marginTop: 10, flexDirection: 'row' }}
                radioBackground={colours.primary}
                selected={value}
                onSelected={onChange}>

                <RadioButtonItem value={0} label="No" />
                <RadioButtonItem value={1} style={{ marginLeft: 20 }} label="Yes" />
              </RadioButtonGroup>

              {errors.firstExamination && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
            </View>
          )}
        />

        { firstExamination == true && 
          <Controller
            control={control}
            rules={{ required: true }}
            name="installedCorrectly"
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={styles.step__question}>
                <Text style={styles.step__question__label}>Has the equipment been installed correctly?</Text>

                <RadioButtonGroup 
                  containerStyle={{ marginTop: 10, flexDirection: 'row' }}
                  radioBackground={colours.primary}
                  selected={value}
                  onSelected={onChange}>

                  <RadioButtonItem value={0} label="No" />
                  <RadioButtonItem value={1} style={{ marginLeft: 20 }} label="Yes" />
                </RadioButtonGroup>

                {errors.installedCorrectly && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
              </View>
            )}
          />
        }
      </View>

      <TouchableOpacity style={styles.proceed__button} onPress={handleSubmit(onSubmit)}>
        <Text style={styles.proceed__button__text}>NEXT</Text>
      </TouchableOpacity>

    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 40,
  },

  form: {
    flex: 1,
    marginTop: 10,
  },

    step__question: {
      padding: 20,
      backgroundColor: '#FFF',
      marginBottom: 20,
    },

    step__question__label: {
      fontFamily: 'OpenSans-Light',
      fontSize: 18,
      marginBottom: 5,
    },

    input__validation: {
      color: 'red',
      marginTop: 10
    },

    proceed__button: {
      padding: 10,
      backgroundColor: colours.primary,
      marginTop: 20,
      borderRadius: 7,
      height: 45,
  
  
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  
      proceed__button__text: {
        fontFamily: 'OpenSans-Bold',
        color: '#FFF',
        textAlign: 'center',
        fontSize: 18
      },
});
