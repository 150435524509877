import * as React from 'react';
import { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, Platform } from 'react-native';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';

import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import { Entypo, Ionicons, SimpleLineIcons  } from '@expo/vector-icons';
import { breakdownApi, useDeleteBreakdownPartMutation, useGetBreakdownPartsListQuery } from '@store/services/api/breakdown-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { DataTableHeader } from '@web/table/DataTableHeader';


export default function PartsListScreen({navigation}: {navigation: any}) {

  const [deleteBreakdownPart, deleteBreakdownPartResult] = useDeleteBreakdownPartMutation();
  const isFocused = useIsFocused()

  const optionsPerPage = [5, 10, 50];
  const [page, setPage] = React.useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);

  // Filters 
  const [partNo, setPartNo] = useState("");

  const [trigger, partsResult] = breakdownApi.useLazyGetBreakdownPartsListQuery();
  const [sortedColumn, setSortedColumn] = React.useState<string>("itemCode");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

  const loadTable = () => {
    var dto = new PagedTableDto(page, { 
      partNo: partNo,
    }, sortedColumn, sortedColumnAscending);
    trigger(dto);
  }

  const resetFilters = () => {
    setPartNo("");
  }
  
  useEffect(() => {
    loadTable();
  }, [page, sortedColumn, sortedColumnAscending])

  useEffect(() => {
    console.log(partsResult.data);
  }, [partsResult.data]);

  const remove = async (entityId) => {
    if (Platform.OS == 'web') {
      if (confirm("Are you sure you want to delete this part?")) {
        var result = await deleteBreakdownPart(entityId);
        if (result.error) {
          Alert.alert("Error", "Something went wrong. Please try again");
          return;
        }

        refetch();
      }
    }
  }

  if (partsResult.isLoading || deleteBreakdownPartResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Part No</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Part No"
              onChangeText={setPartNo}
              value={partNo}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <TouchableOpacity onPress={() => navigation.navigate("manage")}>
        <Text style={GlobalStyle.add__new__link}>Add New Part</Text>
      </TouchableOpacity>

      <DataTable style={GlobalStyle.table}>
        {/* <DataTable.Header>
          <DataTable.Title>Item Code</DataTable.Title>
          <DataTable.Title>Description</DataTable.Title>
          <DataTable.Title>Unit Cost</DataTable.Title>
          <DataTable.Title>Part Number</DataTable.Title>
          <DataTable.Title>Option</DataTable.Title>
        </DataTable.Header> */}
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[

            { text: 'Item Code', sortedName: "itemCode", visible: true },
            { text: 'Description', sortedName: "description", visible: true },
            { text: 'Unit Cost', sortedName: "unitCost", visible: true },
            { text: 'Part Number', sortedName: "partNo", visible: true },
            { text: 'Option', visible: true },
            
          ]}
        />

        { partsResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.itemCode}</DataTable.Cell>
              <DataTable.Cell>{element.description}</DataTable.Cell>
              <DataTable.Cell>{element.unitCost}</DataTable.Cell>
              <DataTable.Cell>{element.partNo}</DataTable.Cell>
              <DataTable.Cell>
                <View style={{ flexDirection: 'row'}}>
                  <TouchableOpacity onPress={() => navigation.navigate("manage", { breakdownPartId: element.id })}>
                    <Entypo name="pencil" size={24} color="black" />
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => remove(element.id) } style={{ marginLeft: 20 }}>
                    <Ionicons name="ios-remove-circle" size={24} color="red" />
                  </TouchableOpacity>
                </View>
                
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <DataTable.Pagination
        page={page}
        numberOfPages={partsResult.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)} 
        //@ts-ignore
        itemsPerPage={partsResult.data?.pagination.pageSize}
        showFastPagination
        optionsLabel={'Rows per page'}
      />

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
