import { createNativeStackNavigator } from '@react-navigation/native-stack';
import RoteStep1 from './Step1';
import RoteStep2 from './Step2';
import RoteStep3 from './Step3';
const Stack = createNativeStackNavigator();

export default function RoteInspect({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Step1" component={RoteStep1} options={{ headerShown: false, title: 'Step1'  }} />
      <Stack.Screen name="Step2" component={RoteStep2} options={{ headerShown: false, title: 'Step2'  }} />
      <Stack.Screen name="Step3" component={RoteStep3} options={{ headerShown: false, title: 'Step3'  }} />
    </Stack.Navigator>
  )
};