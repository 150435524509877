import { baseApi, PagedTableDto } from './base-api'

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getEngineers: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `users/getUsers?pageNumber=${dto.page + 1}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));
        console.log(pagination);
        console.log(response);
        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getAllEngineers: build.query<any, any>({
      query: (companyId) => ({
        url: `users/getAllEngineers`,
      }),
    }),
    getUsers: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `users/getUsers?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));
        console.log(pagination);
        console.log(response);
        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getUser: build.query<any, any>({
      query: (userId) => ({
        url: `users/getuser/${userId}`,
      }),
    }),
    createUser: build.mutation<any, any>({
      query: (body) => ({
        url: 'users/adduser',
        method: 'post',
        body: body
      }),
    }),
    updateUser: build.mutation<any, any>({
      query: (body) => ({
        url: `users/updateuser/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    deleteUser: build.mutation<any, any>({
      query: (partId) => ({
        url: `users/deleteuser/${partId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: true,
})

export const { 
  useGetEngineersQuery, 
  useGetAllEngineersQuery, 

  useGetUsersQuery,
  useGetUserQuery, 
  useCreateUserMutation, 
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApi