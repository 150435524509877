import { store } from "..";
import { companyApi } from "./api/company-api";
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from "moment";
import { CompanyDatabase } from "./database/company-database";
import { LocationDatabase } from "./database/location-database";
import { locationApi } from "./api/location-api";
import { categoryApi } from "./api/category-api";
import { CategoryDatabase } from "./database/category-database";
import { ManufacturerDatabase } from "./database/manufacturer-database";
import { manufacturerApi } from "./api/manufacturer-api";
import { EquipmentDatabase } from "./database/equipment-database";
import { equipmentApi } from "./api/equipment-api";
import { JobDatabase } from "./database/job-database";
import { ColourDatabase } from "./database/colour-database";
import { PurposeDatabase } from "./database/purpose-database";
import { jobApi } from "./api/job-api";
import { purposeApi } from "./api/purpose-api";
import { colourApi } from "./api/colour-api";
import { RAMSDatabase } from "./database/rams-database";
import { ramsApi } from "./api/rams-api";
import { serviceApi } from "./api/service-api";
import { ServiceDatabase } from "./database/service-database";
import { BreakdownDatabase } from "./database/breakdown-database";
import { breakdownApi } from "./api/breakdown-api";
import { InspectionDatabase } from "./database/inspection-database";
import { inspectionApi } from "./api/inspection-api";
import { EngineerReportDatabase } from "./database/engineer-report-database";
import { engineerReportApi } from "./api/engineer-report";
import { ProofLoadDatabase } from "./database/proof-load-database";
import { proofLoadApi } from "./api/proof-load-api";
import { baseApi } from "./api/base-api";

export class SyncService {

  fullSync: boolean = false;

  private getUpdateTime = async (key) => {
    if (this.fullSync) {
      return "2000-01-01";
    }
    var lastUpdateTime = '2000-01-01';
    var lastUpdateTimeJson = await AsyncStorage.getItem(key);
    if (lastUpdateTimeJson != null && lastUpdateTimeJson != "") {
      lastUpdateTime = moment(lastUpdateTimeJson, "YYYY/MM/DD HH:mm:ss").add(-90, 'minutes').format("YYYY-MM-DD HH:mm:ss");
    }

    return lastUpdateTime;
  }

  private setUpdateTime = async (key, time) => {
    await AsyncStorage.setItem(key, time);
  }

  private syncCompanies = async () => {
    var companyDatabase = new CompanyDatabase();
    const key = "companies-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);


    var result = await store.dispatch(companyApi.endpoints.syncCompanies.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
     console.log(data);

    if (data.length > 0) {
     
      await companyDatabase.insertOrUpdateList(data);
    }

  }

  private syncLocations = async () => {
    var locationDatabase = new LocationDatabase();
    const key = "locations-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(locationApi.endpoints.syncLocations.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await locationDatabase.insertOrUpdateList(data);
    }

    console.log("Finished syncing locations: " + data.length);
  }

  
  private syncCategories = async () => {
    var categoryDatabase = new CategoryDatabase();
    const key = "categories-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(categoryApi.endpoints.syncCategories.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await categoryDatabase.insertOrUpdateList(data);
    }

    console.log("Finished syncing categories: " + data.length);
  }

  private syncMasterCategories = async () => {
    var categoryDatabase = new CategoryDatabase();
    const key = "master-categories-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(categoryApi.endpoints.syncMasterCategories.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await categoryDatabase.insertOrUpdateMasterCategoriesList(data);
    }

    console.log("Finished syncing master categories: " + data.length);
  }

  private syncManufacturers = async () => {
    var manufacturerDatabase = new ManufacturerDatabase();
    const key = "manufacturers-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(manufacturerApi.endpoints.syncManufacturers.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await manufacturerDatabase.insertOrUpdateList(data);
    }

    console.log("Finished syncing manufacturers: " + data.length);
  }

  private syncEquipment = async () => {
    var equipmentDatabase = new EquipmentDatabase();
    const key = "equipment-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(equipmentApi.endpoints.syncEquipment.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await equipmentDatabase.insertOrUpdateList(data, false);
    }

    console.log("Finished syncing equipment: " + data.length);
  }

  private syncJobs = async () => {
    var jobDatabase = new JobDatabase();
    const key = "jobs-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);;
    var result = await store.dispatch(jobApi.endpoints.syncJobs.initiate(lastSyncedTime)) as any;
    var data = result.data;    

    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await jobDatabase.insertOrUpdateList(data);
    }

    console.log("Finished syncing jobs: " + data.length);
  }

  private syncEngineerReports = async () => {
    var engineerDatabase = new EngineerReportDatabase();
    const key = "engineer-report-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);;
    var result = await store.dispatch(engineerReportApi.endpoints.syncEngineerReports.initiate(lastSyncedTime)) as any;
    var data = result.data;    

    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await engineerDatabase.insertOrUpdateList(data);
    }

    console.log("Finished syncing engineer reports: " + data.length);
  }

  private syncRAMS = async () => {
    var ramsDatabase = new RAMSDatabase();
    const key = "rams-report-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);;
    var result = await store.dispatch(ramsApi.endpoints.syncRamsReports.initiate(lastSyncedTime)) as any;
    var data = result.data;    

    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await ramsDatabase.insertOrUpdateList(data);
    }

    console.log("Finished syncing RAMS reports: " + data.length);
  }


  private syncPurposes = async () => {
    var database = new PurposeDatabase();
    const key = "purposes-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(purposeApi.endpoints.syncPurposes.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await database.insertOrUpdateList(data);
    }

    console.log("Finished syncing purposes: " + data.length);
  }

  private syncColours = async () => {
    var database = new ColourDatabase();
    const key = "colours-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(colourApi.endpoints.syncColours.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await database.insertOrUpdateList(data);
    }

    console.log("Finished syncing colours: " + data.length);
  }

  private syncRAMSQuestions = async () => {
    var database = new RAMSDatabase();
    const key = "rams-questions-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(ramsApi.endpoints.syncRAMSQuestions.initiate(lastSyncedTime)) as any;
    var data = result.data;

    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await database.insertOrUpdateQuestionList(data);
    }

    console.log("Finished syncing rams questions: " + data.length);
  }
  
  
  private syncServiceQuestionCategories = async () => {
    var database = new ServiceDatabase();
    const key = "service-question-categories-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(serviceApi.endpoints.syncServiceQuestionCategories.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await database.insertOrUpdateQuestionCategoryList(data);
    }

    console.log("Finished syncing service question categories: " + data.length);
  }

  private syncServiceQuestions = async () => {
    var database = new ServiceDatabase();
    const key = "service-questions-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(serviceApi.endpoints.syncServiceQuestions.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await database.insertOrUpdateQuestionList(data);
    }

    console.log("Finished syncing service questions: " + data.length);
  }

  private syncBreakdownCommonFaults = async () => {
    var database = new BreakdownDatabase();
    const key = "breakdown-common-faults-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(breakdownApi.endpoints.syncBreakdownCommonFaults.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await database.insertOrUpdateCommonFaultsList(data);
    }

    console.log("Finished syncing breakdown common faults: " + data.length);
  }

  private syncBreakdownParts = async () => {
    var database = new BreakdownDatabase();
    const key = "breakdown-parts-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(breakdownApi.endpoints.syncParts.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await database.insertOrUpdatePartsList(data);
    }

    console.log("Finished syncing breakdown parts: " + data.length);
  }

  private syncLoadTypes = async () => {
    var database = new EquipmentDatabase();
    const key = "load-types-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(equipmentApi.endpoints.syncLoadTypes.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await database.insertOrUpdateLoadTypesList(data);
    }

    console.log("Finished syncing load types: " + data.length);
  }

  private syncLoadUnits = async () => {
    var database = new EquipmentDatabase();
    const key = "load-units-update-time";

    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(equipmentApi.endpoints.syncLoadUnits.initiate(lastSyncedTime)) as any;
    var data = result.data;
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));
    
    if (data.length > 0) {
      await database.insertOrUpdateLoadUnitsList(data);
    }

    console.log("Finished syncing load units: " + data.length);
  }

  private syncServices = async () => {
    var database = new ServiceDatabase();
    const key = "service-update-time";
    // data.questions = [];
    var lastSyncedTime = await this.getUpdateTime(key);
    var result = await store.dispatch(serviceApi.endpoints.syncServices.initiate(lastSyncedTime)) as any;
    var data = result.data;
    
    
    if (data.length > 0) {
      data.forEach((inspection) => database.addInspectionHistory(inspection.id,inspection.inspectionDate, inspection.nextInspectionDate, inspection.safeForUse,
        inspection.equipmentId, inspection.engineerId, inspection.jobId, 
        inspection.purposeId, inspection.colourId, inspection.missing,0, inspection.engineerName)
      );
      
    }
    await this.syncServicesquestions(lastSyncedTime);
    database.removeOldServices();
    await this.setUpdateTime(key, moment().format("YYYY/MM/DD HH:mm:ss"));

    console.log("Finished syncing Services: " + data.length);
  }


  private syncServicesquestions = async (lastSyncedTime: string) => {
    console.log("getting services questions");
     var database = new ServiceDatabase();
    var result = await store.dispatch(serviceApi.endpoints.syncServicesQuestions.initiate(lastSyncedTime)) as any;
    var data = result.data;

    if (data.length > 0) {
      data.forEach((inspection) => database.addInspectionQuestionHistory(inspection.id,inspection.questionId, inspection.response,
         inspection.comments, inspection.serviceInspectionId)
      );
      
    }

    console.log("Finished syncing Services questions: " + data.length);
  }

  private sendEquipment = async () => {
    var database = new EquipmentDatabase();
    var equipments = await database.getForSync();
    console.log("EQUIPMENT TO SYNC: " + equipments.length);
    for (let equipment of equipments) {
      var result = await store.dispatch(equipmentApi.endpoints.updateEquipment.initiate(equipment)) as any;
      if (!result.error) {
        await database.setToBeSynced(equipment.id, false);
      }
    }
  }

  private sendInspections = async () => {
    var database = new InspectionDatabase();

    var inspections = await database.getForSync();
    for (let inspection of inspections) {
      var result = await store.dispatch(inspectionApi.endpoints.sendInspection.initiate(inspection)) as any;
      if (!result.error) {
        await database.deleteInspection(inspection.id);
      }
    }
  }

  private sendJobs = async () => {
    var database = new JobDatabase();
    var jobs = await database.getJobsForSync();
    for (let job of jobs) {
      var result = await store.dispatch(jobApi.endpoints.sendJob.initiate(job)) as any;
      // if (!result.error) {
      //   console.log("SUCCESS");
      //   await database.updateJobToBeSynced(job.id, false);
      // }
    }
  }

  private sendJobAssets = async () => {
    var database = new JobDatabase();

    var jobAssets = await database.getJobAssetsForSync();
    for (let jobAsset of jobAssets) {
      var result = await store.dispatch(jobApi.endpoints.sendJobAssets.initiate(jobAsset)) as any;
      
      if (!result.error) {
        console.log("SUCCESS");
        await database.updateJobAssetToBeSynced(jobAsset.id, false);
      }
    }
  }

  private sendRAMS = async () => {
    var database = new RAMSDatabase();

    var ramsList = await database.getForSync();
    
    for (let rams of ramsList) {
      var result = await store.dispatch(ramsApi.endpoints.sendRAMS.initiate(rams)) as any;
      if (!result.error) {
        console.log("SUCCESS");
        await database.updateRAMSToBeSynced(rams.id, false);
      }
    }
  }

  private sendEngineerReports = async () => {
    var database = new EngineerReportDatabase();

    var engineerReports = await database.getForSync();
    for (let engineerReport of engineerReports) {
      var result = await store.dispatch(engineerReportApi.endpoints.sendEngineerReport.initiate(engineerReport)) as any;
      if (!result.error) {
        console.log("SUCCESS");
        await database.updateEngineerReportToBeSynced(engineerReport.id, false);
      }
    }
  }

  private sendBreakdowns = async () => {
    var database = new BreakdownDatabase();

    var inspections = await database.getForSync();
    //console.log(inspections);
    for (let inspection of inspections) {
      var result = await store.dispatch(breakdownApi.endpoints.sendBreakdownInspection.initiate(inspection)) as any;

      if (!result.error) {
        console.log("SUCCESS");
        await database.deleteInspection(inspection.id);
      }
    }
  }

  private sendServices = async () => {
    var database = new ServiceDatabase();

    var inspections = await database.getForSync();

    console.log(" services got from get for sync");
    console.log(inspections);
    console.log(" services got from get for sync questions");
    console.log(inspections.questions);
    for (let inspection of inspections) {
      //console.log(inspection);
      var result = await store.dispatch(serviceApi.endpoints.sendServiceInspection.initiate(inspection)) as any;
      if (!result.error) {
        console.log("SUCCESS");
        await database.removeSyncInspection(inspection.id);
      }
    }
  }

  private sendProofLoads = async () => {
    var database = new ProofLoadDatabase();

    var inspections = await database.getForSync();
    console.log("PROOFLOAD TO SYNC: " + inspections.length);
    for (let inspection of inspections) {
      var result = await store.dispatch(proofLoadApi.endpoints.sendProofLoads.initiate(inspection)) as any;
      if (!result.error) {
        console.log("SUCCESS");
        await database.deleteInspection(inspection.id);
      }
    }
  }


  public syncAll = async () => {
    await this.sendAll();

    await this.getS();
    await this.getAll();
  }

  public getS = () : Promise<void> => {

    console.log("10")
    return new Promise(async(resolve) => {
      await this.syncServices();
      resolve();
    });

  }

  public sendAll = () : Promise<void> => {
    return new Promise(async (resolve) => {
      console.log(0);
      await this.sendEquipment();
      
      console.log(1);
      await this.sendJobs();

      console.log(2);
      await this.sendRAMS();

      console.log(3);
      await this.sendEngineerReports();
      
      console.log(4);
      await this.sendJobAssets();
      
      console.log(5);
      await this.sendInspections();
      
      console.log(6);
      await this.sendBreakdowns();
      
      console.log(7);
      await this.sendServices();
      
      console.log(8);
      await this.sendProofLoads();
      console.log(9);

      resolve();
    });
  }

  public getAll = () : Promise<void> => {
    

    return new Promise((resolve) => {
      var promises = [];
      console.log("getting all");
      promises.push(this.syncCompanies());
      promises.push(this.syncLocations());
      promises.push(this.syncMasterCategories());
      promises.push(this.syncCategories());
      promises.push(this.syncManufacturers());
      promises.push(this.syncEquipment());
      promises.push(this.syncJobs());
      promises.push(this.syncEngineerReports());
      promises.push(this.syncRAMS());
      
      promises.push(this.syncPurposes());
      promises.push(this.syncColours());
      promises.push(this.syncRAMSQuestions());
      promises.push(this.syncServiceQuestionCategories());
      promises.push(this.syncServiceQuestions());

      promises.push(this.syncBreakdownCommonFaults());
      promises.push(this.syncBreakdownParts());

      promises.push(this.syncLoadTypes());
      promises.push(this.syncLoadUnits());

            // promises.push(this.syncServices());

      Promise.all(promises).then(() => {
        resolve();
      })
    });
  }
}