import * as SQLite from 'expo-sqlite';
import { Database } from './database';

export class CategoryDatabase extends Database {
  TABLE_NAME = "Categories";
  TABLE_NAME_MASTER_CATEGORIES = "MasterCategories";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Categories ( 
        id TEXT PRIMARY KEY,
        name TEXT,
        inspectionInterval INT,
        masterCategoryId TEXT
      )`;

      var sqlMasterCategories = `CREATE TABLE IF NOT EXISTS MasterCategories ( 
        id TEXT PRIMARY KEY,
        name TEXT
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlMasterCategories);
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  insertOrUpdateList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (id, name, inspectionInterval, masterCategoryId)
            VALUES (?, ?, ?, ?)
          `

          tx.executeSql(sql, [entity.id, entity.name, entity.inspectionInterval, entity.masterCategoryId]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  insertOrUpdateMasterCategoriesList(entities: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        for (let entity of entities) {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME_MASTER_CATEGORIES} (id, name)
            VALUES (?, ?)
          `

          tx.executeSql(sql, [entity.id, entity.name]);
        }
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  getById(categoryId) : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = null;
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Categories WHERE id = ?
        `;

        tx.executeSql(sql, [categoryId], (_, { rows: { _array } }) => {
          if (_array.length > 0)
            data = _array[0];
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }

  getAllCategories() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME}
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          //console.log(_array)
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }

  getAllMasterCategories() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME_MASTER_CATEGORIES}
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          //console.log(_array)
          data = _array;
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    })
  }
}