import { baseApi, PagedTableDto } from './base-api'

export const ramsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRAMSReports: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `rams/getrams?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    syncRAMSQuestions: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `rams/getramsquestionsforsync?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    sendRAMS: build.mutation<any, any>({
      query: (body) => ({
        url: `rams/addrams`,
        method: 'post',
        body: body
      }),
    }),
    syncRamsReports: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `rams/GetRamsReportsForSync?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
  }),
  overrideExisting: true,
})

export const { useSyncRAMSQuestionsMutation, } = ramsApi