import * as React from 'react';
import { useState, useEffect } from 'react';
import { Picker } from '@react-native-picker/picker';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { useDebounce } from '../../../../../components/helpers/UseDebounce';
import GlobalStyle from '../../../../../constants/GlobalStyle';
import { useGetAllEngineersQuery } from '@store/services/api/user-api';
import moment from 'moment';
import DateTimePicker from '@web/components/navigation/controls/DateTimePicker';
import { useGetAllCategoriesQuery } from '@store/services/api/category-api';
import { Foundation, SimpleLineIcons } from '@expo/vector-icons';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { PagedTableDto } from '@store/services/api/base-api';
import { DataTable } from 'react-native-paper';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { ReportableService } from '@store/services/reportable/reportable.service';
import { Basket, BasketIcon } from '@web/components/Basket';
import { proofLoadApi } from '@store/services/api/proof-load-api';
import { ImageViewer } from '@web/components/ImageViewer';
import { ReportKey } from '@web/components/ReportKey';
import { DataTableHeader } from '@web/table/DataTableHeader';

export default function ProofLoadsScreen({navigation}: {navigation: any}) {
  const user = useSelector((state: RootState) => state.user.user);

  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: engineers } = useGetAllEngineersQuery(null);

  const [page, setPage] = React.useState<number>(0);
  const [trigger, inspectionResult] = proofLoadApi.useLazyGetProofLoadInspectionsQuery();

  const [companyId, setCompanyId] = useState(user.companyId);
  const [locationId, setLocationId] = useState("");
  const [engineerId, setEngineerId] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [startDate, setStartDate] = useState(moment().add(-6, 'months').toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [serialNumber, setSerialNumber] = useState("");
  const [drawingRef, setDrawingRef] = useState("");

  const [imagesToView, setImagesToView] = useState([]);

  const [sortedColumn, setSortedColumn] = React.useState<string>("InspectionDate");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

  const updateDateRange = (val) => {
    setDateRange(val);

    let startDate = moment();
    const endDate = moment();
    if (val == "-1") {
      startDate = moment().add(-1, 'week');
    } else {
      startDate = moment().add(-Number.parseInt(val), 'M');
    }

    setStartDate(startDate.toDate());
    setEndDate(endDate.toDate());
  }

  const resetFilters = () => {
    setCompanyId(user.companyId);
    setLocationId("");
    setEngineerId("");
    setDateRange("");
    setStartDate(moment().add(-6, 'months').toDate());
    setEndDate(moment().toDate());
    setSerialNumber("");
    setDrawingRef("");
  }

  const loadTable = () => {
    var dto = new PagedTableDto(page, { 
      companyId: companyId,
      locationId: locationId,
      engineerId: engineerId,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      serialNumber: serialNumber,
      drawingRef: drawingRef,
    }, sortedColumn, sortedColumnAscending);
    console.log(dto);
    trigger(dto);
  }

  useEffect(() => {
    loadTable();
  }, [page, sortedColumn, sortedColumnAscending])

  useEffect(() => {
    console.log(inspectionResult.data);
  }, [inspectionResult.data]);

  const downloadCert = async (inspection) => {
    //inspection.isLoading = true;
    var reportableService = new ReportableService();
    console.log(inspection.id);
    var data = await reportableService.downloadProofLoad(inspection.id);
    //inspection.isLoading = false;
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data, 'Report.pdf');
    }
  }

  const downloadRAMS = async (inspection) => {
    var reportableService = new ReportableService();
    var data = await reportableService.downloadRAMS(inspection.ramsId);
    if (data != null) {
      const fileSaver = require('file-saver');
      fileSaver(data, 'Report.pdf');
    }
  }

  if (inspectionResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
      { imagesToView.length > 0 && <ImageViewer images={imagesToView} onClosed={() => setImagesToView([])} />}

      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={companyId}
              enabled={user.role != 'User'}
              onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); setLocationId(""); }}>

              <Picker.Item label="Please Select" value="" />

              { companies != null && companies.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={locationId}
              onValueChange={(itemValue, itemIndex) => setLocationId(itemValue)}>

              <Picker.Item label="Please Select" value="" />

              { locations != null && locations.filter(l => companyId == "" || l.companyId == companyId).map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Engineers</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={engineerId}
              onValueChange={(itemValue, itemIndex) => setEngineerId(itemValue)}>

              <Picker.Item label="Please Select" value="" />

              { engineers != null && engineers.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Date Range</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={dateRange}
              onValueChange={(itemValue, itemIndex) => updateDateRange(itemValue)}>

              <Picker.Item label="Predefined Date Range" value="" />
              <Picker.Item label="1 Week" value="-1" />
              <Picker.Item label="1 Month" value="1" />
              <Picker.Item label="3 Months" value="3" />
              <Picker.Item label="6 Months" value="6" />
              <Picker.Item label="12 Months" value="12" />
              
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Start Date</Text>
            <DateTimePicker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              onChange={setStartDate}
              value={startDate}>
            </DateTimePicker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>End Date</Text>
            <DateTimePicker
              style={[GlobalStyle.column, GlobalStyle.column__input,]} 
              onChange={setEndDate}
              value={endDate}>
            </DateTimePicker>
          </View>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Serial #</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              onChangeText={setSerialNumber}
              value={serialNumber}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Drawing Ref</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              onChangeText={setDrawingRef}
              value={drawingRef}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
          </View>

          <View style={GlobalStyle.column__flex}>
          </View>

          <View style={GlobalStyle.column__flex}>
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <Basket/>

      <ReportKey showRote={true} showPowra={true} roteLabel={"Proof Load"}/>
      
      <DataTable style={GlobalStyle.table}>
        {/* <DataTable.Header>
          <DataTable.Title>Basket</DataTable.Title>
          <DataTable.Title>Report</DataTable.Title>
          <DataTable.Title>Description</DataTable.Title>
          <DataTable.Title>Serial #</DataTable.Title>
          <DataTable.Title>Inspection Date</DataTable.Title>
          <DataTable.Title>Engineer</DataTable.Title>
          <DataTable.Title>Drawing Ref</DataTable.Title>
          <DataTable.Title>Location</DataTable.Title>
          <DataTable.Title>Company</DataTable.Title>
        </DataTable.Header> */}
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Basket', visible: true },
            { text: 'Report', visible: true },
            { text: 'Description', sortedName: "description", visible: true },
            { text: 'Serial Number', sortedName: "serialNumber", visible: true },
            { text: 'Inspection Date', sortedName: "InspectionDate", visible: true },
            { text: 'Engineer', sortedName: "engineer", visible: true },
            { text: 'Drawing Ref', sortedName: "drawingRef", visible: true },
            { text: 'Location', sortedName: "locationName", visible: true },
            { text: 'Company', sortedName: "companyName", visible: true },
            
          ]}
        />

        { inspectionResult.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>
                <BasketIcon inspectionId={element.id} serialNumber={element.serialNumber} type={"proofload"}/>
              </DataTable.Cell>              
              <DataTable.Cell>
              <View style={{ flexDirection: 'row'}}>
                  <TouchableOpacity onPress={() => downloadCert(element)}><Foundation name="page-pdf" size={24} color="black" /></TouchableOpacity>
                  { element.ramsId != null && <TouchableOpacity style={{ marginLeft: 20 }} onPress={() => downloadRAMS(element)}><Foundation name="page-pdf" size={24} color="blue" /></TouchableOpacity> }
                  { element.images != null && element.images.length > 0 &&
                    <TouchableOpacity style={{ marginLeft: 10 }} onPress={() => setImagesToView(element.images)}>
                      <Foundation name="camera" size={24} color="black" />
                    </TouchableOpacity>
                  }
                </View>
              </DataTable.Cell>
              <DataTable.Cell>{element.description}</DataTable.Cell>
              <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
              <DataTable.Cell>{element.inspectionDateFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.engineer}</DataTable.Cell>
              <DataTable.Cell>{element.drawingRef}</DataTable.Cell>
              <DataTable.Cell>{element.locationName}</DataTable.Cell>
              <DataTable.Cell>{element.companyName}</DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <DataTable.Pagination
        page={page}
        numberOfPages={inspectionResult.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)} 
        //@ts-ignore
        itemsPerPage={inspectionResult.data?.pagination.pageSize}
        showFastPagination
        optionsLabel={'Rows per page'}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

});
