import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';

export class ProofLoadDatabase extends Database {
  TABLE_NAME = "ProofLoadInspections";
  TABLE_NAME_IMAGES = "ProofLoadImages";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      
      var sql = `CREATE TABLE IF NOT EXISTS ProofLoadInspections ( 
        id TEXT PRIMARY KEY,
        inspectionDate TEXT,
        
        proofLoadTestType TEXT,
        additionalDetails TEXT,
        standards TEXT,
        quantityToBeTested INT,

        loadType TEXT,
        loadValue TEXT,
        drawingRef TEXT,
        customerOrderRef TEXT,

        assetNumber TEXT,
        dateOfManufacturer TEXT,
        description TEXT,
        swl TEXT,
        serialNumber TEXT,

        equipmentId TEXT,
        engineerId TEXT,
        jobId TEXT,
        purposeId TEXT,
        colourId TEXT,
        missing INT
      )`;

      var sqlImages = `CREATE TABLE IF NOT EXISTS ProofLoadImages (
        id TEXT PRIMARY KEY,
        inspectionId TEXT,
        base64 TEXT
      )`;

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlImages);
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  addInspection(inspectionDate: any, proofLoadTestType: any, additionalDetails: any, standards: any, quantityToBeTested: any,
    assetNumber: any, dateOfManufacturer: any, description: any, swl: any, serialNumber: any, equipmentId: any, engineerId: any, jobId: any, 
    purposeId: any, colourId: any, missing: any, loadType: any, loadValue: any, drawingRef: any, customerOrderRef: any,images: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        Database.getDb().transaction((tx) => {
          var sql = `
            INSERT OR REPLACE INTO ${this.TABLE_NAME} (
              id, inspectionDate, proofLoadTestType, additionalDetails, standards, quantityToBeTested, 
              assetNumber, dateOfManufacturer, description, swl, serialNumber,
              equipmentId, engineerId, jobId, purposeId, colourId, missing, loadType, loadValue, drawingRef, customerOrderRef
            )
            VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
          `
          var inspectionId = uuid.v4();

          tx.executeSql(sql, [
            inspectionId, inspectionDate, proofLoadTestType, additionalDetails, standards, quantityToBeTested, 
            assetNumber, dateOfManufacturer, description, swl, serialNumber,
            equipmentId, engineerId, jobId, purposeId, colourId, missing, loadType, loadValue, drawingRef, customerOrderRef
          ]);

          if (images.length > 0) {
            sql = `INSERT INTO ${this.TABLE_NAME_IMAGES} (id, inspectionId, base64) VALUES (?, ?, ?)`;
            for (let image of images) {
              tx.executeSql(sql, [uuid.v4(), inspectionId, image]);
            }
          }

          // Update this asset to be completed 
          sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? and equipmentId = ?";
          tx.executeSql(sql, [jobId, equipmentId]);

        }, (error) => {
          console.log("ERROR");
          console.log(error);
          reject();
        }, () => {
          console.log("UPDATED JOB Assets - JobId: " + jobId + " EquipId: " + equipmentId);
          resolve();
        }) 
      } catch (e) {
        console.log("ERROR INSIDE PROOF LOAD")
        reject();
        console.log(e)
      }
    })
  }
  
  deleteInspection(inspectionId) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `DELETE FROM ${this.TABLE_NAME} WHERE id = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => {});

        sql = `DELETE FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => {});
        
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  getForSync() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM ${this.TABLE_NAME}
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
          for (let inspection of data) {
            sql = `SELECT base64 FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
            tx.executeSql(sql, [inspection.id], (_, { rows: { _array } }) => {
              inspection.images = [];
              for (let image of _array) {
                inspection.images.push(image.base64)
              }
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }


}