import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator, ScrollView, Image, Alert } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { JobDatabase } from '@store/services/database/job-database';
import { FlashList } from "@shopify/flash-list";
import { AntDesign, Ionicons, MaterialCommunityIcons  } from '@expo/vector-icons';
import { TitleBar } from '../../../components/TitleBar';
import { Controller, useForm } from 'react-hook-form';
import { setInspectionData } from '@store/slices/rote-inspection-slice';
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import { colours } from '../../../../../config';
import { Camera, CameraType } from 'expo-camera';
import moment from 'moment';
import { CategoryDatabase } from '@store/services/database/category-database';
import { CameraModal } from '@app/components/CameraModal';
import { InspectionDatabase } from '@store/services/database/inspection-database';
import { EquipmentDatabase } from '@store/services/database/equipment-database';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { DatePicker } from '@app/components/DatePicker';

export default function RoteStep3({navigation, route}: {navigation: any, route: any}) {
  const [permission, requestPermission] = Camera.useCameraPermissions();

  const dispatch = useDispatch();

  const inspection = useSelector((state: RootState) => state.roteInspection);
  const job = useSelector((state: RootState) => state.job);
  const [showCamera, setShowCamera] = useState(false);
  const [images, setImages] = useState([]);


  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      identification: inspection.identification,
      particulars: inspection.particulars,
      safeForUse: inspection.safeForUse,

      inspectionDate: moment().toDate(),
      nextInspectionDate: null
    }
  });

  // useEffect(() => {
  //   console.log("UPDATING DATES");
  //   setDates();
  // }, [inspDate]);
  

  const safeForUse = watch("safeForUse");
  const inspDate = watch("inspectionDate");
  const nextInspDate = watch("nextInspectionDate");

  const setDates = async () => {
    var date = moment(inspDate);
    if (safeForUse == true || safeForUse == null) {
      if (job.purposeInterval == 6) { date = date.add(6, 'M'); }
      else if (job.purposeInterval == 12) { date = date.add(12, 'M') }
      else {
        // Get the category and use that interval
        var categoryDatabase = new CategoryDatabase();
        var category = await categoryDatabase.getById(job.categoryId);
        date = date.add(category.inspectionInterval, 'M');
      }
    }
    
    setValue("nextInspectionDate", date.toDate());
  }

  useEffect(() => {
    setDates();
  }, [inspDate, safeForUse])

  const addImage = (image) => {
    setImages([...images, image]);
    setShowCamera(false);
  }

  const removeImage = (image) => {
    Alert.alert("Images", "Are you sure you want to remove this image?", [
      {
        text: 'No',
        style: 'cancel'
      },
      { text: 'Yes', onPress: () => {setImages(images.filter(i => i !== image))}}
    ])
  }

  const loadCamera = () => {
    if (images.length >= 3) {
      Alert.alert("You have reached the maximum images.")
      return;
    }

    setShowCamera(true);
  }

  const saveInspection = async (data) => {
    var inspectionData = {...inspection};

    // var inspectionDate = moment().format("YYYY-MM-DD");
    // var nextInspectionDate = moment().format("YYYY-MM-DD");
    // if (safeForUse) {
    //   if (job.purposeInterval == 6) { nextInspectionDate = moment().add(6, 'M').format("YYYY-MM-DD");}
    //   else if (job.purposeInterval == 12) { nextInspectionDate = moment().add(12, 'M').format("YYYY-MM-DD"); }
    //   else {
    //     // Get the category and use that interval
    //     var categoryDatabase = new CategoryDatabase();
    //     var category = await categoryDatabase.getById(job.categoryId);
    //     nextInspectionDate = moment().add(category.inspectionInterval, 'M').format("YYYY-MM-DD");
    //   }
    // }

    inspectionData.identification = data.identification;
    inspectionData.particulars = data.particulars;
    inspectionData.safeForUse = data.safeForUse;


    var inspectionDatabase = new InspectionDatabase();
    await inspectionDatabase.addInspection(
      moment(data.inspectionDate).format("YYYY-MM-DD"), moment(data.nextInspectionDate).format("YYYY-MM-DD"), inspectionData.firstExamination, inspectionData.installedCorrectly,
      inspectionData.defects, inspectionData.immediateToPersons, inspectionData.becomeDangerToPersons, inspectionData.repairDate,
      inspectionData.repair, inspectionData.identification, inspectionData.particulars, inspectionData.safeForUse, 
      job.equipmentId, job.engineerId, job.jobId, job.purposeId, job.colourId, false, images);

    if (!safeForUse) {
      Alert.alert('Scrap', 'Do you want to scrap this asset?', [        
        { text: 'No', style: 'cancel', onPress: () => { navigation.navigate("JobAssets"); } },
        { text: 'Yes', onPress: () => {
          const equipmentDatabase = new EquipmentDatabase();
          equipmentDatabase.setScrapped(job.equipmentId);
          navigation.navigate("JobAssets");
        }}
      ]);

      return;
    }

    navigation.navigate("JobAssets");
  }

  const onSubmit = async data => {
    if (safeForUse == null) {
      Alert.alert("Safe for use", "Select if this asset is safe for use");
      return;
      
    }

    Alert.alert('Inspection', `Are you sure this is a ${safeForUse ? 'PASS' : 'FAIL'}`, [
      {
        text: 'NO',
      },
      { text: 'YES', onPress: () => saveInspection(data)},
    ]);
  };
   
  if (showCamera) {
    return (
      <CameraModal onCancel={() => setShowCamera(false)} onPictureTaken={(image) => addImage(image)}/>
    )
  }

  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} title="Step 3 of 3" showSearchButton={false} showBackButton={true} onSynced={null} onSearch={null}/>

      <ScrollView style={styles.form}>
        <View style={{ flexDirection: 'row' }}>
          <Controller
            control={control}
            name="inspectionDate"
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={[styles.step__question, styles.step__question_flex]}>
                <Text style={styles.step__question__label}>Inspection Date:</Text>

                <View style={styles.step__question__input}>
                  <DatePicker value={value} mode="date" onChanged={onChange} maximumDate={moment().toDate()}/>
                </View>
              </View>
            )}
          />

          
          { nextInspDate != null &&
            <Controller
              control={control}
              name="nextInspectionDate"
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={[styles.step__question, styles.step__question_flex]}>
                  <Text style={styles.step__question__label}>Next Inspection Date:</Text>

                  <View style={styles.step__question__input}>
                    <DatePicker value={value} mode="date" onChanged={onChange}/>
                  </View>
                </View>
              )}
            />
          }
        </View>
       

        <Controller
          control={control}
          name="identification"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Identification of any parts not accessible for examination:</Text>

              <TextInput
                style={styles.step__question__input}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
              />
            </View>
          )}
        />

        <Controller
          control={control}
          name="particulars"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Particulars of any test carried out as part of this examination:</Text>

              <TextInput
                style={styles.step__question__input}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
              />
            </View>
          )}
        />

        <View style={styles.image__container}>
          <TouchableOpacity onPress={() => loadCamera() } style={{ paddingLeft: 30, paddingRight: 30 }}>
            <Ionicons name="camera" size={92} color="black" />
          </TouchableOpacity>

          { images.map((image, idx) => {
            return (
              <TouchableOpacity key={idx} onPress={() => removeImage(image)}>
                <Image
                  style={[styles.image]}
                  resizeMode={"contain"}
                  source={{
                    uri: image,
                  }}
                />
              </TouchableOpacity>
            )
          })}
        </View>

        <Controller
          control={control}
          name="safeForUse"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.safe__block}>
              <TouchableOpacity style={[styles.safe__button, styles.not_safe, safeForUse == false && styles.unsafe_checked]} onPress={() => onChange(false)}>
                <MaterialCommunityIcons name="cancel" size={44} color={safeForUse == false ? 'white' : 'black'}/>
                <Text style={[styles.safe__button__text, safeForUse == false && styles.safe__button__text__checked]}>NOT SAFE FOR USE</Text>
              </TouchableOpacity>

              <TouchableOpacity style={[styles.safe__button, styles.safe, safeForUse == true && styles.safe_checked]} onPress={() => onChange(true)}>
                <Ionicons name="checkmark-circle-outline" size={44} color={safeForUse == true ? 'white' : 'black'} />
                <Text style={[styles.safe__button__text, safeForUse == true && styles.safe__button__text__checked]}>SAFE FOR USE</Text>
              </TouchableOpacity>
            </View>
          )}
        />

        {/* <View>
          <TouchableOpacity onPress={() => takePhoto()}>
            <Ionicons name="camera" size={68} color="black" />
          </TouchableOpacity>
        </View> */}

      </ScrollView>

      <TouchableOpacity style={styles.proceed__button} onPress={handleSubmit(onSubmit)}>
        <Text style={styles.proceed__button__text}>SAVE</Text>
      </TouchableOpacity>

    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 40,
  },

  form: {
    flex: 1,
    marginTop: 10,
  },

    step__question: {
      padding: 20,
      backgroundColor: '#FFF',
      marginBottom: 20,
    },

    step__question_flex: {
      flex: 1
    },

    step__question__label: {
      fontFamily: 'OpenSans-Light',
      fontSize: 18,
      marginBottom: 5,
    },

    step__question__input: {
      borderWidth: 1,
      borderColor: '#CECECE',
      padding: 10,
      marginTop: 5,
    },

    input__validation: {
      color: 'red',
      marginTop: 10
    },

    proceed__button: {
      padding: 10,
      backgroundColor: colours.primary,
      marginTop: 20,
      borderRadius: 7,
      height: 45,
  
  
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  
      proceed__button__text: {
        fontFamily: 'OpenSans-Bold',
        color: '#FFF',
        textAlign: 'center',
        fontSize: 18
      },

  safe__block: {
    flexDirection: 'row',
  },

  safe__button: {
    flex: 1,
    height: 140,
    backgroundColor: '#edebeb',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 5,
  },

    safe__button__text: {
      fontFamily: 'OpenSans-Bold',
      marginLeft: 10,
      fontSize: 38
    },

    not_safe: {
      marginRight: 10,
    },

    safe: {
      marginLeft: 10,
    },

    unsafe_checked: {
      backgroundColor: 'red',
    },

    safe_checked: {
      backgroundColor: 'green',
    },

    safe__button__text__checked: {
      color: '#FFF'
    },

  image__container: {
    flexDirection: 'row',
    padding: 10,
    marginBottom: 20,
    alignItems: 'center'
  },

    // image__block: {
    //   flex: 1,
    // },

      image: {
        height: 150,
        width: 150,
        marginRight: 20,
        marginLeft: 20,
      },

    empty__image: {
      borderWidth: 1,
      borderColor: '#CECECE'
    }
});
