import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';

import { DataTable } from 'react-native-paper';
import { useIsFocused } from "@react-navigation/native";
import GlobalStyle from '../../../../../../constants/GlobalStyle';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import { jobApi, useCompleteJobMutation, useGetJobsQuery } from '@store/services/api/job-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { Picker } from '@react-native-picker/picker';
import { SimpleLineIcons, Ionicons} from '@expo/vector-icons';

export default function JobApprovalScreen({navigation}: {navigation: any}) {
  const isFocused = useIsFocused();
  // const [completeJob, completeJobResult] = useCompleteJobMutation();
  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);

  // Filters 
  const [companyId, setCompanyId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [jobNumber, setJobNumber] = useState("");

  const [page, setPage] = React.useState<number>(0);
  const [trigger, jobsResult] = jobApi.useLazyGetJobsQuery();

  React.useEffect(() => {
    if (isFocused) {
      loadTable();
    }
  }, [page, isFocused]);

  const loadTable = () => {
    trigger(new PagedTableDto(page, {
      companyId: companyId, 
      locationId: locationId,
      jobNumber: jobNumber, 
      statusId: 4
    }));
  }

  const resetFilters = () => {
    setCompanyId("");
    setLocationId("");
    setJobNumber("");
  }

  // const markJobCompleted = async (id) => {
  //   if (confirm("Are you sure you want to complete this job?")) {
  //     var result = await completeJob(id) as any;
  //     if (result.error) {
  //       alert("Error completing job. Please try again.")
  //       return;
  //     }

  //     loadTable();
  //   }
  // }

  if (jobsResult.isLoading) {
    return <LoadingIndicator/>
  }


  return (
    <View style={styles.container}>
        <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}> 
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={companyId}
              onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); }}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All Companies" value="" />

              { companies != null && companies.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={locationId}
              onValueChange={(itemValue, itemIndex) => setLocationId(itemValue)}
              itemStyle={{ height: 50 }}>

              <Picker.Item label="All Locations" value="" />

              { locations != null && companyId != "" && locations.filter(l => l.companyId == companyId).map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Job #</Text>
            <TextInput 
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              placeholder="Job #"
              value={jobNumber}
              onChangeText={setJobNumber}
            />
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <DataTable style={GlobalStyle.table}>
        <DataTable.Header>
          <DataTable.Title>Company</DataTable.Title>
          <DataTable.Title>Location</DataTable.Title>
          <DataTable.Title>Job Number</DataTable.Title>
          <DataTable.Title>Added</DataTable.Title>
          <DataTable.Title>Job Type</DataTable.Title>
          <DataTable.Title>Option</DataTable.Title>
        </DataTable.Header>

        { jobsResult?.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.companyName}</DataTable.Cell>
              <DataTable.Cell>{element.locationName}</DataTable.Cell>
              <DataTable.Cell>{element.jobNumber}</DataTable.Cell>
              <DataTable.Cell>{element.createdOnFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.jobTypeFormatted}</DataTable.Cell>
              <DataTable.Cell></DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <DataTable.Pagination
        page={page}
        numberOfPages={jobsResult?.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)} 
        //@ts-ignore
        itemsPerPage={jobsResult?.data?.pagination.pageSize}
        showFastPagination
        optionsLabel={'Rows per page'}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
