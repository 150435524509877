import * as React from 'react';
import { useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';

import { DataTable } from 'react-native-paper';
import { useIsFocused } from "@react-navigation/native";
import { Entypo } from '@expo/vector-icons';
import GlobalStyle from '../../../constants/GlobalStyle';

export default function JobAssetList({navigation}: {navigation: any}) {
  

  return (
    <View style={styles.container}>
     
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
