import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { FlashList } from "@shopify/flash-list";
import { TitleBar } from '../../components/TitleBar';
import { AntDesign } from '@expo/vector-icons';
import moment from "moment";
import { Controller, useForm } from 'react-hook-form';
import { colours } from '../../../../config';
import { PurposeDatabase } from '../../../../store/services/database/purpose-database';
import { Picker } from '@react-native-picker/picker';
import { ColourDatabase } from '../../../../store/services/database/colour-database';
import { JobDatabase } from '../../../../store/services/database/job-database';
import { setJobData } from '../../../../store/slices/job-slice';
import { RootState } from '@store/index';

export default function JobDetailsScreen({navigation, route}: {navigation: any, route: any}) {
  const dispatch = useDispatch();
  const job = useSelector((state: RootState) => state.job);

  const [purposes, setPurposes] = useState([]);
  const [colours, setColours] = useState([]);

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      purposeId: null,
      colourId: null
    }
  });

  const jobDatabase = new JobDatabase();

  const loadPurposes = async() => {
    const purposeDatabase = new PurposeDatabase();
    var purposes = await purposeDatabase.getAll();
    setPurposes(purposes);
  }

  const loadColours = async() => {
    const coloursDatabase = new ColourDatabase();
    var colours = await coloursDatabase.getAll();
    setColours(colours);
  }

  useEffect(() => {
    loadPurposes();
    loadColours();
  
    setValue("purposeId", job.purposeId);
    setValue("colourId", job.colourId);
  }, [])

  const onSubmit = async data => {
    await jobDatabase.updateJobData(job.jobId, data.purposeId, data.colourId);

    var purpose = purposes.find(p => p.id == data.purposeId);

    dispatch(setJobData({
      purposeId: data.purposeId,
      colourId: data.colourId,
      purposeInterval: purpose.interval
    }))


    navigation.navigate("JobAssets");
  };

  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} title="Job Details" showBackButton={true} onSynced={null} showSearchButton={false} onSearch={null}/>

      {job != null &&
        <Text style={styles.job__title}>
          {job.jobTitle}
        </Text>
      }

      <View style={styles.form}>
        <Controller
          control={control}
          rules={{ required: true }}
          name="purposeId"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Purpose of Examination</Text>

              <Picker
                selectedValue={value}
                onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                itemStyle={{ height: 50 }}>

                <Picker.Item label="Please Select" value="" />

                { purposes != null && purposes.map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.purpose} value={value.id} />
                  )
                })}
              </Picker>

              {errors.purposeId && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
            </View>
          )}
        />

        <Controller
          control={control}
          rules={{ required: true }}
          name="colourId"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Colours</Text>

              <Picker
                selectedValue={value}
                onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                itemStyle={{ height: 50 }}>

                <Picker.Item label="Please Select" value="" />

                { colours != null && colours.map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                  )
                })}
              </Picker>

              {errors.purposeId && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
            </View>
          )}
        />
      </View>

      <TouchableOpacity style={styles.proceed__button} onPress={handleSubmit(onSubmit)}>
        <Text style={styles.proceed__button__text}>NEXT</Text>
      </TouchableOpacity>

    {/* <Picker
      style={GlobalStyle.form__column__input}
      selectedValue={value}
      onValueChange={(itemValue, itemIndex) => onChange(itemValue)}>

      <Picker.Item label="Please Select" value="" />

      { companies != null && companies.map((value, index) => {
        return (
          <Picker.Item key={value.id} label={value.name} value={value.id} />
        )
      })}
    </Picker> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 40,
  },

  job__title: {
    marginTop: 10,
    marginBottom: 10,
    fontFamily: 'OpenSans-Bold',
    fontSize: 22,
    backgroundColor: '#FFF',
    padding: 20,
  },

  form: {
    flex: 1,
    marginTop: 10,
  },

    step__question: {
      padding: 20,
      backgroundColor: '#FFF',
      marginBottom: 20,
    },

    step__question__label: {
      fontFamily: 'OpenSans-Light',
      fontSize: 18,
      marginBottom: 5,
    },

    input__validation: {
      color: 'red',
      marginTop: 10
    },

    proceed__button: {
      padding: 10,
      backgroundColor: colours.primary,
      marginTop: 20,
      borderRadius: 7,
      height: 45,
  
  
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  
      proceed__button__text: {
        fontFamily: 'OpenSans-Bold',
        color: '#FFF',
        textAlign: 'center',
        fontSize: 18
      },
});
