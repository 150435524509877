import { baseApi, PagedTableDto } from './base-api'

export const purposeApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPurposes: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `purpose/getpurposes?pageNumber=${dto.page + 1}&filters=${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    syncPurposes: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `purpose/getpurposesforsync?lastmodifiedon=${lastModifiedOn}`,
      })
    })
  }),
  overrideExisting: true,
})

export const { useGetPurposesQuery } = purposeApi