import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { JobDatabase } from '@store/services/database/job-database';
import { SyncService } from '@store/services/sync-service';
import { JobGridStats } from './components/JobGridStats';
import { JobsList } from './components/Jobs';
import { TitleBar } from '../../components/TitleBar';
import { useIsFocused } from '@react-navigation/native';

export default function AppDashboardScreen({navigation}: {navigation: any}) {
  const isFocused = useIsFocused();
  const user = useSelector((state: RootState) => state.user.user);
  const [jobs, setJobs] = useState([]);

  const jobDatabase = new JobDatabase();

  const loadJobs = async (searchQuery = null) => {
    var jobs = await jobDatabase.getJobs(user.id, searchQuery);
    setJobs(jobs);
  }

  useEffect(() => {
    loadJobs();
  }, [isFocused])

  return (
    <View style={styles.container}>

      <TitleBar navigation={navigation} 
        title="Dashboard" 
        showSearchButton={true} 
        showBackButton={false}
        onSearch={(searchQuery) => loadJobs(searchQuery)} 
        onSynced={() => loadJobs()} />

      <JobGridStats jobs={jobs}/>

      <JobsList navigation={navigation} jobs={jobs}/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 20,
    paddingTop: 40,
  },

});
