import { baseApi, PagedTableDto } from './base-api'

export const breakdownApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBreakdownInspections: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `breakdown/getbreakdowninspections?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getBreakdownPartsList: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `breakdown/getpartslist?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    syncBreakdownCommonFaults: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `breakdown/getcommonfaults?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    syncParts: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `breakdown/getparts?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
    createBreakdownPart: build.mutation<any, any>({
      query: (body) => ({
        url: 'breakdown/addpart',
        method: 'post',
        body: body
      }),
    }),
    updateBreakdownPart: build.mutation<any, any>({
      query: (body) => ({
        url: `breakdown/updatepart/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    getBreakdownPart: build.query<any, any>({
      query: (partId) => ({
        url: `breakdown/getpart/${partId}`,
      }),
    }),
    deleteBreakdownPart: build.mutation<any, any>({
      query: (partId) => ({
        url: `breakdown/deletebreakdownpart/${partId}`,
        method: 'DELETE',
      }),
    }),
    sendBreakdownInspection: build.mutation<any, any>({
      query: (body) => ({
        url: 'breakdown/addbreakdown',
        method: 'post',
        body: body
      }),
    }),
  }),
  overrideExisting: true,
})

export const { 
  useSyncBreakdownCommonFaultsMutation, 
  useSyncPartsMutation, 
  useGetBreakdownPartQuery, 
  useGetBreakdownPartsListQuery, 
  useCreateBreakdownPartMutation, 
  useUpdateBreakdownPartMutation,
  useDeleteBreakdownPartMutation,
  useSendBreakdownInspectionMutation,
  useGetBreakdownInspectionsQuery,
} = breakdownApi