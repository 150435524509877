
import React, { useState, useEffect } from "react";
import { createElement } from "react-native";

export default function DateTimePicker({ value, onChange, style }: Props) {
  const [date, setDate] = useState(value);
  
  useEffect(() => {
    setDate(value);
  }, [value]);


  const getFormattedDate = (val) => {
    const yyyy = val.getFullYear();
    let mm = val.getMonth() + 1; // Months start at 0!
    let dd = val.getDate();
  
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
  
    return yyyy + '-' + mm + '-' + dd;
  }

  return createElement('input', {
    type: 'date',
    //value:  date.toISOString().split("T")[0],
    value: getFormattedDate(date),
    onInput: onChange,
    style: style,

    onChange: (event) => {
      setDate(new Date(event.target.value));
      onChange(new Date(event.target.value));
    },
  })
}