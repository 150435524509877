import { baseApi, PagedTableDto } from './base-api'

export const manufacturerApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getManufacturers: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `manufacturer/getmanufacturers?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getManufacturer: build.query<any, any>({
      query: (manufacturerId) => ({
        url: `manufacturer/getmanufacturer/${manufacturerId}`,
      }),
    }),
    getAllManufacturers: build.query<any, any>({
      query: (companyId) => ({
        url: `manufacturer/getallmanufacturers`,
      }),
    }),
    createManufacturer: build.mutation<any, any>({
      query: (body) => ({
        url: 'manufacturer/addmanufacturer',
        method: 'post',
        body: body
      }),
    }),
    updateManufacturer: build.mutation<any, any>({
      query: (body) => ({
        url: `manufacturer/updatemanufacturer/${body.id}`,
        method: 'put',
        body: body
      }),
    }),
    syncManufacturers: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `manufacturer/getmanufacturersforsync?lastmodifiedon=${lastModifiedOn}`,
      })
    })
  }),
  overrideExisting: true,
})

export const { useGetManufacturersQuery, useGetManufacturerQuery, useGetAllManufacturersQuery, useCreateManufacturerMutation, useUpdateManufacturerMutation } = manufacturerApi