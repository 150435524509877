import { Platform } from 'react-native';
import { store } from '..';
import { authApi, useLoginMutation } from './api/auth-api';
import { UserDatabase } from './database/user-database';
import { ServiceResponse } from './service-response';

export class UserService {
  public login = (email, password) : Promise<ServiceResponse> => {
    return new Promise(async (resolve, reject) => {
      // We require logging in to have an internet connection
      var result = await store.dispatch(authApi.endpoints.login.initiate({ email: email, password: password })) as any;
      if (result.error) {
        resolve(ServiceResponse.throwError());
        return;
      }
      resolve(ServiceResponse.success(result.data));

      // console.log(Platform.OS);
      // if (Platform.OS == "android" || Platform.OS == "ios") {
      //   const userDatabase = new UserDatabase();
      //   var result = await userDatabase.login(email, password);
      //   resolve(result);
      // } else {
      //   var result = await store.dispatch(authApi.endpoints.login.initiate({ email: email, password: password })) as any;
      //   if (result.error) {
      //     resolve(ServiceResponse.throwError());
      //     return;
      //   }
      //   resolve(ServiceResponse.success(result.data));
      // }
    });
  }
}