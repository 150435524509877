import { baseApi, PagedTableDto } from './base-api'

export const engineerReportApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    sendEngineerReport: build.mutation<any, any>({
      query: (body) => ({
        url: `engineerreport/addengineerreport`,
        method: 'post',
        body: body
      }),
    }),
    syncEngineerReports: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `engineerreport/GetEngineerReportsForSync?lastmodifiedon=${lastModifiedOn}`,
      })
    }),
  }),
  overrideExisting: true,
})

export const { useSendEngineerReportMutation } = engineerReportApi