import { baseApi, PagedTableDto } from './base-api'

export const colourApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getColours: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `colour/getcolours?pageNumber=${dto.page + 1}&filters=${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    syncColours: build.mutation<any, any>({
      query: (lastModifiedOn) => ({
        url: `colour/getcoloursforsync?lastmodifiedon=${lastModifiedOn}`,
      })
    })
  }),
  overrideExisting: true,
})

export const { useGetColoursQuery } = colourApi