import * as React from 'react';
import { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, Platform } from 'react-native';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';

import { DataTable } from 'react-native-paper';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { useIsFocused } from "@react-navigation/native";
import { Entypo, Ionicons } from '@expo/vector-icons';
import { useDeleteBreakdownPartMutation, useGetBreakdownPartsListQuery } from '@store/services/api/breakdown-api';
import { PagedTableDto } from '@store/services/api/base-api';
import { useDebounce } from '../../../../../../components/helpers/UseDebounce';
import { useDeleteUserMutation, useGetEngineersQuery, userApi } from '@store/services/api/user-api';
import { DataTableHeader } from '@web/table/DataTableHeader';


export default function TabletUsersList({ navigation }: { navigation: any }) {

  const [deleteUser, deleteUserResult] = useDeleteUserMutation();
  const isFocused = useIsFocused()

  // const { data, error, isLoading, refetch } = useGetEngineersQuery();
  const [trigger, usersResult] = userApi.useLazyGetUsersQuery();
  const [page, setPage] = React.useState<number>(0);

  const [sortedColumn, setSortedColumn] = React.useState<string>("Name");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

  // React.useEffect(() => {
  //   if (isFocused) {
  //     refetch();
  //   }
  // }, [isFocused]);

  useEffect(() => {
    loadTable();
  }, [page, sortedColumn, sortedColumnAscending, isFocused])

  const loadTable = () => {
    var dto = new PagedTableDto(page, {
      // name: name,
      role: "Engineer",
    }, sortedColumn, sortedColumnAscending);
    trigger(dto);
  }

  const remove = async (entityId) => {
    if (Platform.OS == 'web') {
      if (confirm("Are you sure you want to delete this user?")) {
        var result = await deleteUser(entityId);
        if (result.error) {
          Alert.alert("Error", "Something went wrong. Please try again");
          return;
        }

        // refetch();
        loadTable();
      }
    }
  }

  if (usersResult.isLoading || deleteUserResult.isLoading) {
    return <LoadingIndicator />
  }

  return (
    <View style={styles.container}>
      {/* <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <TextInput 
            style={[GlobalStyle.column, GlobalStyle.column__input]} 
            placeholder="Part Description"
            onChangeText={setDescription}
          />
        </View>
      </View> */}

      <TouchableOpacity onPress={() => navigation.navigate("manage")}>
        <Text style={GlobalStyle.add__new__link}>Add New Engineer</Text>
      </TouchableOpacity>

      <DataTable style={GlobalStyle.table}>
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[
            { text: 'Name ', sortedName: "Name", visible: true },
            { text: 'Email', sortedName: "Email", visible: true },
            { text: 'Option', visible: true },
          ]}
        />
        {usersResult.data?.list.map((element: any) => {
          return (

            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.name}</DataTable.Cell>
              <DataTable.Cell>{element.email}</DataTable.Cell>

              <DataTable.Cell>
                <View style={{ flexDirection: 'row' }}>
                  <TouchableOpacity onPress={() => navigation.navigate("manage", { userId: element.id })}>
                    <Entypo name="pencil" size={24} color="black" />
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => remove(element.id)} style={{ marginLeft: 20 }}>
                    <Ionicons name="ios-remove-circle" size={24} color="red" />
                  </TouchableOpacity>
                </View>

              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>
      <DataTable.Pagination
        page={page}
        numberOfPages={usersResult.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)}
        //@ts-ignore
        itemsPerPage={usersResult.data?.pagination.pageSize}
        showFastPagination
        optionsLabel={'Rows per page'}
      />

      {/* <DataTable style={GlobalStyle.table}>
        <DataTable.Header>
          <DataTable.Title>Name</DataTable.Title>
          <DataTable.Title>Email</DataTable.Title>
          <DataTable.Title>Option</DataTable.Title>
        </DataTable.Header>

        { data.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              <DataTable.Cell>{element.name}</DataTable.Cell>
              <DataTable.Cell>{element.email}</DataTable.Cell>
           
              <DataTable.Cell>
                <View style={{ flexDirection: 'row'}}>
                  <TouchableOpacity onPress={() => navigation.navigate("manage", { userId: element.id })}>
                    <Entypo name="pencil" size={24} color="black" />
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => remove(element.id) } style={{ marginLeft: 20 }}>
                    <Ionicons name="ios-remove-circle" size={24} color="red" />
                  </TouchableOpacity>
                </View>
                
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable> */}

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
