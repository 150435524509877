import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert, ActivityIndicator } from 'react-native';
import { EvilIcons, Ionicons } from '@expo/vector-icons';
import config from '../../../config';
import { useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { SyncService } from '@store/services/sync-service';
import AsyncStorage from '@react-native-async-storage/async-storage';

export function TitleBar({ navigation, title, showBackButton, showSearchButton, onSynced, onSearch, promptBack = false }) {
  const user = useSelector((state: RootState) => state.user.user);
  const [syncing, setSyncing] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");

  const goBack = () => {
    if (promptBack) {
      Alert.alert('Back', 'Are you sure you want to go back? This may lose any progress', [        
        { text: 'No', style: 'cancel', },
        { text: 'Yes', onPress: () => navigation.goBack() }
      ]);
      return;
    }
    navigation.goBack();
  }

  const logout = () => {
    Alert.alert("Logout", "Are you sure you want to logout?", [
      {
        text: 'No',
        style: 'cancel'
      },
      { text: 'Yes', onPress: () => {AsyncStorage.removeItem("user"); navigation.navigate("login"); }}
    ])
  }

  const search = () => {
    onSearch(searchQuery);
  }

  
  const sync = async () => {
    if (syncing)
      return;

    setSyncing(true);
    var syncService = new SyncService();
    await syncService.syncAll();
    setSyncing(false);

    if (onSynced) onSynced();
  }


  return (
    <View style={styles.title__container}>
      {showBackButton && 
        <TouchableOpacity style={{ alignItems: 'center', justifyContent: 'center', marginRight: 20, marginTop: 5 }} onPress={() => goBack()}>
          <Ionicons name="arrow-back-circle-outline" size={36} color="black" />
        </TouchableOpacity> 
      }
      <Text style={styles.title__text}>{ title }</Text>
      <View style={styles.spacer}></View>
      { showSearchButton && 
        <View style={styles.search__bar}>
          <TextInput onChangeText={setSearchQuery} style={styles.search__input} placeholder="Search"/>
          <TouchableOpacity style={styles.search__button} onPress={() => search()}>
            <EvilIcons name="search" size={24} color="#FFF" />
          </TouchableOpacity>
        </View>
      }

      <TouchableOpacity style={styles.sync__button} onPress={() => sync()}>
        { !syncing && 
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Ionicons name="sync" size={24} color="#FFF" />
            <Text style={styles.sync__button__text}>Sync</Text>
          </View>
        }
        { syncing && <ActivityIndicator  color="#FFF" /> }
      </TouchableOpacity>

      <TouchableOpacity style={styles.user} onPress={() => logout()}>
        <Text>{user.name}</Text>
        <Ionicons name="exit-outline" size={24} color="black" style={{ marginLeft: 10 }} />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  title__container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

    title__text: {
      fontFamily: 'OpenSans-Bold',
      fontSize: 32,
    },

    spacer: {
      flex: 1,
    },

    search__bar: {
      backgroundColor: '#FFF',
      alignItems: 'center',
      width: 350,
      flexDirection: 'row',
      borderRadius: 5
    },

      search__input: {
        padding: 10,
        flex: 1,
        fontFamily: 'OpenSans-Regular',
      },

      search__button: {
        backgroundColor: config.colours.primary,
        height: 35,
        width: 35,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,
      },

    user: {
      marginLeft: 20,
      backgroundColor: '#FFF',
      alignItems: 'center',
      flexDirection: 'row',
      borderRadius: 5,
      fontFamily: 'OpenSans-Bold',
      padding: 15,
    },

    sync__button: {
      backgroundColor: config.colours.primary,
      width: 100,
      height: 40,
      borderRadius: 5,
      flexDirection: 'row',
      marginLeft: 10,
      alignItems: 'center',
      justifyContent: 'center'
    },

    sync__button__text: {
      fontFamily: 'OpenSans-Bold',
      color: '#FFF',
      marginLeft: 10,
    }

});
