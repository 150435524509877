import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { JobDatabase } from '@store/services/database/job-database';
import { FlashList } from "@shopify/flash-list";
import { AntDesign } from '@expo/vector-icons';
import { TitleBar } from '../../../components/TitleBar';
import { Controller, useForm } from 'react-hook-form';
import { setInspectionData } from '@store/slices/rote-inspection-slice';
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import { colours } from '../../../../../config';

export default function RoteStep2({navigation, route}: {navigation: any, route: any}) {
  const dispatch = useDispatch();

  const inspection = useSelector((state: RootState) => state.roteInspection);

  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      defects: inspection.defects,
      immediateToPersons: inspection.immediateToPersons,
      becomeDangerToPersons: inspection.becomeDangerToPersons,
      repairDate: inspection.repairDate,
      repair: inspection.repair,
    }
  });

  const defects = watch("defects");
  const immediateToPersons = watch("immediateToPersons");
  const becomeDangerToPersons = watch("becomeDangerToPersons");

  const onSubmit = data => {
    dispatch(setInspectionData({
      defects: data.defects, 
      immediateToPersons: data.immediateToPersons, 
      becomeDangerToPersons: data.becomeDangerToPersons, 
      repairDate: data.repairDate, 
      repair: data.repair, 
    }));

    navigation.navigate("Step3");
  };

  return (
    <View style={styles.container}>
      <TitleBar navigation={navigation} title="Step 2 of 3" showSearchButton={false} showBackButton={true} onSynced={null} onSearch={null}/>

      <ScrollView style={styles.form}>
        <Controller
          control={control}
          rules={{ required: true }}
          name="defects"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={styles.step__question}>
              <Text style={styles.step__question__label}>Identification of any parts found to have a defect which is or could become a danger to persons and a description of the defect:</Text>

              <TextInput
                style={styles.step__question__input}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
              />

              {errors.defects && <Text style={styles.input__validation}>Defects is required.</Text>}
            </View>
          )}
        />

        { defects != 'NONE' && 
          <Controller
            control={control}
            rules={{ required: true }}
            name="immediateToPersons"
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={styles.step__question}>
                <Text style={styles.step__question__label}>Is the above a defect which is of immediate danger to persons?</Text>

                <RadioButtonGroup 
                  containerStyle={{ marginTop: 10, flexDirection: 'row' }}
                  radioBackground={colours.primary}
                  selected={value}
                  onSelected={onChange}>

                  <RadioButtonItem value={0} label="No" />
                  <RadioButtonItem value={1} style={{ marginLeft: 20 }} label="Yes" />
                </RadioButtonGroup>

                {errors.immediateToPersons && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
              </View>
            )}
          />
        }

        {immediateToPersons == false && 
          <View>
            <Controller
              control={control}
              rules={{ required: true }}
              name="becomeDangerToPersons"
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.step__question}>
                  <Text style={styles.step__question__label}>Is the above defect which is not yet but could become a danger to persons?</Text>

                  <RadioButtonGroup 
                    containerStyle={{ marginTop: 10, flexDirection: 'row' }}
                    radioBackground={colours.primary}
                    selected={value}
                    onSelected={onChange}>

                    <RadioButtonItem value={0} label="No" />
                    <RadioButtonItem value={1} style={{ marginLeft: 20 }} label="Yes" />
                  </RadioButtonGroup>

                  {errors.becomeDangerToPersons && <Text style={styles.input__validation}>Please select a response before continuing</Text>}
                </View>
              )}
            />

            { becomeDangerToPersons == true && 
              <Controller
                control={control}
                name="repairDate"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={styles.step__question}>
                    <Text style={styles.step__question__label}>Please state the date by when:</Text>
    
                    <TextInput
                      style={styles.step__question__input}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                    />
                  </View>
                )}
              />
            }

            <Controller
              control={control}
              name="repair"
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.step__question}>
                  <Text style={styles.step__question__label}>Particulars of any repair, renewal or alteration required to remedy the defect identified above:</Text>

                  <TextInput
                    style={styles.step__question__input}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                </View>
              )}
            />
          </View>
        }

      </ScrollView>

      <TouchableOpacity style={styles.proceed__button} onPress={handleSubmit(onSubmit)}>
        <Text style={styles.proceed__button__text}>NEXT</Text>
      </TouchableOpacity>

    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 40,
  },

  form: {
    flex: 1,
    marginTop: 10,
  },

    step__question: {
      padding: 20,
      backgroundColor: '#FFF',
      marginBottom: 20,
    },

    step__question__label: {
      fontFamily: 'OpenSans-Light',
      fontSize: 18,
      marginBottom: 5,
    },

    step__question__input: {
      borderWidth: 1,
      borderColor: '#CECECE',
      padding: 10,
      marginTop: 5,
    },

    input__validation: {
      color: 'red',
      marginTop: 10
    },

    proceed__button: {
      padding: 10,
      backgroundColor: colours.primary,
      marginTop: 20,
      borderRadius: 7,
      height: 45,
  
  
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  
      proceed__button__text: {
        fontFamily: 'OpenSans-Bold',
        color: '#FFF',
        textAlign: 'center',
        fontSize: 18
      },
});
