import * as React from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useState, useEffect, useRef } from 'react';
import { Picker } from '@react-native-picker/picker';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import GlobalStyle from '../../../../constants/GlobalStyle';
import { useGetEngineersQuery } from '@store/services/api/user-api';
import moment from 'moment';
import DateTimePicker from '@web/components/navigation/controls/DateTimePicker';
import { useGetAllCategoriesQuery } from '@store/services/api/category-api';
import { Foundation, SimpleLineIcons } from '@expo/vector-icons';
import { inspectionApi } from '@store/services/api/inspection-api';
import { LoadingIndicator } from '@web/components/navigation/controls/LoadingIndicator';
import { PagedTableDto } from '@store/services/api/base-api';
import { DataTable } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/index';
import { ReportableService } from '@store/services/reportable/reportable.service';
import { Basket, BasketIcon } from '@web/components/Basket';
import { ImageViewer } from '@web/components/ImageViewer';
import { ReportKey } from '@web/components/ReportKey';
import { DatePicker } from '@app/components/DatePicker';
import { DataTableHeader } from '@web/table/DataTableHeader';

export default function DashboardScreen({navigation}: {navigation: any}) {

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const [jobNumber, setJobNumber] = useState("");

  const [page, setPage] = React.useState<number>(0);
  const [trigger, inspectionResult] = inspectionApi.useLazyGetInspectionsDueQuery();

  const [companyId, setCompanyId] = useState(user.companyId);
  const [locationId, setLocationId] = useState("");
  const [jobType, setJobType] = useState("-1");
  const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState(moment().endOf('month').toDate());
  const [sortedColumn, setSortedColumn] = React.useState<string>("serialNumber");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

  useEffect(() => {
    console.log(inspectionResult);
  }, [inspectionResult])


  const resetFilters = () => {
    setCompanyId(user.companyId);
    setLocationId("");
    setJobNumber("");
    setJobType("-1");
    setStartDate(moment().startOf('month').toDate());
    setEndDate(moment().endOf('month').toDate());
  }

  const loadTable = () => {
    var dto = new PagedTableDto(page, { 
      companyId: companyId,
      locationId: locationId,
      jobNumber: jobNumber,
      jobType: +jobType,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).add(1, 'days').toISOString(),
    }, sortedColumn, sortedColumnAscending);
    trigger(dto);
  }

  // useEffect(() => {
  //   loadTable();
  // }, [page])
  useEffect(() => {
    loadTable();
  }, [page, sortedColumn, sortedColumnAscending])

  useEffect(() => {
    console.log(inspectionResult.data);
  }, [inspectionResult.data]);
  
  if (inspectionResult.isLoading) {
    return <LoadingIndicator/>
  }
 
  return (
    <View style={styles.container}>

      <View style={GlobalStyle.filters}>
        <View style={GlobalStyle.filters__header}>
          <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
        </View>

        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Company</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={companyId}
              enabled={user.role != 'User'}
              onValueChange={(itemValue, itemIndex) => { setCompanyId(itemValue); setLocationId(""); }}>

              <Picker.Item label="Please Select" value="" />

              { companies != null && companies.map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Location</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={locationId}
              onValueChange={(itemValue, itemIndex) => setLocationId(itemValue)}>

              <Picker.Item label="Please Select" value="" />

              { locations != null && locations.filter(l => companyId == "" || l.companyId == companyId).map((value, index) => {
                return (
                  <Picker.Item key={value.id} label={value.name} value={value.id} />
                )
              })}
            </Picker>
          </View>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Job Type</Text>
            <Picker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              selectedValue={jobType}
              onValueChange={(itemValue, itemIndex) => setJobType(itemValue)}>

              <Picker.Item label="All" value="-1" />
              <Picker.Item label="ROTE" value="0" />
              <Picker.Item label="Servicing" value="1" />
              <Picker.Item label="Breakdown" value="2" />

              
            </Picker>
          </View>
          
          
        </View>
        <View style={GlobalStyle.filter__columns}>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Job #</Text>
            <TextInput
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              onChangeText={setJobNumber}
              value={jobNumber}
            />
          </View>
          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>Start Date</Text>
            <DateTimePicker
              style={[GlobalStyle.column, GlobalStyle.column__input]} 
              onChange={setStartDate}
              value={startDate}>
            </DateTimePicker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <Text style={GlobalStyle.column__header}>End Date</Text>
            <DateTimePicker
              style={[GlobalStyle.column, GlobalStyle.column__input,]} 
              onChange={setEndDate}
              value={endDate}>
            </DateTimePicker>
          </View>

          <View style={GlobalStyle.column__flex}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => loadTable()}>
                <SimpleLineIcons name="magnifier" size={16} color="white" />
              </TouchableOpacity>

              <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                <SimpleLineIcons name="ban" size={16} color="black" />
              </TouchableOpacity>
            </View>
          </View>

        </View>
          
        
      </View>



      <DataTable style={GlobalStyle.table}>
        {/* <DataTable.Header>
          <DataTable.Title>Description</DataTable.Title>
          <DataTable.Title>Next Inspection Date</DataTable.Title>
          <DataTable.Title>Serial Number</DataTable.Title>
          <DataTable.Title>Location</DataTable.Title>
          <DataTable.Title>Company</DataTable.Title>
          <DataTable.Title>Job Type</DataTable.Title>
        </DataTable.Header> */}
        <DataTableHeader
          sortedColumn={sortedColumn}
          sortedColumnAscending={sortedColumnAscending}
          onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
          headers={[

            { text: 'Description', sortedName: "description", visible: true },
            { text: 'Next Inspection Date', sortedName: "inspection.NextInspectionDate", visible: true },
            { text: 'Serial Number', sortedName: "serialNumber", visible: true },
            { text: 'Location', sortedName: "locationName", visible: true },
            { text: 'Company', sortedName: "companyName", visible: true },
            { text: 'Job Type', sortedName: "jobType", visible: true },
          ]}
        />

        { inspectionResult.data?.list.map((element: any) => {
          return (
            <DataTable.Row key={element.id}>
              
              <DataTable.Cell>{element.description}</DataTable.Cell>
              <DataTable.Cell>{element.nextInspectionDateFormatted}</DataTable.Cell>
              <DataTable.Cell>{element.serialNumber}</DataTable.Cell>
              <DataTable.Cell>{element.locationName}</DataTable.Cell>
              <DataTable.Cell>{element.companyName}</DataTable.Cell>
              <DataTable.Cell>{element.jobType}</DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>

      <DataTable.Pagination
        // page={page}
        // numberOfPages={inspectionResult.data?.pagination.totalPages}
        // onPageChange={(page) => setPage(page)} 
        // label={`${inspectionResult?.data?.pagination.currentStartIndex}-${inspectionResult?.data?.pagination.currentEndIndex} of ${inspectionResult?.data?.pagination.totalCount}`}
        // showFastPaginationControls
        // numberOfItemsPerPage={inspectionResult.data?.pagination.pageSize}
        // optionsLabel={'Rows per page'}
        page={page}
        numberOfPages={inspectionResult.data?.pagination.totalPages}
        onPageChange={(page) => setPage(page)} 
        //@ts-ignore
        itemsPerPage={inspectionResult.data?.pagination.pageSize}
        showFastPagination
        optionsLabel={'Rows per page'}
      />


    </View>
        
      
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

});
