import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert } from 'react-native';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../../constants/GlobalStyle';
import { EvilIcons } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';
import { LoadingIndicator } from '../../../../components/navigation/controls/LoadingIndicator';

import { useIsFocused } from "@react-navigation/native";
import { Picker } from '@react-native-picker/picker';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import { useGetAllCategoriesQuery, useGetAllMasterCategoriesQuery } from '@store/services/api/category-api';
import { useGetAllManufacturersQuery } from '@store/services/api/manufacturer-api';
import { equipmentApi, useCreateEquipmentMutation, useUpdateEquipmentMutation } from '@store/services/api/equipment-api';
import { EquipmentManageControl } from '../../../../../../components/management/EquipmentManageControl';

export default function EquipmentManageScreen({navigation, route}: {navigation: any, route: any}) {
  const [createEquipment, createEquipmentResult] = useCreateEquipmentMutation();
  const [updateEquipment, updateEquipmentResult] = useUpdateEquipmentMutation();
  const [trigger, equipmentResult] = equipmentApi.useLazyGetEquipmentQuery();  

  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: masterCategories } = useGetAllMasterCategoriesQuery(null);
  const { data: categories } = useGetAllCategoriesQuery(null);
  const { data: manufacturers } = useGetAllManufacturersQuery(null);

  React.useEffect(() => {
    if (route.params?.equipmentId != null) {
      trigger(route.params?.equipmentId);
    }
    
  }, [])

  const cancel = () => {
    navigation.goBack();
  }

  const submit = async (equipment) => {
    var result = null;
    console.log(equipment);

    if (equipment.equipmentExtras.length > 0) {
      for (let extra of equipment.equipmentExtras) {
        if(extra.type != 3) {
          if (extra.make == "" || extra.serialNum == "") {
            alert("Enter all fields");
            return;
          }
        }
        
      }
    }


    if (equipment.id != null) {
      result = await updateEquipment(equipment) as any;
    } else {
      console.log("creating equipment");
      console.log(equipment);
      result = await createEquipment(equipment) as any;
    }

    if (result.error) {
      return;
    }

    navigation.goBack();
  }
  

  if (createEquipmentResult.isLoading || updateEquipmentResult.isLoading || equipmentResult.isLoading) {
    return <LoadingIndicator/>
  }

  return ( 
    <View>
      <EquipmentManageControl 
        equipment={equipmentResult.data} companies={companies} locations={locations} masterCategories={masterCategories} 
        categories={categories} manufacturers={manufacturers} onCancel={cancel} onSubmit={submit}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },
});
