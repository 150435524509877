import * as React from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Alert } from 'react-native';
import { EvilIcons, Feather } from '@expo/vector-icons';
import { Controller, useForm } from 'react-hook-form';

import { useIsFocused } from "@react-navigation/native";
import { Picker } from '@react-native-picker/picker';
import { LoadingIndicator } from '../../../components/navigation/controls/LoadingIndicator';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../../constants/GlobalStyle';
import DateTimePicker from '../../../components/navigation/controls/DateTimePicker';
import { jobApi, useCreateJobMutation, useUpdateJobMutation } from '@store/services/api/job-api';
import { useGetAllCompaniesQuery } from '@store/services/api/company-api';
import { useGetAllLocationsQuery } from '@store/services/api/location-api';
import { useGetAllEngineersQuery } from '@store/services/api/user-api';
import moment from 'moment';
import { JobManageControl } from '../../../../../components/management/JobManageControl';


export default function JobsManageScreen({navigation, route}: {navigation: any, route: any}) {
  const [createJob, createJobResult] = useCreateJobMutation();
  const [updateJob, updateJobResult] = useUpdateJobMutation();

  const { data: companies } = useGetAllCompaniesQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: allEngineers } = useGetAllEngineersQuery(null);

  const [trigger, jobResult] = jobApi.useLazyGetJobQuery();

  React.useEffect(() => {
    if (route.params?.jobId != null) {
      trigger(route.params?.jobId);
    }
  }, [])

  const onSubmit = async (data) => {
    var result = null;

    data.jobType = parseInt(data.jobType);

    if (data.id != null) {
      result = await updateJob(data) as any;
    } else {
      result = await createJob(data) as any;
    }

    if (result.error) {
      return;
    }

    navigation.goBack();
  };


  if (createJobResult.isLoading || updateJobResult.isLoading) {
    return <LoadingIndicator/>
  }

  return (
    <View style={styles.container}>
       <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon}  onPress={() => navigation.goBack() }>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{"MANAGE JOB"}</Text>
      </View>

      <JobManageControl companies={companies} locations={locations} allEngineers={allEngineers} job={jobResult?.data} onSubmit={onSubmit} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    padding: 10,
  },

  engineer__picker: {
    flexDirection: 'row',
  },

    engineer__picker__button: {
      backgroundColor: PRIMARY_COLOUR,
      borderWidth: 1,
      borderColor: '#CECECE',
      marginTop: 5,
      marginLeft: 10,
    },

      engineer__picker__button__text: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        color: '#FFF'
      },
      
  engineer__picker__list: {
    margin: 5,
  },

    engineer__row: {
      marginTop: 5,
      marginBottom: 5,
      backgroundColor: '#f5f0f0',
      padding: 5,
      
      flexDirection: 'row',
      alignItems: 'center',
    },

      engineer__row__text: {
        fontFamily: 'OpenSans-Bold',
        padding: 5,
        marignRight: 10,
      }
});
