import * as SQLite from 'expo-sqlite';
import * as FileSystem from 'expo-file-system';
import AsyncStorage from '@react-native-async-storage/async-storage';

export abstract class Database {
  static _database = null;
  

  static getDb() {
    if (this._database == null)
      this._database = SQLite.openDatabase('database.db');

    return this._database;
  }

  static deleteDb() {
    var path = `${FileSystem.documentDirectory}/SQLite/database.db`;
    FileSystem.deleteAsync(path);
    this.resetUpdateTimes();
  }

  static resetUpdateTimes() {
    AsyncStorage.clear();
  }


  constructor() {
    this.initialise();
  }

  abstract initialise() : Promise<void>;
}