import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Feather } from '@expo/vector-icons';
import { Foundation } from '@expo/vector-icons';
import { addBasketInspection, clearBasket, removeBasketInspection } from '@store/slices/basket-slice';
import { ScrollView } from 'react-native-gesture-handler';
import { ReportableService } from '@store/services/reportable/reportable.service';
import * as JSZip from 'jszip';
import { pdfDocEncodingDecode, PDFDocument } from 'pdf-lib'
//@ts-ignore
import { RootState } from '@store/';

export const Basket = (props) => {
  const dispatch = useDispatch();
  const inspections = useSelector((state: RootState) => state.basket.inspections);
  const [showList, setShowList] = useState<boolean>(false);

  useEffect(() => {
    dispatch(clearBasket());
  }, []);

  useEffect(() => {
    if (inspections.length == 0) { setShowList(false); }
  }, [inspections])

  const toggleList = () => {
    if (inspections.length == 0) { return; }
    setShowList(!showList);
  }

  const removeFromBasket = (id) => {
    dispatch(removeBasketInspection(id));
  }

  const clearAllFromBasket = () => {
    dispatch(clearBasket());
  }

  const downloadPdf = async () => {
    console.log("STARTING PDF");
    var promises = [];

    const pdfDoc = await PDFDocument.create();

    var count = 0;
    for (let inspection of inspections) {
      promises.push(new Promise(async (resolve, reject) => {
        var base64 = await getBase64(inspection);
   
        count++;

        var pdf = await PDFDocument.load(base64);
        const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach((page) => pdfDoc.addPage(page));

        resolve(true);
      }));
    }

    Promise.all(promises).then(async () => {
      const mergedPdfFile = await pdfDoc.save();

      var blob = new Blob([mergedPdfFile.buffer]);

      const fileSaver = require('file-saver');
      fileSaver(blob, 'Reports.pdf');
    });
  }

  const downloadZip = () => {
    console.log("STARTING ZIP");
    var promises = [];

    //@ts-ignore
    var zip = new JSZip();

    var count = 0;
    for (let inspection of inspections) {
      promises.push(new Promise(async (resolve, reject) => {
        var base64 = await getBase64(inspection);

        count++;
        var fileName = inspection.serialNumber.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') + " - " + count;
        zip.file(`${fileName}.pdf`, base64, { base64: true });

        resolve(true);
        //var fileName = inspection.serialNum.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
      }));
    }

    Promise.all(promises).then(() => {
      console.log("COMPLETE");
      zip.generateAsync({ type: "blob" }, (update) => {
        // job.status = "SAVING";
        // job.percentage = update.percent;
        //this.reportsProgress = update.percent;
        console.log(update);
      }).then(async (content) => {
        // this.reportsProgress = 0;
        // this.reportsZipping = false;
        const fileSaver = require('file-saver');
        fileSaver(content, 'Reports.zip');
      })
    })
  }

  const getBase64 = async (inspection) => {
    var reportableService = new ReportableService();
    switch (inspection.type) {
      case "breakdown":
        return await reportableService.downloadBreakdown(inspection.id, true);
      case "erstr":
        return await reportableService.downloadERSTR(inspection.id, true);
      case "proofload":
        return await  reportableService.downloadProofLoad(inspection.id, true);
      case "rams":
        return await  reportableService.downloadRAMS(inspection.id, true);
      case "service":
        return await  reportableService.downloadService(inspection.id, true);
      default:
        return await  reportableService.downloadRote(inspection.id, true);

    }
  }


  return (
    <View style={styles.basket__container}>
      <View style={styles.basket}>
        <TouchableOpacity style={styles.title}  onPress={() => toggleList()}>
          <Feather name="shopping-cart" size={24} color="black" />
          <Text style={{ fontWeight: 'bold', marginLeft: 10 }}>Basket</Text>
          <View style={{ flex: 1 }}></View>
          <Text><Text style={{ fontWeight: 'bold' }}>{ inspections.length }</Text><Text> Items</Text></Text>
        </TouchableOpacity>
      </View>

      {showList && 
        <View style={styles.basket__list}>
          <ScrollView>
            { inspections.map((inspection) => {
              return (
                <View style={styles.basket__row} key={inspection.id}>
                  <Text style={{ fontWeight: 'bold' }}>{inspection.serialNumber}</Text>
                  <View style={{ flex: 1 }}></View>
                  <TouchableOpacity onPress={() => removeFromBasket(inspection.id)}>
                    <Feather name="trash" size={20} color="red" />
                  </TouchableOpacity>
                </View>
              )
            })}
          </ScrollView>

          <View style={styles.action__buttons}>
            <TouchableOpacity style={styles.action__button} onPress={downloadZip}>
              <Text style={styles.action__button__text}>ZIP</Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.action__button} onPress={downloadPdf}>
              <Text style={styles.action__button__text}>PDF</Text>
            </TouchableOpacity>

            <TouchableOpacity style={[styles.action__button, styles.action__clear__button]} onPress={clearAllFromBasket}>
              <Text style={styles.action__button__text}>CLEAR</Text>
            </TouchableOpacity>
          </View>
        </View>
      }
    </View>
  )
}

export const BasketIcon = ({inspectionId, serialNumber, type}) => {
  const dispatch = useDispatch();
  const inspections = useSelector((state: RootState) => state.basket.inspections);

  const inBasket = (id) => {
    return inspections.filter(i => i.id == id).length > 0;
  }

  const addToBasket = () => {
    dispatch(addBasketInspection({
      id: inspectionId,
      serialNumber: serialNumber,
      type: type
    }))
  }

  const removeFromBasket = () => {
    dispatch(removeBasketInspection(inspectionId));
  }

  
  if (inBasket(inspectionId)) {
    return (
      <TouchableOpacity onPress={() => removeFromBasket()}>
        <Foundation name="minus" size={24} color="black" />
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity onPress={() => addToBasket()}>
      <Foundation name="plus" size={24} color="black" />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  basket__container: {
    position: 'relative',
    zIndex: 999998
  },

  basket: {
    backgroundColor: '#FFF',
    padding: 10,
    marginBottom: 10,
    width: '300px',

    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#CECECE'
  },

    title: {
      flexDirection: 'row',
      alignItems: 'center',
    },

  basket__list: {
    position: 'absolute',
    top: 50,
    left: 0,
    zIndex: 9999999,
    backgroundColor: '#FFF',
    width: '300px',
    maxHeight: '300px',
    borderWidth: 1,
    borderColor: '#CECECE',
  },

    basket__row: {
      backgroundColor: '#f5f2f2',
      padding: 10,
      margin: 5,

      flexDirection: 'row',
      alignItems: 'center'
    },

  action__buttons: {
    flexDirection: 'row',
    borderTopWidth: 2,
    borderTopColor: '#CECECE',
    paddingTop: 5,
    paddingBottom: 5
  },

    action__button: {
      flex: 1,
      backgroundColor: 'black',
      margin: 5,
      borderRadius: 5,
      textAlign: 'center',
    },

      action__button__text: {
        color: '#FFF',
        padding: 5,
      },

    action__clear__button: {
      backgroundColor: 'red'
    }
});