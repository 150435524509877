import { baseApi, PagedTableDto } from './base-api'

export const erstrApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getEngineerReports: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `engineerreport/geterstrinspections?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
  }),
  overrideExisting: true,
})

export const { 
  useGetEngineerReportsQuery,
} = erstrApi