import { View, StyleSheet, Text, TextInput, Modal, TouchableOpacity } from 'react-native';
import { colours } from '../../../../../../config';
import { useState, useEffect } from 'react';
import { DateTimePickerAndroid } from '@react-native-community/datetimepicker';
import moment from 'moment';
import { DatePicker } from '@app/components/DatePicker';



export const Timesheets = ({ timesheets, onUpdated }: { timesheets: any, onUpdated: any }) => {
  const [timesheetsList, setTimesheetsList] = useState(timesheets);
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [timeValue, setTimeValue] = useState();

  useEffect(() => {
    setTimesheetsList(timesheets);
  }, [timesheets])

  const updateHours = (hours) => {
    setSelectedTimesheet(prev => ({...prev, hours: hours})); 
  }

  const updateHoursHalf = (hours) => {
    setSelectedTimesheet(prev => ({...prev, hoursHalf: hours})); 
  }

  const updateHoursDouble = (hours) => {
    setSelectedTimesheet(prev => ({...prev, hoursDouble: hours})); 
  }

  const updateSelectedTimesheet = (timesheet) => {
    setSelectedTimesheet(timesheet);
  }

  const save = () => {
    var idx = timesheetsList.indexOf(timesheetsList.find(t => t.day == selectedTimesheet.day));
    timesheetsList[idx] = selectedTimesheet;
    setTimesheetsList([...timesheetsList]);
    onUpdated(timesheetsList);

    setSelectedTimesheet(null);
  }

  return (
    <View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={selectedTimesheet != null}>
          <View style={styles.modal__block}>
            <View style={styles.modal}>
              <View style={styles.row}>
                {/* <TouchableOpacity style={styles.date__time} onPress={() => updateToSite()}> */}
                <TouchableOpacity style={styles.date__time}>
                  <Text style={styles.date__label}>Depart Home</Text>
                  {/* <Text style={styles.date__value}>{selectedTimesheet?.toSite}</Text> */}
                  <DatePicker value={selectedTimesheet?.toSite} onChanged={(date) => setSelectedTimesheet(prev => ({...prev, toSite: moment(date).format("HH:mm")}))}/>
                </TouchableOpacity>

                <TouchableOpacity style={styles.date__time}>
                  <Text style={styles.date__label}>Arrived</Text>
                  <DatePicker value={selectedTimesheet?.arrived} onChanged={(date) => setSelectedTimesheet(prev => ({...prev, arrived: moment(date).format("HH:mm")}))}/>
                </TouchableOpacity>
              </View>

              <View style={styles.row}>
                {/* <TouchableOpacity style={styles.date__time} onPress={() => updateDepart()}> */}
                <TouchableOpacity style={styles.date__time}>
                  <Text style={styles.date__label}>Depart</Text>
                  <DatePicker value={selectedTimesheet?.depart} onChanged={(date) => setSelectedTimesheet(prev => ({...prev, depart: moment(date).format("HH:mm")}))}/>
                </TouchableOpacity>

                {/* <TouchableOpacity style={styles.date__time} onPress={() => updateHome()}> */}
                <TouchableOpacity style={styles.date__time}>
                  <Text style={styles.date__label}>Home</Text>
                  <DatePicker value={selectedTimesheet?.home} onChanged={(date) => setSelectedTimesheet(prev => ({...prev, home: moment(date).format("HH:mm")}))}/>
                </TouchableOpacity>
              </View>

              <View style={styles.row}>
                <View style={styles.date__time}>
                  <Text style={styles.date__label}>Normal Hours</Text>
                  <TextInput keyboardType={'decimal-pad'} style={styles.date__value} onChangeText={(text) => updateHours(text)}>{selectedTimesheet?.hours}</TextInput>
                </View>
                <View style={styles.date__time}>
                  <Text style={styles.date__label}>Time & Half Hours</Text>
                  <TextInput keyboardType={'decimal-pad'} style={styles.date__value} onChangeText={(text) => updateHoursHalf(text)}>{selectedTimesheet?.hoursHalf}</TextInput>
                </View>
                <View style={styles.date__time}>
                  <Text style={styles.date__label}>Double Hours</Text>
                  <TextInput keyboardType={'decimal-pad'} style={styles.date__value} onChangeText={(text) => updateHoursDouble(text)}>{selectedTimesheet?.hoursDouble}</TextInput>
                </View>
              </View>


              <View style={{ flex: 1}}></View>

              <TouchableOpacity style={styles.proceed__button} onPress={() => save()}>
                <Text style={styles.proceed__button__text}>SAVE</Text>
              </TouchableOpacity>
            </View>
          </View>
      </Modal>

      <View style={styles.row}>
        <Text style={styles.header}></Text>
        <Text style={styles.header}>Depart Home</Text>
        <Text style={styles.header}>Arrived</Text>
        <Text style={styles.header}>Depart</Text>
        <Text style={styles.header}>Home</Text>
        <Text style={styles.header}>Hrs</Text>
        <Text style={styles.header}>Time & Half Hrs</Text>
        <Text style={styles.header}>Double Hours</Text>
      </View>

      { timesheetsList != null && timesheetsList.length > 0 && timesheetsList.map((timesheet: any, index: number) => {
        return (
          <TouchableOpacity key={timesheet.day} style={styles.row} onPress={() => updateSelectedTimesheet(timesheet)}>
            <Text style={styles.column}>{timesheet.day}</Text>
            <Text style={styles.column}>{timesheet.toSite}</Text>
            <Text style={styles.column}>{timesheet.arrived}</Text>
            <Text style={styles.column}>{timesheet.depart}</Text>
            <Text style={styles.column}>{timesheet.home}</Text>
            <Text style={styles.column}>{timesheet.hours}</Text>
            <Text style={styles.column}>{timesheet.hoursHalf}</Text>
            <Text style={styles.column}>{timesheet.toSite == null || timesheet.toSite == "" ? 'Tap to set' : timesheet.hoursDouble}</Text>
          </TouchableOpacity>
        )
      })}
    </View>
  )
}


const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginBottom: 5
  },

    header: {
      flex: 1,
      textAlign: 'center',
      padding: 10,
      backgroundColor: colours.primary,
      color: '#FFF',
      fontSize: 14,
      marginLeft: 2.5,
      marginRight: 2.5,
      fontFamily: 'OpenSans-Bold',
    },

    column: {
      flex: 1,
      textAlign: 'center',
      padding: 10,
      backgroundColor: '#FFF',
      fontSize: 14,
      marginLeft: 2.5,
      marginRight: 2.5,
      fontFamily: 'OpenSans-Regular',
    },

  modal__block: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },

    modal: {
      backgroundColor: '#FFF',
      padding: 10,
      width: 600,
      borderRadius: 5,

      flexDirection: 'column',
    },

  date__time: {
    flex: 1,
    textAlign: 'center',
    margin: 10,
  },

  date__value: {
    borderBottomWidth: 1,
    borderBottomColor: '#CECECE',
    fontSize: 24,
    marginTop: 10,
    paddingBottom: 5,
    textAlign: 'center'
  },

    date__label: {
      fontWeight: 'bold',
      fontSize: 14,
      textAlign: 'center',
    },

  proceed__button: {
    padding: 10,
    backgroundColor: colours.primary,
    marginTop: 20,
    borderRadius: 7,
    height: 45,


    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

    proceed__button__text: {
      fontFamily: 'OpenSans-Bold',
      color: '#FFF',
      textAlign: 'center',
      fontSize: 18
    },
});