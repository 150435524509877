import * as SQLite from 'expo-sqlite';
import { Database } from './database';
import uuid from 'react-native-uuid';

export class InspectionDatabase extends Database {
  TABLE_NAME = "Inspections";
  TABLE_NAME_IMAGES = "InspectionImages";

  initialise() : Promise<void> {
    return new Promise((resolve, reject) => {
      var sql = `CREATE TABLE IF NOT EXISTS Inspections ( 
        id TEXT PRIMARY KEY,
        inspectionDate TEXT,
        nextInspectionDate TEXT,

        firstExamination INT,
        installedCorrectly INT,

        defects TEXT,
        immediateToPersons INT,
        becomeDangerToPersons INT,
        repairDate TEXT,
        repair TEXT,

        identification TEXT,
        particulars TEXT,
        safeForUse INT,

        equipmentId TEXT,
        engineerId TEXT,
        jobId TEXT,
        purposeId TEXT,
        colourId TEXT,
        missing INT
      )`;

      var sqlImages = `CREATE TABLE IF NOT EXISTS InspectionImages (
        id TEXT PRIMARY KEY,
        inspectionId TEXT,
        base64 TEXT
      )`

      Database.getDb().transaction((tx) => {
        tx.executeSql(sql);
        tx.executeSql(sqlImages);
      }, (error) => {
        console.log(error)
        reject();
      }, () => {
        resolve();
      })
    });
  }

  addMissingInspection(inspectionDate, equipmentId, engineerId, jobId, purposeId, colourId) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME} (
            id, inspectionDate, nextInspectionDate, safeForUse, equipmentId, engineerId, jobId, purposeId, colourId, missing
          )
          VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, 1)
        `

        var inspectionId = uuid.v4();
        tx.executeSql(sql, [
          inspectionId, inspectionDate, inspectionDate, false, equipmentId, engineerId, jobId, purposeId, colourId
        ]);

        sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? and equipmentId = ?";
        tx.executeSql(sql, [jobId, equipmentId]);
      }, (error) => {
        console.log("ERROR");
        console.log(error);
        reject();
      }, () => {
        console.log("SUCCESS");
        resolve();
      })
    });
  }

  addInspection(inspectionDate: any, nextInspectionDate: any, firstExamination: any, installedCorrectly: any, defects: any,
    immediateToPersons: any, becomeDangerToPersons: any, repairDate: any, repair: any, identification: any, particulars: any,
    safeForUse: any, equipmentId: any, engineerId: any, jobId: any, purposeId: any, colourId: any, missing: any, images: any) : Promise<void> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `
          INSERT OR REPLACE INTO ${this.TABLE_NAME} (
            id, inspectionDate, nextInspectionDate, firstExamination, installedCorrectly, defects, immediateToPersons, becomeDangerToPersons,
            repairDate, repair, identification, particulars, safeForUse, equipmentId, engineerId, jobId,
            purposeId, colourId, missing
          )
          VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
        `

        var inspectionId = uuid.v4();

        tx.executeSql(sql, [
          inspectionId, inspectionDate, nextInspectionDate, firstExamination, installedCorrectly, defects, immediateToPersons, becomeDangerToPersons,
          repairDate, repair, identification, particulars, safeForUse, equipmentId, engineerId, jobId,
          purposeId, colourId, missing
        ]);

        if (images.length > 0) {
          sql = `INSERT INTO ${this.TABLE_NAME_IMAGES} (id, inspectionId, base64) VALUES (?, ?, ?)`;
          for (let image of images) {
            tx.executeSql(sql, [uuid.v4(), inspectionId, image]);
          }
        }

        //Update this asset to be completed 
        sql = "UPDATE JobAssets SET completed = 1, toBeSynced = 1 WHERE jobId = ? and equipmentId = ?";
        tx.executeSql(sql, [jobId, equipmentId]);

        console.log("UPDATED JOB Assets - JobId: " + jobId + " EquipId: " + equipmentId);
      }, (error) => {
        console.log("ERROR");
        console.log(error);
        reject();
      }, () => {
        resolve();
      })
    })
  }

  deleteInspection(inspectionId) : Promise<boolean> {
    return new Promise((resolve, reject) => {
      Database.getDb().transaction((tx) => {
        var sql = `DELETE FROM Inspections WHERE id = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => {});

        sql = `DELETE FROM InspectionImages WHERE inspectionId = ?`;
        tx.executeSql(sql, [inspectionId], (_, { rows: { _array } }) => {});
        
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(true);
      })
    });
  }

  getForSync() : Promise<any> {
    return new Promise((resolve, reject) => {
      var data = [];
      Database.getDb().transaction((tx) => {
        var sql = `
          SELECT * FROM Inspections
        `;

        tx.executeSql(sql, [], (_, { rows: { _array } }) => {
          data = _array;
          for (let inspection of data) {
            sql = `SELECT base64 FROM ${this.TABLE_NAME_IMAGES} WHERE inspectionId = ?`;
            tx.executeSql(sql, [inspection.id], (_, { rows: { _array } }) => {
              inspection.images = [];
              for (let image of _array) {
                inspection.images.push(image.base64)
              }
            });
          }
        });
      }, (error) => {
        console.log(error);
        reject();
      }, () => {
        resolve(data);
      })
    });
  }
}