import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, TextInput } from "react-native";
import { MaterialCommunityIcons, AntDesign, EvilIcons, MaterialIcons, FontAwesome, Feather  } from '@expo/vector-icons';
import { Controller, useForm } from "react-hook-form";
import GlobalStyle, { PRIMARY_COLOUR } from "../../constants/GlobalStyle";
import { Picker } from "@react-native-picker/picker";
import { colours } from "../../config";
import Checkbox from "expo-checkbox";



export function EquipmentExtra({title, extras, onAddExtra, onRemoveExtra}) {
  const [extrasList, setExtrasList] = useState(extras);

  useEffect(() => {
    setExtrasList(extras);
    
  }, [extras])

  const updateSerial = (extra, text) => {
    extra.serialNum = text;
    setExtrasList([...extrasList]);
  }

  const updateSerial2 = (extra, text) => {
    extra.serialNum2 = text;
    setExtrasList([...extrasList]);
  }

  const updateMake = (extra, text) => {
    extra.make = text;
    setExtrasList([...extrasList]);
  }

  const updateMake2 = (extra, text) => {
    extra.make2 = text;
    setExtrasList([...extrasList]);
  }

  const updateType = (extra, text) => {
    extra.type = text;
    setExtrasList([...extrasList]);
  }

  const updateType2 = (extra, text) => {
    extra.type2 = text;
    setExtrasList([...extrasList]);
  }
  const updateDiameter = (extra, text) => {
    extra.diameter = text;
    setExtrasList([...extrasList]);
  }

  const updateDescription = (extra, text) => {
    extra.description = text;
    setExtrasList([...extrasList]);
  }

  const updateLength = (extra, text) => {
    extra.length = text;
    setExtrasList([...extrasList]);
  }

  return (
    <View style={styles.equipment__extra__block}>
      <View style={styles.equipment__extra__title__block}>
        <Text style={styles.equipment__extra__title}>{title}</Text>
        <TouchableOpacity onPress={() => onAddExtra()}>
          <Text style={styles.equipment__extra__title__add__button__text}>Add</Text>
        </TouchableOpacity>
      </View>

      <View>
        { extrasList.map((value, index) => {
          return (
            <View key={index} style={{ flexDirection: 'row', padding: 5, flex: 1 }}>
              {value.equipmentExtraType != 3 && 
              <View style={{ marginRight: 5, flex: 1 }}>
                <Text style={GlobalStyle.form__column__text}>Serial Number (*)</Text>
                <TextInput 
                  style={GlobalStyle.form__column__input} 
                  onChangeText={(text) => updateSerial(value, text)}
                  value={value.serialNum}>
                </TextInput>
              </View>}
                
              {value.equipmentExtraType != 3 && 
              <View style={{ marginRight: 5, flex: 1 }}>
                <Text style={GlobalStyle.form__column__text}>Make (*)</Text>
                <TextInput 
                  style={GlobalStyle.form__column__input} 
                  onChangeText={(text) => updateMake(value, text)}
                  value={value.make}>
                </TextInput>
              </View>
              }
              {value.equipmentExtraType != 3 && 
              <View style={{ marginRight: 5, flex: 1 }}>
                <Text style={GlobalStyle.form__column__text}>Type (*)</Text>
                <TextInput 
                  style={GlobalStyle.form__column__input} 
                  onChangeText={(text) => updateType(value, text)}
                  value={value.type}>
                </TextInput>
              </View>
              }
              {value.equipmentExtraType != 3 && 
              <View style={{ marginRight: 5, flex: 1 }}>
                <Text style={GlobalStyle.form__column__text}>Serial Number 2 (*)</Text>
                <TextInput 
                  style={GlobalStyle.form__column__input} 
                  onChangeText={(text) => updateSerial2(value, text)}
                  value={value.serialNum2}>
                </TextInput>
              </View>}
              {value.equipmentExtraType != 3 && 

              <View style={{ marginRight: 5, flex: 1 }}>
                <Text style={GlobalStyle.form__column__text}>Make 2 (*)</Text>
                <TextInput 
                  style={GlobalStyle.form__column__input} 
                  onChangeText={(text) => updateMake2(value, text)}
                  value={value.make2}>
                </TextInput>
              </View>}
              {value.equipmentExtraType != 3 && 

              <View style={{ marginRight: 5, flex: 1 }}>
                <Text style={GlobalStyle.form__column__text}>Type 2 (*)</Text>
                <TextInput 
                  style={GlobalStyle.form__column__input} 
                  onChangeText={(text) => updateType2(value, text)}
                  value={value.type2}>
                </TextInput>
              </View>}
                 
              {value.equipmentExtraType == 3 && 
              <View style={{ marginRight: 5, flex: 1 }}>
                <Text style={GlobalStyle.form__column__text}>Diameter</Text>
                <TextInput 
                  style={GlobalStyle.form__column__input} 
                  onChangeText={(text) => updateDiameter(value, text)}
                  value={value.diameter}>
                </TextInput>
              </View>}
              {value.equipmentExtraType == 3 && 
              <View style={{ marginRight: 5, flex: 1 }}>
                <Text style={GlobalStyle.form__column__text}>Description</Text>
                <TextInput 
                  style={GlobalStyle.form__column__input} 
                  onChangeText={(text) => updateDescription(value, text)}
                  value={value.description}>
                </TextInput>
              </View>}
              {value.equipmentExtraType == 3 && 
              <View style={{ marginRight: 5, flex: 1 }}>
                <Text style={GlobalStyle.form__column__text}>Length</Text>
                <TextInput 
                  style={GlobalStyle.form__column__input} 
                  onChangeText={(text) => updateLength(value, text)}
                  value={value.length}>
                </TextInput>
              </View>}

              <TouchableOpacity onPress={() => onRemoveExtra(value)} style={{ flex: 1, backgroundColor: '#ebebeb', padding: 10, alignItems: 'center', alignSelf: 'flex-end' }}>
                <Text style={{color: '#000'}}>Remove</Text>
              </TouchableOpacity>
            </View>
          )
        })}
      </View>
    </View>
  )
}

export const EquipmentManageControl = ({ equipment, companies, locations, masterCategories, categories, manufacturers, onCancel, onSubmit, hideMenu = false, disablePickers = false }) => {
  const [selectedMasterCategoryName, setSelectedMasterCategoryName] = React.useState<string>("");
  const [selectedCategoryName, setSelectedCategoryName] = React.useState<string>("");

const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      description: "",
      serialNumber: "",
      assetNumber: "",
      dateOfManufacture: "",
      proofLoad: "",
      swl: "",
      qr: "",
      companyId: "",
      locationId: "",
      categoryId: "",
      manufacturerId: "",

      numberOfHoists: "0",
      hoistType: "",
      abusNo: "",
      yearOfCommissioning: "",
      masterCategoryId: "",

      doc: false,
      transposedStandards: "",
      nationalStandards: "",
      dateOfFirstUse: "",
      scrapped: false,

      isCraneRadio: false,

      equipmentExtras: []
      
    }
  });

  const companyId = watch("companyId");
  const masterCategoryId = watch("masterCategoryId");
  const categoryId = watch("categoryId");
  const equipmentExtras = watch("equipmentExtras");
  const doc = watch("doc");
  const isCraneRadio = watch("isCraneRadio");

  useEffect(() => {
    if (masterCategories == null || masterCategoryId == null || masterCategoryId == "") {
      setSelectedMasterCategoryName("");
      return;
    }
    var masterCategory = masterCategories.find(c => c.id == masterCategoryId);
    setSelectedMasterCategoryName(masterCategory.name);
  }, [masterCategoryId])

  useEffect(() => {
    if (categories == null || categoryId == null || categoryId == "") {
      setSelectedCategoryName("");
      return;
    }

    var category = categories.find(c => c.id == categoryId);
    setSelectedCategoryName(category.name);
  }, [categoryId]);



  useEffect(() => {
    if (equipment != null) {
      var data = equipment;
    
      setValue("id", data.id);
      setValue("description", data.description);
      setValue("serialNumber", data.serialNumber);
      setValue("assetNumber", data.assetNumber);
      setValue("dateOfManufacture", data.dateOfManufacture);
      setValue("proofLoad", data.proofLoad);
      setValue("swl", data.swl);
      setValue("qr", data.qr);
      setValue("companyId", data.companyId);
      setValue("locationId", data.locationId);
      setValue("masterCategoryId", data.masterCategoryId);
      setValue("categoryId", data.categoryId);
      setValue("manufacturerId", data.manufacturerId);
      setValue("doc", data.doc);
      setValue("transposedStandards", data.transposedStandards);
      setValue("nationalStandards", data.nationalStandards);
      setValue("dateOfFirstUse", data.dateOfFirstUse);
      setValue("equipmentExtras", data.equipmentExtras == null ? [] : data.equipmentExtras);

      setValue("numberOfHoists", data.numberOfHoists != null ? data.numberOfHoists.toString() : 0);
      setValue("hoistType", data.hoistType);
      setValue("abusNo", data.abusNo);
      setValue("yearOfCommissioning", data.yearOfCommissioning);
      setValue("isCraneRadio", data.isCraneRadio);
      console.log(data);
    }
  }, [equipment]);

  const addEquipmentExtra = (type) => {
    var equipExtras = getValues("equipmentExtras");
    console.log("equipExtras");
    console.log(equipExtras);
    if(type == 3) {
      equipExtras.push({
        description: "",
        diameter: "",
        length: "",
        equipmentExtraType: type
      });

    } else {
      equipExtras.push({
        serialNum: "",
        make: "",
        equipmentExtraType: type
      });

    }


    setValue("equipmentExtras", equipExtras)
  }

  const removeEquipmentExtra = (extra) => {
    var equipExtras = getValues("equipmentExtras");
    equipExtras.splice(equipExtras.indexOf(extra), 1);

    setValue("equipmentExtras", equipExtras)
  }

  const submit = async (data) => {
    console.log("submit");
    console.log(data);
    onSubmit(data, data.id == null);
  };

  return (
    <View style={styles.container}>
      {!hideMenu && <View style={GlobalStyle.sub__title}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon}  onPress={() => onCancel() }>
          <EvilIcons name="arrow-left" size={24} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
        <Text style={GlobalStyle.sub__title__text}>{getValues("id") == null ? "CREATE EQUIPMENT" : "UPDATE EQUIPMENT"}</Text>
      </View> 
      }
      
      <View style={GlobalStyle.form}>
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="companyId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Company (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    itemStyle={{ height: 50 }}
                    enabled={!disablePickers}>

                    <Picker.Item label="Please Select" value="" />

                    { companies != null && companies.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.companyId && <Text style={GlobalStyle.form__input__validation}>Company is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="locationId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Location (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    enabled={!disablePickers}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    { locations != null && companyId != "" && locations.filter(l => l.companyId == companyId).map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.locationId && <Text style={GlobalStyle.form__input__validation}>Location is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="manufacturerId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Manufacturer (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    { manufacturers != null && manufacturers.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.manufacturerId && <Text style={GlobalStyle.form__input__validation}>Manufacturer is required.</Text>}
          </View>
        </View>


        {/* ##### ROW ##### */}
        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="serialNumber"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Serial Number (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.serialNumber && <Text style={GlobalStyle.form__input__validation}>Serial Number is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="description"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Description (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />

            {errors.description && <Text style={GlobalStyle.form__input__validation}>Description is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="assetNumber"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Asset Number</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>
        </View>

        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="dateOfManufacture"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Date Of Manufacture</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    keyboardType={"number-pad"}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="proofLoad"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Proof Load</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="swl"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Safe Working Load (*)</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
            
            {errors.swl && <Text style={GlobalStyle.form__input__validation}>SWL is required.</Text>}
          </View>
        </View>

        <View style={GlobalStyle.form__row}>
          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="masterCategoryId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Master Category (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    { masterCategories != null && masterCategories.map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.masterCategoryId && <Text style={GlobalStyle.form__input__validation}>Master Category is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="categoryId"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>Category (*)</Text>
                  <Picker
                    style={GlobalStyle.form__column__input}
                    selectedValue={value}
                    onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                    itemStyle={{ height: 50 }}>

                    <Picker.Item label="Please Select" value="" />

                    { categories != null && masterCategoryId != "" && categories.filter(l => l.masterCategoryId == masterCategoryId).map((value, index) => {
                      return (
                        <Picker.Item key={value.id} label={value.name} value={value.id} />
                      )
                    })}
                  </Picker>
                </View>
              )}
            />

            {errors.categoryId && <Text style={GlobalStyle.form__input__validation}>Category is required.</Text>}
          </View>

          <View style={GlobalStyle.form__column}>
            <Controller
              control={control}
              name="qr"
              render={({ field: { onChange, onBlur, value } }) => (
                <View>
                  <Text style={GlobalStyle.form__column__text}>QR</Text>
                  <TextInput 
                    style={GlobalStyle.form__column__input} 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}>
                  </TextInput>
                </View>
              )}
            />
            
          </View>
        </View>

        {(selectedMasterCategoryName == "Machine" || selectedMasterCategoryName == "Lifting") && 
          <View>
            <View style={GlobalStyle.form__row}>
            <View style={GlobalStyle.form__column}>
                <Controller
                  control={control}
                  name="doc"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>DOC</Text>
                      <Checkbox 
                        style={GlobalStyle.form__column__input} 
                        onValueChange={onChange}
                        value={value}>
                      </Checkbox>
                    </View>
                  )}
                />

                {errors.transposedStandards && <Text style={GlobalStyle.form__input__validation}>Required.</Text>}
              </View>

              {doc == true && 
                <View style={GlobalStyle.form__column}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="transposedStandards"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View>
                        <Text style={GlobalStyle.form__column__text}>The transposed harmonised standards used (specify): (*)</Text>
                        <TextInput 
                          style={GlobalStyle.form__column__input} 
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}>
                        </TextInput>
                      </View>
                    )}
                  />

                  {errors.transposedStandards && <Text style={GlobalStyle.form__input__validation}>Required.</Text>}
                </View>
              }

              {doc == true && 
                <View style={GlobalStyle.form__column}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="nationalStandards"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View>
                        <Text style={GlobalStyle.form__column__text}>The national standards and any technical specifications used (specify): (*)</Text>
                        <TextInput 
                          style={GlobalStyle.form__column__input} 
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}>
                        </TextInput>
                      </View>
                    )}
                  />

                  {errors.transposedStandards && <Text style={GlobalStyle.form__input__validation}>Required.</Text>}
                </View>
              }
            </View>
          </View>
        }

        {(selectedMasterCategoryName == "PPE") && 
          <View>
            <View style={GlobalStyle.form__row}>
              <View style={GlobalStyle.form__column}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="dateOfFirstUse"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Date of First Use (*)</Text>
                      <TextInput 
                        style={GlobalStyle.form__column__input} 
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}>
                      </TextInput>
                    </View>
                  )}
                />

                {errors.transposedStandards && <Text style={GlobalStyle.form__input__validation}>Required.</Text>}
              </View>
            </View>
          </View>
        }

        {selectedMasterCategoryName == "Machine" && 
          <View>
            <View style={GlobalStyle.form__row}>
              <View style={GlobalStyle.form__column}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="numberOfHoists"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Number of Hoists (*)</Text>
                      <Picker
                        style={GlobalStyle.form__column__input}
                        selectedValue={value}
                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                        itemStyle={{ height: 50 }}>

                        <Picker.Item label="Please Select" value="0" />
                        <Picker.Item label="1" value="1" />
                        <Picker.Item label="2" value="2" />
                      </Picker>
                    </View>
                  )}
                />

                {errors.numberOfHoists && <Text style={GlobalStyle.form__input__validation}>Number of Hoists is required.</Text>}
              </View>

              <View style={GlobalStyle.form__column}>
                <Controller
                  control={control}
                  name="hoistType"
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Hoist Type (*)</Text>
                      <TextInput 
                        style={GlobalStyle.form__column__input} 
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}>
                      </TextInput>
                    </View>
                  )}
                />

                {errors.hoistType && <Text style={GlobalStyle.form__input__validation}>Hoist Type is required.</Text>}
              </View>

              <View style={GlobalStyle.form__column}>
                <Controller
                  control={control}
                  name="yearOfCommissioning"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Year Of Commissioning</Text>
                      <TextInput 
                        style={GlobalStyle.form__column__input} 
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}>
                      </TextInput>
                    </View>
                  )}
                />
              </View>

              {/* <View style={GlobalStyle.form__column}>
                <Controller
                  control={control}
                  name="abusNo"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View>
                      <Text style={GlobalStyle.form__column__text}>Abus No</Text>
                      <TextInput 
                        style={GlobalStyle.form__column__input} 
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}>
                      </TextInput>
                    </View>
                  )}
                />
              </View> */}
            </View>

            <View style={GlobalStyle.form__row}>
              { selectedCategoryName == "Crane" && 
                <View style={GlobalStyle.form__column}>
                  <Controller
                    control={control}
                    name="isCraneRadio"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View>
                        <Text style={GlobalStyle.form__column__text}>Radio or Pendant ({isCraneRadio ? 'Radio' : 'Pendant'})</Text>
                        <Checkbox 
                          style={GlobalStyle.form__column__input} 
                          onValueChange={onChange}
                          value={isCraneRadio ? true : false}>
                        </Checkbox>
                      </View>
                    )}
                  />
                </View>
              }
            </View>
          </View>
        }
        {selectedMasterCategoryName != null && selectedMasterCategoryName == "Machine" && 
          <View style={{ marginTop: 10 }}>
            <View style={styles.equipment__extra__list}>
              <View>
                <EquipmentExtra 
                  title={'Hoist(s)'} 
                  extras={equipmentExtras.filter(e => e.equipmentExtraType == 0)} 
                  onAddExtra={() => addEquipmentExtra(0)} 
                  onRemoveExtra={(extra) => removeEquipmentExtra(extra)} />
              </View>

              <View>
                <EquipmentExtra 
                  title={'Motors Cross Travel(s)'} 
                  extras={equipmentExtras.filter(e => e.equipmentExtraType == 1)} 
                  onAddExtra={() => addEquipmentExtra(1)} 
                  onRemoveExtra={(extra) => removeEquipmentExtra(extra)} />
              </View>

              <View>
                <EquipmentExtra 
                  title={'Motors Long Travel(s)'} 
                  extras={equipmentExtras.filter(e => e.equipmentExtraType == 2)} 
                  onAddExtra={() => addEquipmentExtra(2)} 
                  onRemoveExtra={(extra) => removeEquipmentExtra(extra)} />
              </View>

              <View>
                <EquipmentExtra 
                  title={'Hoist Load Rope'} 
                  extras={ equipmentExtras.filter(e => e.equipmentExtraType == 3)} 
                  onAddExtra={() => addEquipmentExtra(3)} 
                  onRemoveExtra={(extra) => removeEquipmentExtra(extra)} />
              </View>
            </View>
          </View>
        }

        <TouchableOpacity style={GlobalStyle.form__submit__button} onPress={handleSubmit(submit)}>
          <Text style={GlobalStyle.form__submit__button__text}>SAVE</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}


const styles = StyleSheet.create({
  container: {

  },

  equipment__extra__list: {
    
  }, 
    equipment__extra__block: {
      flex: 1,
      margin: 5,
      marginBottom: 20
    },
      equipment__extra__title__block: {
        backgroundColor: colours.primary,
        padding: 10,
        flexDirection: 'row'
      },
        equipment__extra__title: {
          color: '#FFF',
          fontWeight: 'bold',
          flex: 1
        },

        equipment__extra__title__add__button__text: {
          color: '#FFF'
        }


});