import { createNativeStackNavigator } from '@react-navigation/native-stack';
import TabletUsersList from './TabletUsersList';
import TabletUserManage from './TabletUsersManage';


const Stack = createNativeStackNavigator();

export default function TabletUsers({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={TabletUsersList} options={{ headerShown: false, title: 'Tablet Users'  }} />
      <Stack.Screen name="manage" component={TabletUserManage} options={{ headerShown: false, title: 'Users Manage'}}  />
    </Stack.Navigator>
  )
};