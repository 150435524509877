import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput, TouchableOpacity, ActivityIndicator, Alert } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@store/index';
import { JobDatabase } from '@store/services/database/job-database';
import { FlashList } from "@shopify/flash-list";
import { TitleBar } from '../../components/TitleBar';
import { AntDesign, MaterialCommunityIcons, Entypo } from '@expo/vector-icons';
import moment from "moment";
import { setJobData } from '../../../../store/slices/job-slice';
import { useIsFocused } from '@react-navigation/native';
import { clearInspectionData } from '../../../../store/slices/rote-inspection-slice';
import { colours } from '../../../../config';
import { InspectionDatabase } from '@store/services/database/inspection-database';
import { clearBreakdownData } from '@store/slices/breakdown-slice';

export default function JobAssetsScreen({navigation, route}: {navigation: any, route: any}) {
  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const job = useSelector((state: RootState) => state.job);

  const [jobAssets, setJobAssets] = useState([]);

  const jobDatabase = new JobDatabase();

  const loadJobAssets = async (searchQuery = null) => {
    var jobAssets = await jobDatabase.getJobAssets(job.jobId, searchQuery);
    setJobAssets(jobAssets);
  }

  const selectAsset = (viewModel) => {
    dispatch(clearInspectionData());
    dispatch(clearBreakdownData());
    dispatch(setJobData({ equipmentId: viewModel.id, categoryId: viewModel.categoryId }))
    navigation.navigate("EquipmentDetails");
  }

  const rams = () => {
    if(job.ramsId != null) {
      Alert.alert('Overwrite RAMS', 'Are you sure you want to overwrite these RAMS?', [        
        { text: 'No', style: 'cancel' },
        { text: 'Yes', onPress: () => navigation.navigate("RAMS")}
      ]);
      return;
    } else {
      navigation.navigate("RAMS");
    }

  }

  const erstr = () => {
    navigation.navigate("ERSTR");
  }

  const addEquipment = () => {
    navigation.navigate("EquipmentManage", { equipmentId: null })
  }

  const complete = async (promptEngineerReport: boolean = true) => {
    // Prompt for erstr
    if (job.engineerReportId == null && promptEngineerReport) {
      Alert.alert('Complete Job', 'Do you want to carry out your engineer report now?', [        
        { text: 'No', style: 'cancel', onPress: () => complete(false)  },
        { text: 'Yes', onPress: () => erstr() }
      ]);
      return;
    }

    if (jobAssets.length == 0) {
      await jobDatabase.completeJob(job.jobId);
      Alert.alert("Success", "Successfully completed Job");
      navigation.navigate('Dashboard');
      return;
    }

    // if (job.jobType == 0) {
    //   Alert.alert('Complete Job', 'Completing this job will set the remaining assets as missing. Are you sure you want to do this?', [        
    //     { text: 'No', style: 'cancel', onPress: async () => {
          
    //     }},
    //     { text: 'Yes', onPress: async () => {
    //       var inspectionDatabase = new InspectionDatabase();
    //       for (let jobAsset of jobAssets) {
    //         await inspectionDatabase.addMissingInspection(moment().format("YYYY-MM-DD"), jobAsset.id, job.engineerId, job.jobId, job.purposeId, job.colourId);
    //       }

    //       await jobDatabase.completeJob(job.jobId);
    //       Alert.alert("Success", "Successfully completed Job");
    //       navigation.navigate('Dashboard');
    //     }}
    //   ]);
    // } else {
    //   await jobDatabase.completeJob(job.jobId);
    //   Alert.alert("Success", "Successfully completed Job");
    //   navigation.navigate('Dashboard');
    // }
    // return;

  }

  useEffect(() => {
    loadJobAssets();
  }, [isFocused])

  const renderItem = ({item, index}) => (
    <TouchableOpacity style={styles.row} onPress={() => selectAsset(item)}>
      <View style={[styles.column, styles.body__column]}>
        <Text style={styles.job__text}>{item.description}</Text>
      </View>
      <View style={[styles.column, styles.body__column]}>
        <Text  style={styles.job__text}>{item.categoryName}</Text>
      </View>
      <View style={[styles.column, styles.body__column]}>
        <Text style={styles.job__text}>{item.serialNumber}</Text>
      </View>
      <View style={[styles.column, styles.body__column]}>
        <Text style={styles.job__text}>{item.manufacturer}</Text>
      </View>
      <View style={{ width: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>
        <AntDesign name="rightcircleo" size={24} color="black" style={{ marginLeft: 10, }} />
      </View>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <TitleBar 
        navigation={navigation} 
        title="Job Assets" 
        showBackButton={true} 
        showSearchButton={true} 
        onSynced={null}
        onSearch={(searchQuery) => loadJobAssets(searchQuery)} />

      {job != null &&
        <View style={styles.job__title}>
          <Text style={styles.job__title__text}>{job.jobTitle}</Text>

          <TouchableOpacity onPress={() => addEquipment()} style={[styles.add__equipment__button]}>
            <Entypo style={{ marginRight: 10 }} size={24} color={"#FFF"} name="cog" />
            <Text style={{ color: '#FFF', fontFamily: 'OpenSans-Bold' }}>ADD EQUIPMENT</Text>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => erstr()} style={[styles.rams__button, job.engineerReportId == null ? styles.rams__button__red : styles.rams__button__green]}>
            { job.engineerReportId == null && <MaterialCommunityIcons style={{ marginRight: 10 }} name="cancel" size={24} color={"#FFF"} />}
            { job.engineerReportId != null && <MaterialCommunityIcons style={{ marginRight: 10 }} name="check" size={24} color={"#FFF"} />}
            <Text style={{ color: '#FFF', fontFamily: 'OpenSans-Bold' }}>ERSTR</Text>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => rams()} style={[styles.rams__button, job.ramsId == null ? styles.rams__button__red : styles.rams__button__green]}>
            { job.ramsId == null && <MaterialCommunityIcons style={{ marginRight: 10 }} name="cancel" size={24} color={"#FFF"} />}
            { job.ramsId != null && <MaterialCommunityIcons style={{ marginRight: 10 }} name="check" size={24} color={"#FFF"} />}
            <Text style={{ color: '#FFF', fontFamily: 'OpenSans-Bold' }}>RAMS</Text>
          </TouchableOpacity>
        </View>
      }

      <View style={{ marginTop: 20, }}>
        <View style={styles.row}>
          <Text style={[styles.column, styles.head__column]}>Description</Text>
          <Text style={[styles.column, styles.head__column]}>Category</Text>
          <Text style={[styles.column, styles.head__column]}>Serial #</Text>
          <Text style={[styles.column, styles.head__column]}>Manufacturer</Text>
          <View style={{ width: 40 }}></View>
        </View>
      </View>

      <FlashList
        data={jobAssets}
        renderItem={renderItem}
        estimatedItemSize={57}
      />

      {/* <TouchableOpacity style={styles.complete__button} onPress={() => complete()}>
        <Text style={styles.complete__button__text}>COMPLETE JOB</Text>
      </TouchableOpacity> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    padding: 40,
  },

  row: {
    marginBottom: 5,
    flexDirection: 'row',
  },

  column: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },

    head__column: {
      padding: 15,
      paddingTop: 0,
      fontFamily: 'OpenSans-Bold',
    },

    body__column: {
      marginBottom: 5,
      backgroundColor: '#FFF',
      flexDirection: 'row',
    },

  job__title: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#FFF',
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center'
  },

  job__title__text: {
    fontFamily: 'OpenSans-Bold',
    fontSize: 22,
    flex: 1,
  },

  spacer: {
    flex: 1,
  },

  job__text: {

  },

  add__equipment__button: {
    backgroundColor: colours.primary,
    padding: 10,
    borderRadius: 10,
    width: 190,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
  },

  rams__button: {
    backgroundColor: '#CECECE',
    padding: 10,
    borderRadius: 10,
    width: 100,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
  },

  rams__button__red: {
    backgroundColor: 'red'
  },

  rams__button__green: {
    backgroundColor: 'green'
  },

  complete__button: {
    backgroundColor: colours.success,
    padding: 10,
    borderRadius: 10,
    marginTop: 10,
    textAlign: 'center',
  },

    complete__button__text: {
      fontFamily: 'OpenSans-Bold',
      fontSize: 22,
      color: '#FFF',
      textAlign: 'center',
    }
});
