import { createNativeStackNavigator } from '@react-navigation/native-stack';
import EquipmentManageScreen from './EquipmentManage';
import EquipmentsListScreen from './EquipmentsList';
import MassUploadScreen from './MassUpload';

const Stack = createNativeStackNavigator();

export default function Equipment({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="list" component={EquipmentsListScreen} options={{ headerShown: false, title: 'Equipments'  }} />
      <Stack.Screen name="manage" component={EquipmentManageScreen} options={{ headerShown: false, title: 'Equipment Manage'}}  />
      <Stack.Screen name="massupload" component={MassUploadScreen} options={{ headerShown: false, title: 'Mass Upload'}}  />
    </Stack.Navigator>
  )
};